import React, {Fragment, useEffect, useState} from "react";
import css from "./sign.module.less";
import {Button, Descriptions, message} from 'antd';
import {useDataApi} from "../../../../hook";
import {AUDIT_PUBLISH, MODE_OFF, resourcesUrl} from "../../../../common/static_number";
import {PublishModal} from "../../../../common";
import {tap} from "rxjs/operators";
import {useHistory} from "react-router-dom";

export const Sign = (props) => {
    const {params:{project_sn=""}} = props;
    const [defaultFileList, setDefaultFileList] = useState([]); //默认的文件列表
    const [initialData, setInitialData] = useState({});

    const [{data, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});

    useEffect(() => {
        if (!isLoading) {
            let _data = data;
            _data.project_issued = _data.project_issued ||{};
            if (_data.project_issued.attachment_url && _data.project_issued.attachment_url.length) {
                setDefaultFileList(_data.project_issued.attachment_url)
            }
            _data.isLoading = true;
            setInitialData(_data);
        }
    }, [isLoading]);
    const {push} = useHistory();
    const submit = ()=>{
        PublishModal.signFile({prosend_id:initialData.project_issued.id}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                if(initialData.bond >0){
                    message.success('签收成功！',3, push({pathname: '/market/tender',state:{project_sn,step:2}}));
                }else{
                    message.success('签收成功！',3, push({pathname: '/market/tender',state:{project_sn,step:3}}));
                }
            },
            error:err=>{},
            complete:()=>{}
        });
    };


    return (
        <div className='container'>
            <div className={css.mainBody}>

                {!isLoading && <div className={css.sign}>
                    <div className={css.boxTitle}>
                        签收招标文件
                    </div>

                    <Descriptions bordered column={2}>
                        <Descriptions.Item label="公告标题" span={2}>{initialData.title}</Descriptions.Item>
                        <Descriptions.Item label="项目名称">{initialData.project_name}</Descriptions.Item>
                        <Descriptions.Item label="项目编号">NO:{initialData.project_sn}</Descriptions.Item>
                        <Descriptions.Item label="行业分类">{initialData.category_trade}</Descriptions.Item>
                        <Descriptions.Item label="二级分类">{initialData.category_name}</Descriptions.Item>
                        <Descriptions.Item label="招标联系人">{initialData.user_name}</Descriptions.Item>
                        <Descriptions.Item
                            label="联系电话">{initialData.user_phone && initialData.user_phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}</Descriptions.Item>
                        <Descriptions.Item label="招标类型">{initialData.tentype}</Descriptions.Item>
                        <Descriptions.Item label="招标方式">{initialData.tendering_mode === MODE_OFF ?'仅线上发布招标公告':'在线电子招投标'}</Descriptions.Item>
                        <Descriptions.Item
                            label="所属地区">{initialData.address}</Descriptions.Item>
                        <Descriptions.Item label="投标保证金">{initialData.bond}（万元）</Descriptions.Item>
                        <Descriptions.Item label="投标时间">{data.project_issued.bidstart_time} - {data.project_issued.bidend_time}</Descriptions.Item>
                        <Descriptions.Item label="招标文件标题">{data.project_issued.issued_name}</Descriptions.Item>
                    </Descriptions>
                    {!initialData.isLoading || <Fragment>
                        <Descriptions title="招标文件正文" bordered style={{marginTop: '20px'}}>
                            <Descriptions.Item>
                                <div dangerouslySetInnerHTML={{__html: initialData.project_issued.content}}/>
                            </Descriptions.Item>
                        </Descriptions>


                        <Descriptions style={{marginTop: '20px'}} column={1}>
                            {
                                defaultFileList.length >0 && <Descriptions.Item label='附件' >
                                    {defaultFileList.map((item)=>
                                        <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                                    )}
                                </Descriptions.Item>
                            }
                        </Descriptions>
                    </Fragment>
                    }
                    <div className={css.btns}>
                        <Button size={"large"} className={css.saveBtn} onClick={submit}>
                            签收
                        </Button>
                    </div>
                </div>
                }
            </div>

        </div>



    );
}