import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import {Confirm,  Offer,OfferNew} from "./components";

import css from "./listConfirmNew.module.less";
import 'braft-editor/dist/index.css'
import {Flow} from "../components";

const { TabPane } = Tabs;


/** 定标 */
export const ListConfirmNew = (props) => {
    const superStep = 5; //1:约标 2：发标 3：开标 4：评标 5：定标
    console.log(props.location.state);
    const [ step,setStep ] = useState(0);
    const [params,setParams] = useState({project_sn:props.location.state.project_sn||''});
    useEffect(()=>{
        const {project_sn='',step=0} = props.location.state||{};
        setStep(step);
        setParams({project_sn});
    }, [props.location.state]);

    const superList = [
        {name: '约标', id: 2, step: 1},
        {name: '发标', id: 2, step: 2},
        {name: '开标', id: 2, step: 3},
        {name: '评标', id: 2, step: 4},
        {name: '定标', id: 2, step: 5},
    ];
    let stepList = [
        {
            stepId: 1,
            stepName: '确认中标人',
            disabled: true
        },
        // {
        //     stepId: 2,
        //     stepName: '中标通知',
        //     disabled: true
        // },
        {
            stepId: 3,
            stepName: '中标公示',
            disabled: true
        },
    ];

    return (
        <div className='container'>
            <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList} superList={superList} />
            <div className={css.listConfirm}>
                <Tabs activeKey={ step.toString() }>
                    {/* <TabPane tab="确认中标人" key="1">
                        <Confirm  params={params} />
                    </TabPane> */}
                    {/*<TabPane tab="中标通知" key="2">*/}
                    {/*    <InformSuccess  params={params}/>*/}
                    {/*</TabPane>*/}
                    <TabPane tab="中标公示" key="3">
                        <OfferNew  params={params}/>
                    </TabPane>
                </Tabs>

            </div>
        </div>
    )
};