import React, {useEffect, useState,Fragment} from "react";
import css from "./pay.module.less";
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Descriptions, message, Modal } from 'antd';
import {useDataApi} from "../../../../hook";
import {AUDIT_PUBLISH} from "../../../../common/static_number";
import {UploadImage} from "../../../../components";
import {PublishModal} from "../../../../common";
import {useHistory} from "react-router-dom";

const layout = {
    labelCol: {span: 3},
    wrapperCol: {span: 10},
};
export const Pay = (props) => {
    const {params:{project_sn=""}} = props;
    const [params, setParams] = useState({images: []});
    const [form] = Form.useForm();

    const [defaultImagesList, setDefaultImagesList] = useState([]); //默认的图片列表
    const [initialData, setInitialData] = useState({});
    const [validImages, setValidImages] = useState(true);
    const [accountFlag, setAccountFlag] = useState(false);

    const [{data, isLoading}] = useDataApi({url: '/api/prosign', method: 'post', data: {project_sn}});

    useEffect(() => {
        if (!isLoading) {
            let _data = data.prosign;
            const _param = params;
            _data = data;
            _param.images = _data.certificate||[];
            if (_param.images.length) {
                setDefaultImagesList(_data.certificate)
            }

            _data.isLoading = true;
            setInitialData(_data);
            setParams(_param);
        }
    }, [isLoading]);
    const {push} = useHistory();

    const submit = ()=>{
        const {images:certificate} = params;
        if (!certificate.length) {
            setValidImages(false);
            return
        }

        PublishModal.payDeposit({project_sn,prosign_id:initialData.id,certificate,bond:data.bond}).then(({data:{code,msg}})=>{
                if (code === 200) {
                    message.success('提交成功！',3);
                    if(data.status_type === '14'){
                        push({pathname:'/market/tender',state:{project_sn:initialData.project_sn,step:3}})
                    }else{
                        window.history.back();
                    }
                } else {
                    message.error(msg);
                }
            }
        );
    };
    /** 图片上传成功事件 */
    const handleImageChange = val => {
        let _params = params;
        _params.images = val;
        console.log("val",val);
        setParams(_params);
        setValidImages(true);
    };

    /** 获取账户信息 */
    const getAccount = val => {
        const content = <Fragment className={css.account}>
            <Descriptions column={1}  labelCol={{span:8}} bordered>
                <Descriptions.Item label={'账户名称'}>中镇生态科技河北有限公司</Descriptions.Item>
                <Descriptions.Item label={'账户号码'} >7519 0188 0001 3574 9</Descriptions.Item>
                <Descriptions.Item label={'开户银行'} >中国光大银行股份有限公司石家庄康乐街支行</Descriptions.Item>
                <Descriptions.Item label={'法定代表人（单位负责人）'} >窦丽辉</Descriptions.Item>
                <Descriptions.Item label={'基本存款账户编号'} >J1210061741401</Descriptions.Item>
            </Descriptions>

        </Fragment>
        Modal.confirm({
            title:'汇款账户信息',
            content: content,
            maskClosable: true,
            width:500,
            onOk: ()=>{

            },
            onCancel:()=>{

            }
        });
    };

    return (
            <div className={css.mainBody}>

                <div className={css.pay}>
                    <div className={css.boxTitle}>
                        缴纳保证金
                        <div className={css.info}>
                            <span >项目名称：</span>
                            <span className={css.high}>{data.project_name}</span>
                            <span >项目编号：</span>
                            <span className={css.high}>{data.project_sn}</span>
                        </div>
                    </div>
                    {!initialData.isLoading ||
                    <Form
                        {...layout}
                        initialValues={initialData}
                        scrollToFirstError={true}
                        form={form}
                    >
                        <Form.Item label="投标保证金">
                             <span>{data.bond}&nbsp;&nbsp;&nbsp;万元 </span>
                        </Form.Item>
                        <Form.Item
                            labelCol={{span: 3}}
                            label="上传缴纳凭证"
                            validateStatus={validImages ? '' : 'error'}
                            help={validImages ? '' : '请上传缴纳凭证'}
                            required
                            style={{marginBottom: 0}}
                        >
                            <UploadImage uploadNum={3} onChange={handleImageChange}
                                         value={defaultImagesList}/>
                        </Form.Item>
                    </Form>

                    }
                    <div className={css.btns}>
                        <Button size={"large"}  type="primary" className={css.saveBtn} onClick={getAccount}>
                            提取缴纳信息
                        </Button>
                        <Button size={"large"} type={"primary"} onClick={submit}>
                            提交
                        </Button>
                    </div>
                    <Descriptions style={{marginLeft: '140px'}}>
                        <Descriptions.Item label="" >
                            温馨提示<br/>
                            1. 请上传清晰且真实有效的汇款凭证。<br/>
                            2. 如逾期未缴纳保证金，则视为您自动放弃本项目的投标资格。<br/>
                            3. 请认真核实汇款账户信息，如遇错转等情况本平台概不承担责任。
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </div>
    );
}