import React, {useEffect, useState} from 'react';
import {Input, Select} from 'antd';
import {useDataApi} from "../hook";

const { Option } = Select;

export const PriceInput = ({ value = {}, onChange }) => {
    const [number, setNumber] = useState(0);
    const [unit, setUnit] = useState('');
    const [other, setOther] = useState('');
    const [{data}] = useDataApi({url: '/api/getcompany', method: 'post',initialData:[]});


    const triggerChange = changedValue => {
        if (onChange) {
            console.log("11111");
            onChange({
                number,
                unit,
                other,
                ...value,
                ...changedValue,
            });
        }
        console.log("22222");
    };

    const onNumberChange = e => {
        const newNumber = parseInt(e.target.value || 0, 10);
        console.log("onOtherChange",newNumber);

        if (Number.isNaN(number)) {
            return;
        }

        if (!('number' in value)) {
            setNumber(newNumber);
        }

        triggerChange({
            number: newNumber,
        });
    };

    const onCurrencyChange = newCurrency => {
        // if (!('unit' in value)) {
        //
        //
        // }
        setUnit(newCurrency);
        console.log("newCurrency",newCurrency);
        triggerChange({
            unit: newCurrency,
        });
    };
    const onOtherChange = ({target:{value:newCurrency}}) => {
        console.log("onOtherChange",newCurrency);
        if (!('other' in value)) {
            setOther(newCurrency);
        }
        triggerChange({
            other: newCurrency,
        });
    };
    return (
        <span>
      <Input
          type="text"
          value={value.number || number}
          onChange={onNumberChange}
          style={{
              width: 100,
          }}
      />
      <Select
          value={value.unit || unit}
          style={{
              width: 120,
              margin: '0 8px',
          }}
          onChange={onCurrencyChange}
      >
          <Option value={''}>自定义</Option>
          {data.map((item)=><Option value={item.name}>{item.name}</Option>)}
      </Select>
            { unit ==='' &&
        <Input
            type="text"
            value={value.other}
            onChange={onOtherChange}
            style={{
                width: 110,
            }}
            placeholder={'自定义单位'}
        />
            }
    </span>
    );
};