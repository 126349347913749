import React, {useContext, useEffect, useRef, useState} from "react";
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import css from "./notice.module.less";
import moment from 'moment';
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    DatePicker,
    Descriptions,
    Divider,
    Input,
    message,
    Modal,
    Popconfirm,
    Table,
    Radio
} from 'antd';
import {AddressItem, TableTransfer, UploadFile, UploadImage} from "../../../../components";
import {PublishModal, ServicesModel} from "../../../../common";
import {tap} from "rxjs/operators";
import {useDataApi} from "../../../../hook/dataApi";
import {Contacts} from "../contacts";
import {unique} from "../../../../helps";
import {AUDIT_PUBLISH, MODE_OFF, TYPE_INVITE} from "../../../../common/static_number";
import locale from 'antd/es/date-picker/locale/zh_CN';
import {useHistory} from "react-router-dom";
import {PreviewMarket} from "../../../preview/previewMarket";
import {QuestionCircleOutlined} from '@ant-design/icons'



const layout = {

    labelCol: {span: 3},
    wrapperCol: {span: 10},
};
const modalCol = {

    labelCol: {span: 6},
    wrapperCol: {span: 16},
}
const EditableContext = React.createContext();


const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};


const EditableCell = ({
                          title,
                          agent_type,
                          entrust_company_name,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async e => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const {TextArea} = Input;
const {  RangePicker } = DatePicker;

export const Notice = (props) => {
    const {params:{project_sn=""}} = props;
    const [form] = Form.useForm();
    const [params, setParams] = useState({ file: []});

    const [agent_type, setagent_type] = useState(1);

    const [selectedData, setSelectedData] = useState([]); //弹框中选中的供应商列表
    const [dataSource, setDataSource] = useState([]); //邀请中的供应商列表
    const [project, setProject] = useState();
    const [contactsVisible, showContactsVisible] = useState(false); //审批人弹窗控制
    const [previewFlag, showPreview] = useState(false); //预览弹窗控制
    const [users, setUsers] = useState([]); //选中的审批人
    const [count, setCount] = useState(60);
    const [countdown, showCountdown] = useState(false);
    const [initialData, setInitialData] = useState({});
    const [auditFlag, setAuditFlag] = useState(false);
    const [modalFlag, setModalFlag] = useState(false);

    const [{data, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});
    const [supplierData,setSupplierData] = useState([]);
    const {push} = useHistory();

    const [userdata] = useDataApi({url: '/api/getAuthentication', method: 'post'});
    console.log('userdata',userdata.data.company_name)

    useEffect(() => {
        if(!isLoading){
            let _data = data;
            const {province_id,city_id,county_id,user_name,user_phone,invitation_remark,content,images,file} = data;
            const signup_time = data.signup_time? moment(data.signup_time) : null;
            const signend_time = data.signend_time? moment(data.signend_time ) :null;
            _data.rangePicker = [signup_time,signend_time];
            _data.remark = invitation_remark;
            _data.address = _data.address ? [province_id,city_id,county_id]:void 0;
            _data.content = BraftEditor.createEditorState(content);
            _data.images = images || [];
            
            _data.agent_type = ''+data.agent_type || '1';
            _data.entrust_company_name = data.entrust_company_name || '';

            console.log("_data.address",_data.address);
            params.file = file || [];
            setParams({...params,...{user_name,user_phone}});
            if(_data.projsign_list){
                setSupplierData(_data.projsign_list);
            }
            if(_data.approval_username){
                setUsers([{
                    username: _data.approval_username,
                    user_id: _data.approval_userid
                }])
            }
            _data.isLoading = true;
            setInitialData(_data);
        }

    }, [data,isLoading]);


    /** 文件上传成功事件 */
    const handleFileChange = (val) => {
        setParams({...params,...{file:val}});
    };

    const excludeControls = ['media', 'emoji'];

    /** 关闭预览弹窗 */
    const closePreview = (value) => {
       showPreview(false);
    };

    /** 获取手机验证码 */
    const getCode = () => {
        const {user_phone} = initialData;
        startCountdown();
        ServicesModel.getSendCode({mobile: user_phone, event: 'noticeapproval'}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:(res)=>{
                message.success('验证码已发送')
            }
        });
    };
    let IntervalId = '';
    const startCountdown = () => {
        showCountdown(true);
        let _count = count;
        IntervalId = setInterval(() => {
                if (_count > 0) {
                    _count--;
                    setCount(_count);
                } else {
                    showCountdown(false);
                    clearInterval(IntervalId);
                    setCount(60);
                }
            }
            , 1000);
    };

    const col = [
        {
            title: '供应商名称',
            dataIndex: 'store_name',
            key: 'store_name',
        },
        {
            title: '行业分类',
            dataIndex: 'business_type',
            key: 'business_type',

        },
        {
            title: '公司所在地',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '注册资金',
            dataIndex: 'money',
            key: 'money',
            render: (text, record) =>

                <span>{text? text : record.reg_capital} (万元)</span>

        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) =>
                <Popconfirm title="确定要删除嘛?" onConfirm={() => handleDelete(record.store_id)}>
                    <a>删除</a>
                </Popconfirm>
        },
    ];

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        let _columns = col.map(opt => {
            if (!opt.editable) {
                return opt;
            }
            return {
                ...opt,
                onCell: record => ({
                    record,
                    editable: opt.editable,
                    dataIndex: opt.dataIndex,
                    title: opt.title,
                    handleSave: handleSave,
                }),
            };
        });
        setColumns(_columns);
    }, [dataSource]);

    useEffect(() => {
        if(supplierData){
            const _data = supplierData.map(item => {
                return {ps: '邀请通知：', ...item}
            });
            setDataSource(_data);
        }
    }, [supplierData]);

    const handleDelete = id => {
        const deleteData = dataSource.filter(item => item.store_id !== id);
        setDataSource(deleteData);
        setSelectedData(deleteData);
    };
    const handleSave = row => {
        const newData = [...dataSource];
        const index = newData.findIndex(item => row.store_id === item.store_id);
        const item = newData[index];
        newData.splice(index, 1, {...item, ...row});
        setDataSource(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    /** 提交验证 */
    const submitVerify = () => {
        form.validateFields().then((values) => {
            if (initialData.tendering_type === TYPE_INVITE && dataSource.length < 3) {
                message.error('您至少需要选择三家供应商哟~');
                return
            }
            PublishModal.checkTitle({title:values.title,project_sn}).pipe(
                tap(res=>console.log(res))
            ).subscribe({
                next:({data})=>{
                    if(data){
                        Modal.confirm({
                            title:'温馨提示',
                            content: '您的项目标题跟以往的项目有重复呦！，是否继续提交？',
                            okText:'确定',
                            cancelText:'取消',
                            maskClosable: true,
                            onOk: ()=>{
                                setAuditFlag(true);
                            },
                            onCancel:()=>{}
                        });
                    }else{
                        setAuditFlag(true);
                    }
                },
                error:err=>{},
                complete:()=>{}
            });
        })
    };

    let Loading = false;

    /** 提交事件 */
    const submit = ({is_approval = 0}) => {
        if (Loading) {
            message.warn('正在提交，不要心急哟~');
            return
        }
        const values =  form.getFieldsValue(['title','agent_type','entrust_company_name','project_name','starting_price','remark','content','images']);
        console.log(values)
            let _params = Object.assign({...values}, {...params});
            _params.code = codeVale;
            _params.content = encodeURI(values.content.toHTML());
            const [signup_time,signend_time] = form.getFieldValue('rangePicker');
            _params.signend_time =  signend_time.format('YYYY-MM-DD HH:mm');
            _params.signup_time =  signup_time.format('YYYY-MM-DD HH:mm');
            const [province_id,city_id,county_id] = form.getFieldValue('address');
            if (initialData.tendering_type === TYPE_INVITE) { //邀请招标
                const signdata = dataSource.map(item => {
                    return {store_id: item.store_id, appr_content: item.ps}
                });
                PublishModal.saveNoticeInvitation({
                    project_sn,
                    is_approval,
                    province_id,
                    city_id,
                    county_id,
                    signdata, ..._params
                }).then(({data: {msg, code}}) => {
                    Loading = false;
                    if (code === 200) {
                        historyNext(is_approval);
                    } else {
                        message.error(msg)
                    }
                })
            } else { //公开招标
                PublishModal.saveNoticePublish({is_approval,
                    province_id,
                    city_id,
                    county_id,project_sn, ..._params}).then(({data: {msg, code}}) => {
                    Loading = false;
                    if (code === 200) {
                        setAuditFlag(false);
                        historyNext(is_approval);
                    } else {
                        message.error(msg)
                    }
                })
            }
    };
    const historyNext = (is_approval)=>{
        setAuditFlag(false);
        if(is_approval === 0){
            message.success('保存成功！',3);
        }else{
            if(data.tendering_mode  === MODE_OFF){
                message.success('提交成功！',3,  push({pathname:'/publish/off',state:{step:3,project_sn}}));
            }else{
                message.success('提交成功！',3,  push({pathname:'/publish',state:{step:4,project_sn}}));

            }
        };
    }
    /** 添加数据 */
    const pushData = (selectedRows) => {
        setSelectedData(unique([...selectedData, ...selectedRows], 'store_id'));
    };

    /** 删除数据 */
    const removeData = (selectedRows) => {
        const removeData = selectedData.filter(item => {
            return !selectedRows.some(row => {
                return item.store_id === row.store_id;
            })
        });
        setSelectedData([...removeData]);
    };

    const openModal = () => {
        setModalFlag(true);
    };

    const closeModal = () => {
        setModalFlag(false);
    };

    /** 邀请供应商确定事件 */
    const handleOk = () => {
        setDataSource(selectedData);
        closeModal()
    };

    const [codeVale, setCodeValue] = useState();

    /** 输入验证码事件 */
    const onCodeChange = e => {
        setCodeValue(e.target.value)
    };

    /** 审批人弹窗选择完成 */
    const handleContactsOk = e => {
        if (users.length === 0) {
            message.error('您还没有选择审批人呦~');
            return
        } else if (users.length !== 1) {
            message.error('审批人只能设置一个呦~');
            return
        }
        showContactsVisible(false);
    };

    const handleAuditOk = e => {
        if (!codeVale) {
            message.error('请输入手机验证码');
            return
        }
        if (users.length === 0) {
            message.error('您还没有选择审批人呦~');
            return
        } else if (users.length !== 1) {
            message.error('审批人只能设置一个呦~');
            return
        }
        const audit = users[0];
        PublishModal.setResponsible({
            type: AUDIT_PUBLISH, project_sn, is_approval:1, ...audit
        }).then(({data: {data, msg, code}}) => {
            if (code === 200) {
                submit({is_approval: 1});
            } else {
                message.error(msg)
            }
        })
    };

    /** 人员选择回调函数 */
    const contactsSuccess = e => {
        setUsers(e.map(item => {
            return {username: item.title, user_id: item.id}
        }));
    };

    /** 关闭审批人弹窗 */
    const handleCancel = e => {
        showContactsVisible(false);
    };

    /** 关闭提交审批弹窗 */
    const handleAuditCancel = e => {
        setAuditFlag(false);
    };

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().subtract(1, 'days');
    }

    /** 打开预览 */
    const openPreview = ()=>{
        form.validateFields().then((values) => {
            let _params = Object.assign({...values}, {...params});
            _params.content = values.content.toHTML();
            const {category_name,category_trade,tendering_type} = data;
            const [signup_time,signend_time] = _params.rangePicker;
            const [province_id,city_id,county_id] = _params.address;
            _params.signend_time = signend_time ? signend_time.format('YYYY-MM-DD HH:mm') : '';
            _params.signup_time = signup_time ? signup_time.format('YYYY-MM-DD HH:mm') : '';
            setProject ({
                category_name,category_trade,tendering_type,province_id,city_id,county_id,
                project_sn, ..._params
            });
            showPreview(true);
        })
    };

    const Change_agent_type = e =>{
        // console.log(e.target.value)
        setagent_type(e.target.value)

        
        
    }

    return (
        <div>
            <div className={css.notice}>
                <div className={css.boxTitle}>
                    编辑公告
                    <div className={css.info}>
                        <span>项目编号：</span>
                        <span className={css.high}>{data.project_sn}</span>
                    </div>
                </div>
                
                {!initialData.isLoading ||
                <Form
                    {...layout}
                    initialValues={initialData}
                    scrollToFirstError={true}
                    form={form}
                >
                    <Form.Item
                        labelCol={{span: 3}}
                        wrapperCol={{span: 10}}
                        label="公告标题"
                        name="title"
                        rules={[{required: true, message: '请填写公告标题'}, {min: 6, message: '公告标题最少为6个字符'}, {
                            max: 50,
                            message: '公告标题最长为50个字符'
                        }]}
                    >
                        <Input placeholder='请填写公告标题'/>
                    </Form.Item>
                    <Form.Item label="项目名称" name="project_name" wrapperCol={{span: 10}}
                               rules={[{required: true, message: '请填写项目名称'}, {min: 6, message: '项目最少为6个字符'}, {
                                   max: 50,
                                   message: '项目名称最长为50个字符'
                               }]}>
                        <Input placeholder='请填写项目名称'/>
                    </Form.Item>

                    <Form.Item label="是否代理" name={'agent_type'} valuePropName="value" wrapperCol={{span: 10}} >
                        <Radio.Group buttonStyle="outline" defaultValue="1" onChange={Change_agent_type}>
                            <Radio.Button value="1">否</Radio.Button>
                            <Radio.Button value="2" >是</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    {
                       agent_type == '1' &&
                        <Form.Item label="招标单位"  wrapperCol={{span: 10}} >
                            {userdata.data.company_name}
                        </Form.Item>
                    }
                    {
                        agent_type == '2' &&
                        <div>
                            <Form.Item label="招标代理单位"  wrapperCol={{span: 10}} >
                                {userdata.data.company_name}
                            </Form.Item>
                            <Form.Item label="委托公司" name={'entrust_company_name'} wrapperCol={{span: 10}} 
                                rules={[{required: true, message: '请填写委托公司名称'}, {min: 6, message: '项目最少为6个字符'}, {
                                    max: 200,
                                    message: '项目名称最长为200个字符'
                                }]}
                            >
                                <Input placeholder='请填写委托公司名称'/>
                            </Form.Item>
                        </div>
                    }
                    

                    
                    
                    <div className={css.item}>
                    <Form.Item label="报名时间" name={'rangePicker'} required wrapperCol={{span: 10}}
                               rules={[{required: true, message: '请选择报名时间'},({getFieldValue})=>({
                                   validator(rule,value){
                                       if(value[1].diff(value[0], 'minutes') < 3*24*60){
                                          return Promise.reject('报名时间间隔必须大于3天！');
                                       }
                                       return Promise.resolve();
                                   }
                               })]}>
                        <RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" locale={locale} disabledDate={disabledDate} style={{width: '100%'}}/>
                    </Form.Item>
                        <span className={css.msg}><QuestionCircleOutlined style={{marginRight:'5px'}}/>报名时间间隔必须大于3天</span>
                    </div>
                    <Form.Item
                        label="所属地区"
                        name="address"
                        required
                    >
                        <AddressItem size="default" />
                    </Form.Item>

                    <Form.Item label="项目金额" name="starting_price" wrapperCol={{span: 3}}  rules={[{required:true,message: '请填写项目金额'},({getFieldValue})=>({
                        validator(rule,value){
                            if(value && value < 0){
                                return Promise.reject('项目金额必须是正整数');
                            }
                            return Promise.resolve();
                        }
                    })]}>
                        <Input type={'number'} placeholder='项目金额' suffix={'万元'}/>
                    </Form.Item>
                    <Form.Item
                        labelCol={{span: 3}}
                        label="项目图片"
                        name={'images'}
                    >
                        <UploadImage uploadNum={3} />
                    </Form.Item>
                    <Form.Item
                        label={'公告正文'}
                        labelCol={{span: 3}}
                        wrapperCol={{span: 24}}
                        name={'content'}
                        rules={[{required: true,message:'请填写公告正文'},({getFieldValue})=>({
                            validator(rule,value){
                                if(!value || value.toHTML() === '<p></p>'){
                                   return Promise.reject('请填写公告正文');
                                }
                                return Promise.resolve();
                            }
                        })]}
                    >
                        <BraftEditor
                            excludeControls={excludeControls}
                            style={{border: '1px solid #ddd'}}
                            contentStyle={{height: '200px'}}
                            // placeholder={<div>供应商资质要求 <br/>1.某某某 <br/>2.某某某</div>}
                            placeholder={'请输入公告正文'}
                        />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{offset: 3, span: 16}}
                    >
                        <UploadFile uploadNum={3} accept={' .PDF, .PPT, .PPTX, .DOCX, .DOC'}
                                    handleFileChange={handleFileChange} defaultFileList={params.file}/>
                    </Form.Item>

                    {
                        initialData.tendering_type === TYPE_INVITE && <div>
                            <Divider dashed/>
                            <div className={css.subtitle}>
                                <div className={css.title}>邀请供应商</div>
                                <Button
                                    type="primary"
                                    ghost
                                    onClick={openModal}
                                >
                                    添加供应商
                                </Button>
                            </div>
                            <Form.Item wrapperCol={24}>
                                <Table
                                    components={components}
                                    rowClassName={() => 'editable-row'}
                                    bordered
                                    pagination={false}
                                    dataSource={dataSource}
                                    columns={columns}
                                />
                            </Form.Item>
                            <Form.Item
                                name="remark"
                                label="邀请通知"
                                wrapperCol={12}
                                rules={[{required: true, message: '输入邀请通知'}]}
                            >
                                <TextArea autoSize rows={5} placeholder="输入邀请通知"/>
                            </Form.Item>
                        </div>
                    }
                    <div className={css.btns}>
                        <Button size={"large"} onClick={openPreview}>预览</Button>
                        <Button size={"large"} className={css.saveBtn} onClick={submit}>
                            保存
                        </Button>
                        <Button type="primary" size={"large"} onClick={submitVerify}
                                disabled={isLoading}>
                            提交审批
                        </Button>
                    </div>
                    <Descriptions style={{marginLeft: '140px'}}>
                        <Descriptions.Item label="">
                            温馨提示<br/>
                            1、通过发布入口发布的信息，未经网站审核，暂不显示。您可以前往会员中心查看信息审核状态。<br/>
                            2、发布过程中，如遇任何问题，请致电客服，电话 010 - 53673671。<br/>
                        </Descriptions.Item>
                    </Descriptions>
                </Form>
                }
                <Modal title="选择供应商"
                       visible={modalFlag}
                       width='1000px'
                       onCancel={closeModal}
                       onOk={handleOk}
                >
                    <TableTransfer
                        selectedData={selectedData}
                        pushData={pushData}
                        removeData={removeData}
                    />
                </Modal>
                <Modal title={'提交审批'}
                       visible={auditFlag}
                       onOk={handleAuditOk}
                       onCancel={handleAuditCancel}
                >
                    <Form.Item {...modalCol} label={'联系人'}  style={{marginBottom: '0'}}> {initialData.user_name}</Form.Item>
                    <Form.Item {...modalCol} label={'联系电话'}  style={{marginBottom: '10px'}}> {initialData.user_name && initialData.user_phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}</Form.Item>
                    <Form.Item {...modalCol} label="手机验证码" {...modalCol} style={{marginBottom: '0'}} required>
                        <Form.Item
                            wrapperCol={{span: 23}}
                            style={{display: 'inline-block'}}
                        >
                            <Input placeholder='请输入手机验证码' onChange={onCodeChange} autoComplete="off"/>
                        </Form.Item>
                        {
                            !countdown ?
                                <Form.Item style={{display: 'inline-block'}}>
                                    <Button type="primary" onClick={() => {
                                        getCode();
                                    }}>获取验证码</Button>
                                </Form.Item> :
                                <Form.Item style={{display: 'inline-block'}}>
                                    <Button type={"primary"} disabled>{count}s后获取</Button>
                                </Form.Item>
                        }
                    </Form.Item>
                    <Form.Item label="审批人" {...modalCol} style={{marginBottom: '0'}} required>
                        <Form.Item
                            wrapperCol={{span: 23}}
                            style={{display: 'inline-block'}}
                        >
                            <Input placeholder={'请选择审批人'} value={users.map(item => {
                                return item.username
                            }).join(',')} disabled/>
                        </Form.Item>
                        <Form.Item style={{display: 'inline-block'}}>
                            <Button type="primary" onClick={() => showContactsVisible(true)}>选择审批人</Button>
                        </Form.Item>
                    </Form.Item>
                </Modal>
                <Modal
                    title="选择审批人"
                    visible={contactsVisible}
                    onOk={handleContactsOk}
                    onCancel={handleCancel}
                >
                    <Contacts success={contactsSuccess} users={users}/>
                </Modal>
            </div>

            <Modal visible={previewFlag}
                   width={940}
                   onCancel={closePreview}
                    footer={false}
            >
                <PreviewMarket project = {project} />
            </Modal>
        </div>
    );
}