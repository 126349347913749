import React, {Fragment, useEffect, useState} from "react";
import css from "./auditDetail.module.less";
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Descriptions, Input, message, Modal, Table } from 'antd';
import {useDataApi} from "../../../hook";
import {MODE_OFF, resourcesUrl, TYPE_INVITE} from "../../../common/static_number";
import {UploadImage} from "../../../components";
import {SubNav, UserInfo} from "../components";
import {PublishModal} from "../../../common";
import {useHistory} from "react-router-dom";

const {TextArea} = Input;
export const AuditDetail = (props) => {
    const {id:project_notice_id ='',project_approval_log_id,type} = props.location.state;
    const {push} = useHistory();

    const [typeText,setTypeText] = useState('');
    const [defaultFileList, setDefaultFileList] = useState([]); //默认的文件列表
    const [refuseModal, showRefuseModal] = useState(false);
    const [content, setContent] = useState('');//审批意见
    const [defaultImagesList, setDefaultImagesList] = useState([]); //默认的图片列表
    const [initialData, setInitialData] = useState({});
    const [{data, isLoading}] = useDataApi({
        url: '/api/user/getApprovalDetails',
        method: 'post',
        data: {project_notice_id}
    });
    const col = [
        {
            title: '序号',
            width: 65,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <span>{index+1}</span>
                )
            }

        },
        {
            title: '供应商名称',
            align: 'center',
            dataIndex: 'store_name',
            key: 'store_name',
        },
        {
            title: '联系人',
            align: 'center',
            dataIndex: 'store_user_name',
            key: 'store_user_name',
        }, {
            title: '联系电话',
            align: 'center',
            dataIndex: 'store_user_phone',
            key: 'store_user_phone',
        }, {
            title: '报名时间',
            align: 'center',
            dataIndex: 'createtime',
            key: 'createtime',
        }, {
            title: '报名状态',
            align: 'center',
            dataIndex: 'status_type',
            key: 'status_type',
            render: (text, record,index) =>{
                if( parseInt(text) <=  6 ){
                    return  (
                        <span>资审不通过</span>
                    )
                }else {
                    return <span>资审通过</span>
                }
            }
        }, {
            title: '详情',
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <a onClick={()=>push({pathname:'/applyDetail',state:{project_sign_id:record.id,readonly:true}})}>查看</a>
                )
            }
        },
    ];
    /** 邀请报名列表 */
    const invitecol = [
        {
            title: '序号',
            width: 65,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <span>{index+1}</span>
                )
            }

        },
        {
            title: '供应商名称',
            align: 'center',
            dataIndex: 'store_name',
            key: 'store_name',
        }, {
            title: '行业分类',
            align: 'center',
            dataIndex: 'business_type',
            key: 'business_type',
        }, {
            title: '公司所在地',
            align: 'center',
            dataIndex: 'address',
            key: 'address',
        }, {
            title: '注册资金',
            align: 'center',
            dataIndex: 'reg_capital',
            key: 'reg_capital',
            render: (text, record,index) =>{
                return  (
                    <span>{text} (万元)</span>
                )

            }
        },
    ];

    const confirmCol = [
        {
            title: '供应商名称',
            dataIndex: 'store_name',
            key: 'store_name',
        },
        {
            title: '联系人',
            dataIndex: 'store_user_name',
            key: 'store_user_name',
        },
        {
            title: '联系方式',
            dataIndex: 'store_user_phone',
            key: 'store_user_phone',
        },
        {
            title: '投标时间',
            key: 'createtime',
            dataIndex: 'createtime',
        },
        {
            title: '综合评分',
            key: 'fraction',
            dataIndex: 'fraction',
        },
        {
            title: '中标情况',
            key: 'status_type',
            dataIndex: 'status_type',
            render: (text,record) => {
                if (text === '22') {
                    return <span style={{color: "#333"}}>拒绝成为中标人</span>
                }else if(text !== 23){
                    return <span style={{color: "red"}}>未中标</span>

                }else{
                    return <span style={{color: "green"}}>已中标</span>
                }
            },
        },  {
            title: '标书详情',
            key: 'project_sn',
            dataIndex: 'project_sn',
            render: (text,record) => {
                return <a onClick={()=>push({pathname:'/detail/tenderDetail',state:{project_sn:text,id:record.id,type:'readonly'}})} style={{textDecoration:'underline'}}>查看</a>
            },
        },

    ];


    //type 1招标公告 2 变更公告 3 资质审批 4.招标文件，5 变更文件 6 定标报告 7 中标公示 8 终止招标 9 采购公告
    useEffect(() => {
        if (!isLoading) {
            switch (type.toString()) {
                case '1':
                    setTypeText("招标公告审批");
                    break;
                case '2':
                    setTypeText("变更公告审批");
                    break;
                case '3':
                    setTypeText("供应商资质审批");
                    break;
                case '4':
                    setTypeText("招标文件审批");
                    break;
                case '5':
                    setTypeText("变更文件审批");
                    break;
                case '6':
                    setTypeText("定标报告审批");
                    break;
                case '7':
                    setTypeText("中标公示审批");
                    break;
                case '8':
                    setTypeText("终止招标审批");
                    break;
                case '9':
                    setTypeText("采购公告审批");
                    break;
                default:
            }
            let _data = data.projectData;
            _data.wait_status = data.ProjectNotice.wait_status;
            _data.special = data.special;
            if (_data.images && _data.images.length) {
                setDefaultImagesList(_data.images)
            }
            if (_data.file && _data.file.length) {
                setDefaultFileList(_data.file)
            }
            _data.isLoading = true;
            setInitialData(_data);
        }
    }, [isLoading,type]);

    const handleRefuse = () => {
        if (!content) {
            message.error('请输入审批意见');
            return;
        }
        handleAudit({status: 2});
    };

    /** 提交审批结果 */
    const handleAudit = ({status = 1}) => {
        PublishModal.saveAudit({
            project_notice_id,
            project_approval_log_id,
            status,
            content
        }).then(({data: {data, code, msg}}) => {
            if (code === 200) {
                message.success('提交成功',5);
                const _initialData = initialData;
                _initialData.log_content = content;
                _initialData.wait_status = status;
                setInitialData({..._initialData})
                showRefuseModal(false)
            } else {
                message.error(msg);
            }
        })
    };
    const OtherTitle =()=>{
            switch (type.toString()) {
                case '1':
                    return (
                        <Fragment >
                            <Descriptions.Item label="招标联系人">{initialData.user_name}</Descriptions.Item>
                            <Descriptions.Item
                                label="联系电话">{initialData.user_phone && initialData.user_phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}</Descriptions.Item>
                            <Descriptions.Item
                                label="所属地区">{initialData.address}</Descriptions.Item>
                            <Descriptions.Item
                                        label="报名时间">{initialData.signup_time} ~ {initialData.signend_time}</Descriptions.Item>
                            <Descriptions.Item label="项目金额">{initialData.starting_price} (万元)</Descriptions.Item>
                        </Fragment>
                    );
                case '2':
                    return <Fragment >
                    </Fragment>;
                case '3':
                    return <Fragment >
                    </Fragment>;
                case '4':
                    return <Fragment >
                        <Descriptions.Item label="招标文件标题">{initialData.special.data.issued_name}</Descriptions.Item>
                        <Descriptions.Item label="投标保证金">{initialData.bond} (万元)</Descriptions.Item>
                        <Descriptions.Item
                            label="投标时间">{initialData.special.data.bidstart_time} - {initialData.special.data.bidend_time}</Descriptions.Item>
                        {initialData.bstarttime && <Descriptions.Item
                            label="保证金缴纳时间">{initialData.bstarttime} - {initialData.bendtime}</Descriptions.Item>}
                        <Descriptions.Item
                            label="评标时间">{initialData.special.data.evaluationstart_time} - {initialData.special.data.evaluationend_time}</Descriptions.Item>
                    <Descriptions.Item
                            label="评标地点">{initialData.special.data.issed_address} {initialData.special.data.address}</Descriptions.Item>
                    </Fragment>;
                case '5':
                    return <Fragment >

                    </Fragment>;
                case '6':
                    return <Fragment >

                    </Fragment>;
                case '7':
                    return <Fragment >

                           <Descriptions.Item label="中标单位" >
                                {initialData.store_name}
                            </Descriptions.Item>

                        {/*<Descriptions.Item*/}
                        {/*    label="成交时间">{initialData.completetime}</Descriptions.Item>*/}
                    </Fragment>;
                case '8': //终止招标
                    return <Fragment >

                    </Fragment>;
                case '9': //采购
                    return <Fragment >

                    </Fragment>;
                default:
            }
        };

    const OtherContent = ()=> {
        switch (type.toString()) {
            case '1':
                return  <Fragment >
                    <Descriptions title="公告正文" bordered style={{marginTop: '20px'}}>
                        <Descriptions.Item>
                            <div dangerouslySetInnerHTML={{__html: initialData.content}}/>
                        </Descriptions.Item>
                    </Descriptions>
                    {
                        defaultFileList.length > 0 && <Form.Item label='附件' >
                            {defaultFileList.map((item)=>
                                <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                            )}
                        </Form.Item>
                    }
                    {
                        defaultImagesList.length>0 &&  <Form.Item label='项目图片' style={{marginTop: '20px'}}>
                            <UploadImage disabled={true} uploadNum={3} value={defaultImagesList}/>
                        </Form.Item>
                    }
                    { initialData.tendering_type === TYPE_INVITE &&
                    <div>
                        <Descriptions style={{marginTop: '20px'}}>
                            <Descriptions.Item label="供应商报名列表"/>
                        </Descriptions>
                        <Table
                            bordered
                            pagination={false}
                            dataSource={initialData.special.data}
                            columns={invitecol}
                            style={{marginBottom: '20px'}}
                        />
                        <Descriptions>
                            <Descriptions.Item label="邀请通知">{initialData.invitation_remark}</Descriptions.Item>
                        </Descriptions>
                    </div>
                    }
                </Fragment>;
            case '2':
                return
            case '3':
                return  <Fragment >
                        <Descriptions style={{marginTop: '20px'}}>
                            <Descriptions.Item label="供应商列表"/>
                        </Descriptions>
                        <Table
                            bordered
                            pagination={false}
                            dataSource={initialData.special.data}
                            columns={col}
                            style={{marginBottom: '20px'}}
                        />
                    <Descriptions >
                        <Descriptions.Item label="资审报告信息" ><span dangerouslySetInnerHTML={{__html:initialData.presentation}}/></Descriptions.Item>
                    </Descriptions>
                </Fragment>;
            case '4':
                return  <Fragment >
                    <Descriptions title="招标文件正文" bordered style={{marginTop: '20px'}}>
                        <Descriptions.Item>
                            <div dangerouslySetInnerHTML={{__html: initialData.special.data.content}}/>
                        </Descriptions.Item>
                    </Descriptions>
                    {
                        initialData.special.data.file && <Form.Item label='附件' >
                            {initialData.special.data.file.map((item)=>
                                <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                            )}
                        </Form.Item>
                    }
                </Fragment>;
            case '5':
                return  <Fragment >


                </Fragment>;
            case '6':
                return  <Fragment >
                    <Descriptions title="定标报告正文" bordered style={{marginTop: '20px'}}>
                        <Descriptions.Item>
                            <div dangerouslySetInnerHTML={{__html: initialData.log_remark}}/>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions style={{marginTop: '20px'}}>
                        <Descriptions.Item label="供应商评分情况" />
                    </Descriptions>
                        <Table
                            bordered
                            pagination={false}
                            dataSource={initialData.special.data}
                            columns={confirmCol}
                            style={{marginBottom: '20px'}}
                        />
                        {
                            initialData.log_remark_file && <Form.Item label='附件' >
                                {initialData.log_remark_file.map((item)=>
                                    <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                                )}
                            </Form.Item>
                        }
                </Fragment>;
            case '7':
                return  <Fragment >
                    <Descriptions title="中标公示正文" bordered style={{marginTop: '20px'}}>
                        <Descriptions.Item>
                            <div dangerouslySetInnerHTML={{__html: initialData.content}}/>
                        </Descriptions.Item>
                    </Descriptions>
                    {
                        initialData.file && <Form.Item label='附件' >
                            {initialData.file.map((item)=>
                                <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                            )}
                        </Form.Item>
                    }
                </Fragment>;
            case '8':
                return  <Fragment >
                    <Descriptions title="终止原因" bordered style={{marginTop: '20px'}}>
                        <Descriptions.Item>
                            <div dangerouslySetInnerHTML={{__html: initialData.termination_remark}}/>
                        </Descriptions.Item>
                    </Descriptions>
                </Fragment>;
            case '9':
                return  <Fragment >
                    <Descriptions title="详细信息" bordered style={{marginTop: '20px'}}>
                        <Descriptions.Item>
                            <div dangerouslySetInnerHTML={{__html: initialData.content}}/>
                        </Descriptions.Item>
                    </Descriptions>
                    {
                        defaultFileList.length >0&& <Form.Item label='附件' >
                            {defaultFileList.map((item)=>
                                <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                            )}
                        </Form.Item>
                    }
                    {
                        defaultImagesList.length > 0 &&  <Form.Item label='项目图片' style={{marginTop: '20px'}}>
                            <UploadImage disabled={true} uploadNum={3} value={defaultImagesList}/>
                        </Form.Item>
                    }
                </Fragment>;
            default:
        }

    }
    return (
        <div className={css.body}>
            {!initialData.isLoading ||   <div>
               <div className={css.audit}>
                    <div className={css.boxTitle}>
                        审批管理 > { typeText }
                    </div>
                   {type !== 9 ?
                       <Descriptions bordered column={2}>
                           <Descriptions.Item label="公告标题" span={2}>{initialData.title}</Descriptions.Item>
                           <Descriptions.Item label="项目名称">{initialData.project_name}</Descriptions.Item>
                           <Descriptions.Item label="项目编号">NO:{initialData.project_sn}</Descriptions.Item>

                           <Descriptions.Item label="招标单位">{initialData.agent_type==1?initialData.company_name:initialData.entrust_company_name}</Descriptions.Item>

                           <Descriptions.Item label="行业分类">{initialData.category_trade} - {initialData.category_name}</Descriptions.Item>

                           {initialData.agent_type==2&&
                                <Fragment>
                                    <Descriptions.Item label="代理单位">{initialData.company_name}</Descriptions.Item>
                                </Fragment>
                            }
                           
                           <Descriptions.Item label="招标类型">{initialData.tentype}</Descriptions.Item>
                           <Descriptions.Item label="招标方式">{initialData.tendering_mode === MODE_OFF ?'仅线上发布招标公告':'在线电子招投标' }</Descriptions.Item >
                           {OtherTitle()}
                       </Descriptions>:
                       <Descriptions bordered column={2}>
                           <Descriptions.Item label="项目名称">{initialData.project_name}</Descriptions.Item>
                           <Descriptions.Item label="项目编号">NO:{initialData.project_sn}</Descriptions.Item>
                           <Descriptions.Item label="行业分类">{initialData.category_trade} - {initialData.category_name}</Descriptions.Item>
                           <Descriptions.Item label="采购产品">{initialData.product_name} </Descriptions.Item>
                           <Descriptions.Item label="采购总量">{initialData.total}{initialData.unit_name}</Descriptions.Item>
                           <Descriptions.Item label="采购区域">{initialData.address}</Descriptions.Item>
                           <Descriptions.Item label="采购单价">{initialData.starting_price} (元)</Descriptions.Item>
                           <Descriptions.Item label="采购联系人">{initialData.user_name}</Descriptions.Item>
                           <Descriptions.Item label="联系人电话">{initialData.user_phone}</Descriptions.Item>
                       </Descriptions>
                   }
                   {OtherContent()}
                   {
                       !initialData.log_content ||
                       <Descriptions title="审批意见" bordered={true} style={{margin: '20px 0'}}>
                           <Descriptions.Item>
                               {initialData.log_content}
                           </Descriptions.Item>
                       </Descriptions>
                   }
                    {
                        initialData.wait_status === 0  &&
                    <div className={css.btns}>
                        <Button size={"large"} style={{marginRight: '20px'}}
                                                 onClick={() => showRefuseModal(true)}>
                                审批不通过
                            </Button>
                            <Button type="primary" size={"large"}  onClick={handleAudit}>
                            审批通过
                            </Button>
                    </div>
                    }
                    <Modal visible={refuseModal} title={'审批不通过'} onOk={handleRefuse}
                           onCancel={() => showRefuseModal(false)}>
                        <Form.Item label={'审批意见'} wrapperCol={20}>
                            <TextArea autoSize onChange={(e) => setContent(e.target.value)}
                                      style={{border: '1px solid #eee', padding: '8px', minHeight: '100px'}}/>
                        </Form.Item>
                    </Modal>
                </div>
            </div> }
        </div>
    );
}
