import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import {Audit, Edit, Flow} from "./components";

import css from "./alteration.module.less";
import 'braft-editor/dist/index.css'

const { TabPane } = Tabs;

/** 招标文件变更 */
export const Alteration = (props) => {
    const [ step,setStep ] = useState(1);
    const [params,setParams] = useState({project_sn:props.location.state.project_sn||''});
    useEffect(()=>{
        const {project_sn='',step=1} = props.location.state||{};
        setStep(step);
        setParams({project_sn});
    }, [props.location.state]);

    let stepList = [
        {
            stepId: 1,
            stepName: '变更记录',
            disabled: true
        },
        {
            stepId: 2,
            stepName: '审批结果',
            disabled: true
        },
    ];

    return (
        <div className='container'>
            <Flow step={step} setStep={setStep} stepList={stepList} />
            <div className={css.listConfirm}>
                <Tabs activeKey={ step.toString() }>
                    <TabPane tab="变更记录" key="1">
                        <Edit  params={params} />
                    </TabPane>
                    <TabPane tab="审批结果" key="2">
                        <Audit params={params} />
                    </TabPane>
                </Tabs>

            </div>
        </div>
    )
};