import React, {Fragment, useEffect, useState} from "react";
import css from './commune.module.less';
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, message, Modal, Tabs } from "antd";
import {QA_BIDDING, QA_COMMEND, QA_OPEN, QA_SEND} from "../../common/static_number";
import {useDataApi} from "../../hook";
import classNames from 'classnames';
import {PublishModal} from "../../common";
import iconQuiz from 'aliasimgurl/icon_quiz.png';
import emptyImg from 'aliasimgurl/empty_img.png'

const {TextArea} = Input;
const { TabPane } = Tabs;

export const Commune = (props) => {
    const {project_sn=''} = props.location.state;
    const [replyForm] = Form.useForm();
    const [replyFlag,setReplyFlag] = useState(false);
    const [currentId,setCurrentId] = useState('');
    const [step,setStep] = useState(''); //1 约标 2 发标 3 开标 4 评标
    const [userType,setUserType] = useState(''); //用户身份 10=供应商 1=经办人 2=招标公告审批人，3=供应商审批人，4=招标文件审批人，5=开标人，6=评标人，7=定标人，8=中标公示审批人，9=终止招标审批人
    const [{data,setData},updateParams] = useDataApi();
    const [{data:initStep, isLoading}] = useDataApi({url: '/api/getstatus', method: 'post', data: {project_sn}});
    const [{data:storeList}] = useDataApi({url: '/api/getStorelist', method: 'post', data: {project_sn}});
    useEffect(()=>{
        if(!isLoading){
            setStep(initStep)
        }
    },[isLoading]);
    useEffect(()=>{
        if(step){
            updateParams({url: '/api/commentList', method: 'post', data: {type:step,project_sn}});
        }
    },[step]);
    useEffect(()=>{
        if(data.list){
            setUserType(data.userapro)
        }
    },[data]);

    /** 阶段改变事件 */
    const stepChange = (key)=>{
        console.log("key",key);
        setStep(key);
    }
    /** */
    const questionChange = ()=>{

    }
    /** 回复开关 */
    const replyFlagChange = ({id=0})=>{
        setCurrentId(id);
        setReplyFlag(!replyFlag);
    };
    /** 展开收起回复列表 */
    const moreFlag = ({index})=>{
        let _data = data;
        _data.list[index].flag = !_data.list[index].flag;
        setData({..._data});
    };
    /** 提交回复 */
    const submitReply =()=>{
         replyForm.validateFields().then((values)=>{
             PublishModal.submitReply({project_sn,type:step,pid:currentId,...values}).then(({data: {data, code, msg}}) => {
                 if (code === 200) {
                     message.success('提交成功',5,window.location.reload());
                     replyFlagChange();
                     replyForm.resetFields(['content']);
                 } else {
                     message.error(msg);
                 }
             })
         })
    };
    return (
        <div className='container'>
            <div className={css.commune}>
                <Tabs  type="card" activeKey={step.toString()} onChange={stepChange}>
                    {
                        QA_BIDDING >= initStep &&  <TabPane tab="约标-答疑记录" key={ QA_BIDDING } />
                    }
                    {
                        QA_SEND >= initStep && userType!== 6 &&<TabPane tab="发标-答疑记录" key={ QA_SEND } />
                    }
                    {
                        QA_OPEN >= initStep && userType !== 6 && <TabPane tab="开标-答疑记录" key={ QA_OPEN } />
                    }
                    {
                        QA_COMMEND >= initStep && <TabPane tab="评标-答疑记录" key={ QA_COMMEND } />
                    }

                </Tabs>
                 <Button type={"primary"} className={css.backBtn} onClick={()=> window.history.back()}>返回</Button>

                <div className={css.list}>
                    {data.list && data.list.length>0 ? data.list.map((opt,index)=>
                        <div className={css.question} key={opt.id}>
                            <div className={css.title}>{opt.content}</div>
                            <div className={css.subtitle}>
                                <span>提问者：{opt.store_name}</span><span>{opt.createtime}</span>
                                <div className={css.defaultReplyBtn} onClick={()=>replyFlagChange({id:opt.id})}>回复</div>
                                {!!opt.son.length && !opt.flag &&  <div className={classNames( css.defaultReplyBtn) } onClick={()=>moreFlag({index})}>查看回复({opt.son.length})</div>}
                                {!!opt.son.length && opt.flag && <div className={classNames( css.defaultReplyBtn, css.replyBtn)} onClick={()=>moreFlag({index})}>收起回复({opt.son.length})</div> }
                            </div>
                            { opt.flag &&
                                opt.son.map(item =>
                                    <div className={css.replyBox} key={item.id}>
                                       {/* <div className={css.reply}>
                                            {item.store_name}回复：{item.content}
                                        </div> */}
                                        <div className={css.reply}>
                                            {item.content}
                                        </div>
                                        <div className={css.subtitle}> <span>回答者：{item.answer_store_name}</span><span>{item.createtime}</span></div>
                                    </div>
                                )
                            }

                        </div>
                    ): <div className={css.emptyBox}>
                        <img src={emptyImg} alt=""/>
                        <div className={css.emptyText}>暂无任何记录</div>
                        {(userType === 10 && (step === 3 || step === 4 )) || <Button type={"primary"} style={{width:'210px'}} onClick={replyFlagChange}>去提问</Button> }
                    </div>}
                </div>
                {/*<div className={css.btns} >*/}
                {/*    <Button type={"primary"} onClick={()=> history.goBack()}>返回</Button>*/}
                {/*</div>*/}

                { (userType === 10 && (step === 3 || step === 4 )) || <div className={css.quiz}>
                    <img src={iconQuiz} onClick={replyFlagChange} alt=''/>
                </div>}
            </div>

            <Modal  title="回答"
                    visible={ replyFlag }
                    centered
                    destroyOnClose
                    onOk={submitReply}
                    okText={'提交'}
                    onCancel={replyFlagChange}
            >
                <Form form={replyForm}>
                    {
                        currentId === 0 && ((step === 3 && userType === 1) || (step === 4 && userType === 6)) && <Fragment>
                            <Form.Item label={'选择供应商'} style={{marginBottom:'0'}}/>
                            <Form.Item name={'answer'}  rules={[{ required: true, message: '请选择提问的供应商' }]}>
                                <Checkbox.Group>
                                    {
                                        storeList.map(opt=>
                                            <Checkbox key={ opt.store_id } size="large" value={ opt.store_id }>{opt.store_name}</Checkbox>
                                        )
                                    }
                                </Checkbox.Group>
                            </Form.Item>
                        </Fragment>
                    }

                    <Form.Item name={'content'} rules={[{ required: true, message: '请输入您的问题' }]}>
                        <TextArea autoSize rows={4} placeholder={'请输入您的问题'} onChange={questionChange}/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
};