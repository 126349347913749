import React, {useEffect, useState} from "react";
import css from "./publish.module.less";
import {Button, Descriptions, Modal} from 'antd';
import {useDataApi, useIsLogin} from "../../../../hook";
import {AUDIT_PUBLISH, AWAIT, FAIL, resourcesUrl, SUCCESS} from "../../../../common/static_number";
import {TagAudit, UploadImage} from "../../../../components";
import {useHistory} from "react-router-dom";
import {PublishModal} from "../../../../common";
import {tap} from "rxjs/operators";

/** 发布公告 */
export const Publish = (props) => {
    const {params:{project_sn=''}} = props;
    const [defaultFileList, setDefaultFileList] = useState([]); //默认的文件列表
    const [isLogin] = useIsLogin();
    const [defaultImagesList, setDefaultImagesList] = useState([]); //默认的图片列表
    const [initialData, setInitialData] = useState({});
    const [{data, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});

    useEffect(() => {
        if (!isLoading) {
            const _data = data;
            if (_data.images &&_data.images.length) {
                setDefaultImagesList(_data.images)
            }
            if (_data.file && _data.file.length) {
                setDefaultFileList(_data.file)
            }
            data.isLoading = true;
            setInitialData(_data);
        }
    }, [isLoading]);

    const {push} = useHistory();
    /** 撤回审批  */
    const handleRepeal = ()=>{
        if(data.status === 1){
            Modal.confirm({
                title:'系统提示',
                content:'您确定要撤回采购信息的审批嘛？',
                okText:'确定',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    repeal();
                },
                onCancel:()=>{

                }
            });
        }else{
            repeal();
        }


    };
    const repeal = ()=>{
        PublishModal.repealAudit({operation_type:'withdraw',project_sn:initialData.project_sn,type:1}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                push({pathname:'/procurement',state:{step:2,project_sn:initialData.project_sn}})
            },
            error:err=>{},
            complete:()=>{}
        });
    }

    /** 发布公告 */
    const handlePublish = ()=>{
        if(!isLogin){
            Modal.warning({
                title:'温馨提示',
                content: '您还未登录，是否现在登录？',
                okText:'去登录',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/login'
                },
                onCancel:()=>{

                }
            });
            return
        }
        PublishModal.publish({project_sn:initialData.project_sn}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                Modal.success({
                    title: '请耐心等待，系统正在审核',
                    centered: true,
                    content: '工作人员将在2个工作日内完成审核！',
                    onOk:()=> {
                        push({pathname:'/account/project'})
                    }
                });
            },
            error:err=>{},
            complete:()=>{}
        });
    };

    return (
        <div className={css.publish}>
            <div className={css.boxTitle}>
                发布采购信息
                <TagAudit data={{'1':{title:'审批中',index:AWAIT},'2':{title:'审批通过',index: SUCCESS},'3':{title:'审批未通过',index: FAIL},'4':{title:'平台审核中',index: AWAIT},'30':{title:'平台审核通过',index: SUCCESS},'31':{title:'平台审核未通过',index: FAIL}}[data.status]}/>
                {
                    ( initialData.status === 1 || initialData.status === 3 || initialData.status === 31 ) && <div className={css.info}>
                        <Button className={'withdrawBtn'} size={"small"} onClick={handleRepeal}>{data.status === 1 ? '撤回' : '编辑'}</Button>
                    </div>
                }
            </div>
            {initialData.status === 31 &&
            <Descriptions title="平台审核意见" bordered={true} style={{margin: '20px 0'}}>
                <Descriptions.Item>
                    {initialData.admin_remark}
                </Descriptions.Item>
            </Descriptions>
            }
            {initialData.status === 3 &&
            <Descriptions title="审批意见" bordered={true} style={{margin: '20px 0'}}>
                <Descriptions.Item>
                    {initialData.approvalcontent}
                </Descriptions.Item>
            </Descriptions>
            }
            <Descriptions bordered title="项目概述" column={2}>
                <Descriptions.Item label="项目名称" span={2}>{initialData.project_name}</Descriptions.Item>
                <Descriptions.Item label="项目编号" span={2}>NO:{initialData.project_sn}</Descriptions.Item>
                <Descriptions.Item label="采购物品" span={2}>{initialData.product_name}</Descriptions.Item>
                <Descriptions.Item label="行业分类">{initialData.category_trade}</Descriptions.Item>
                <Descriptions.Item label="二级分类">{initialData.category_name}</Descriptions.Item>
                <Descriptions.Item label="采购总量">{initialData.total}{initialData.unit_name}</Descriptions.Item>
                <Descriptions.Item label="采购单价">{initialData.starting_price} (元)</Descriptions.Item>
                <Descriptions.Item label="联系人">{initialData.user_name}</Descriptions.Item>
                <Descriptions.Item label="联系电话">{initialData.user_phone && initialData.user_phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}</Descriptions.Item>
                <Descriptions.Item label="所属地区" >{initialData.address}</Descriptions.Item>
                <Descriptions.Item label="采购时间" >{initialData.signup_time} ~ {initialData.signend_time}</Descriptions.Item>
            </Descriptions>
            <Descriptions title="采购详情" bordered style={{marginTop: '20px'}}>
                <Descriptions.Item>
                    <div dangerouslySetInnerHTML={{__html: initialData.content}}/>
                </Descriptions.Item>
            </Descriptions>
            { initialData.isLoading &&
            <Descriptions style={{marginTop: '20px'}} column={1}>
                {defaultFileList.length >0 && <Descriptions.Item label='附件'>
                    {defaultFileList.map((item)=>
                        <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}> {item.name}</a>
                    )}
                </Descriptions.Item>}
                <Descriptions.Item label='项目图片'>
                    <UploadImage disabled={true} uploadNum={3} value={defaultImagesList}/>
                </Descriptions.Item>
            </Descriptions>
            }
            <div className={css.btns}>
                <Button type="primary" size={"large"} disabled={ initialData.status !== 2} onClick={handlePublish}>
                    立即发布
                </Button>
            </div>
        </div>);
}