import React, {Fragment, useEffect, useState} from 'react';
import {Card, SubNav, UserInfo} from '../components';
import {YcPagination} from '../../../components';
import classNames from 'classnames';
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
import {useDataApi} from '../../../hook';
import {useHistory} from "react-router-dom";
import css from './index.module.less';
import {MODE_OFF} from "../../../common/static_number";

const window_params = {
    project_name:'',
    type:''
};
if(window.location.search){

    var Objs = window.location.search?window.location.search.split("?"):''
    var cc = Objs[1].split("&")
    // console.log(Objs)
    // console.log(cc)
    for(var i in cc){
        let aa = cc[i].split("=")
        // console.log(aa[0],aa[1])
        if(aa[0] == 'project_sn'){
            // console.log('a----project_sn',aa[1])
            window_params.project_name = aa[1]
        } else if(aa[0] == 'type'){
            // console.log('a----type',aa[1])
            window_params.type = aa[1]
        }
    }
    // console.log(window_params)
}else{
    window_params.project_name = ''
    window_params.type = ''
}

const {Search} =Input;
export const ProAudit = (props) => {
    const [params, setParams] = useState({page:1,type:'0',total:0,status:'3',
        project_name:  window.location.search ? window_params.project_name ? window_params.project_name : props.location.state ? props.location.state.project_sn :'':props.location.state ? props.location.state.project_sn :''
    });//status:  1=审批 0=未审批 3全部 type: 1 招标 2采购

    const [{data: dataSource, isLoading/*, isError */}, updateParams] = useDataApi({initialData:{data:[]}});

    useEffect(() => {
        if (!isLoading) {
            const _params = params;
            _params.total = dataSource.count;
            setParams(_params);
        }
    }, [isLoading]);
    useEffect(()=>{
        const {project_sn = params.project_name} = props.location.state||{};

        let _params = params;
        _params.project_name = project_sn;
        setParams(_params);
    }, [props.location.state]);
    useEffect(() => {
        const {project_name,type,page,status} =params;
        // console.log(params)
        updateParams({
            url: '/api/user/proNoticeList',
            method: 'post',
            data: {page, type,search_name:project_name,wait_status: status}
        })
    }, [params]);
    const {push} = useHistory();

    const handleSearch =(value,type)=>{
        let _params = params;
        _params[type] = value;
        _params.page = 1;
        setParams({..._params});
    };

    const goAudit = (id,project_approval_log_id,type) => {
        push({pathname: '/account/project/audit/detail',state:{id,project_approval_log_id,type}})
    };
    const menu =(opt)=>{
        const {wait_status} = opt;

        return <Fragment>
            { wait_status === 0 && <div className={css.btn} onClick={()=>goAudit(opt.id,opt.project_approval_log_id,opt.type)}>审批</div>}
            { wait_status === 1 && <div className={css.btn} onClick={()=>goAudit(opt.id,opt.project_approval_log_id,opt.type)}>查看</div>}
        </Fragment>
    };
    function projectNameChange (e){
        params.project_name = e.target.value;
    }
    const goDetail = (opt)=>{
        const {type} = params;
        switch (type) {
            case "1": //招标项目
                push({pathname:'/market/view/'+ opt.project_sn});
                break;
            case '2': // 采购
                push({pathname:'/bidding/view/'+ opt.project_sn});
                break;
            case '3': //投标
                push({pathname:'/market/view/'+ opt.project_sn});

                break;
            case '4': // 采购报价
                push({pathname:'/bidding/view/'+ opt.project_sn});
                break;
            default:
        }
    }
    return (

        <div className={css.body}>

                <Card title="审核管理"  cardStyle={{padding: '20px 0px'}}>
                    <Form.Item  wrapperCol={{span:10}} style={{padding: '0 20px'}}>
                        <Search
                            placeholder="请输入项目名称/项目编号进行搜索"
                            enterButton="搜索"
                            size="large"
                            defaultValue = {params.project_name}
                            onChange={projectNameChange}
                            onSearch={value => handleSearch(value,'project_name')}
                        />
                    </Form.Item>
                    <Form.Item  label={'项目类别'} wrapperCol={{span:10}} style={{padding: '0 20px'}}>
                        <div className={css.typeBox}>
                            <div className={params.type === '0'? css.active : ''} onClick={()=>handleSearch('0','type')}>全部</div>
                            <div className={params.type === '1'? css.active : ''} onClick={()=>handleSearch('1','type')}>招标项目</div>
                            <div className={params.type === '2'? css.active : ''} onClick={()=>handleSearch('2','type')}>采购项目</div>
                        </div>
                    </Form.Item>
                    <Form.Item  label={'审批状态'} wrapperCol={{span:10}} style={{padding: '0 20px'}}>
                        <div className={css.typeBox}>
                            <div className={params.status === '3'? css.active : ''} onClick={()=>handleSearch('3','status')}>全部</div>
                            <div className={params.status === '0'? css.active : ''} onClick={()=>handleSearch('0','status')}>待审批</div>
                            <div className={params.status === '1'? css.active : ''} onClick={()=>handleSearch('1','status')}>已审批</div>
                        </div>
                    </Form.Item>
                    <div className={classNames(css.header)} >
                        <div className={css.title}>项目详情</div>
                        <div className={css.status} >审批类型</div>
                        <div className={css.time}>审批状态</div>
                        <div className={css.time} style={{width:'160px'}}>提交时间</div>
                        <div className={css.operation} style={{width: '120px'}}>操作</div>
                    </div>
                    <div className={css.list}>
                    {!isLoading &&  dataSource.list.map(opt=>
                        <div className={css.row} key={opt.id}>
                            <div className={classNames(css.title)}>
                                <div className={css.name} onClick={()=>push({pathname: opt.ptype === '1'?  '/market/view/'+opt.project_sn : '/bidding/view/'+opt.project_sn})}>
                                    {opt.project_name|| '未命名'}
                                </div>
                                <div className={css.infoWrap}>
                                    <p>项目编号：{opt.project_sn}</p>
                                    <p>招标类型：{opt.tendering_type}</p>
                                    {
                                        opt.ptype !== '2'  ?  <Fragment>

                                            <p>招标方式：{opt.tendering_mode === MODE_OFF ?'仅线上发布招标公告':'在线电子招投标' }</p>
                                            {/*<div>招标单位：{opt.store_id_text}</div>*/}
                                        </Fragment> : <Fragment>
                                            {/*<div>采购单位：{opt.store_id_text}</div>*/}
                                        </Fragment>
                                    }
                                    {
                                        opt.address &&  <p>所属地区：{opt.address}</p>
                                    }
                                </div>
                            </div>
                            <div className={css.time} style={{width:'160px'}}>{opt.createtime}</div>
                            <div className={css.status} >{opt.type_text}</div>
                            <div className={css.time}>{opt.wait_status === 0 ? '未审批':opt.wait_status === 1 ? '已审批' :'已撤回'}</div>
                            <div className={css.operation} style={{width: '120px'}}>
                               {menu(opt)}
                            </div>
                        </div>

                    )}
                    <YcPagination
                        total={ params.total || 0 }
                        current={ params.page }
                        pageSize={8}
                        onChange={(e)=> {const _params = params;_params.page=e; setParams({..._params})}}
                        style={ {margin:'30px 0'} } />
                    </div>
                </Card>
            </div>

    )
}