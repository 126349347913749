import React, {useEffect, useState} from 'react';
// import { NavLink } from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {Breadcrumb, YcImage, YcPagination, YcRadio,} from '../../components';
import {BiddingList} from '../components/index';
import {useDataApi} from '../../hook/dataApi';
import headerImg from 'aliasimgurl/bidding-header.jpg';
import css from './index.module.less';
import {getSearch} from "../../helps";


export const Bidding = ()=>{
    let { push,location:{search} } = useHistory();
    const [searchValue,setSearch] =useState({});

    function updateValue(value) {
        let _v = {...searchValue,...value};
        setSearch(_v);
    }
    useEffect(()=>{
        let _s= getSearch(search);
        console.log("_s",_s);
        if(_s && Object.keys(_s).length){
            setSearch({...searchValue,..._s});

        }
    },[search]);


    const [{ data }] = useDataApi({ url: '/api/marketHeader', method: 'post', data: { c_id: 144, type: 1 }, initialData: [] });
    const [searchLable, setSearchLabel] = useState([]);

    const [ codes ] = useState(['industry_type', 'time','sign_status', 'province_id']);

    useEffect(() => {
        function updateLabel(argument) {
            let _res = data.map((opt, index) => {
                const list =   opt.son.map((o, i) => ({
                    name: o.name,
                    value: o.id
                }));
                return {
                    name: opt.name,
                    code: codes[index],
                    items: [{
                        name: '全部',
                        value: 0
                    },...list]
                }
            });
            setSearchLabel(_res);

        };
        if (data && data.length > 0) {
            updateLabel();
            console.log('-----------------setSearchLabel-------------------')
        };
    }, [data,codes]);

    const [ { isLoading,...listData },updateParams ]  =useDataApi();

    useEffect(() => {
        console.log("searchValue",searchValue);
            updateParams({url:'/api/projectList',method:'post',data:{type:2,province_id:0,industry_type:0,time:0,sign_status:0, ...searchValue}});
    }, [searchValue,updateParams]);

    function toView(opt) {
        push({pathname:"/bidding/view/"+opt.project_sn});
    }
    function pageChange (v){
        updateValue({page:v})
    }
    function goDetail(){
        push('/category/149/361/view/100')
    }
    return (
        <div className="container">
            <YcImage imgUrl={ headerImg } onClick={goDetail}  style={{'cursor': 'pointer'}}/>
            <Breadcrumb list={ {name:'采购信息'} } />
            <div className={ css.searchWrap }>
                {
                    searchLable.map((opt,index)=>
                        <YcRadio
                            key={ index }
                            items={ opt.items }
                            className={ css.searchItem }
                            label={ opt.name }
                            name={ opt.code }
                            value={ 0  }
                            more
                            onChange={ updateValue } />
                    )
                }
            </div>
            <div className={ css.listWrap }>
                <BiddingList
                    data={ listData.data.data || [] }
                    toView={ toView } />
                <YcPagination
                    total={ listData.data.count || 0 }
                    current={ searchValue.page }
                    onChange={pageChange}
                    style={ {margin:'30px 0'} } />
            </div>
        </div>
    );
}