import React, {useContext, useEffect, useRef, useState} from "react";
import css from './inform.module.less'
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Descriptions, Input, message, Modal, Table } from "antd";
import 'braft-editor/dist/index.css'
import {useHistory} from "react-router-dom";
import {TagAudit} from "../../../../components";
import {useDataApi} from "../../../../hook";
import {AUDIT_CONFIRM, AWAIT, FAIL, SUCCESS} from "../../../../common/static_number";
import {PublishModal} from "../../../../common";
import {tap} from "rxjs/operators";


const EditableContext = React.createContext();

const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async e => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} autoComplete="off"/>
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};


export const InformSuccess =(props) =>{
    const {params:{project_sn=""}} = props;

    const [initialData, setInitialData] = useState({});
    const [dataSource, setDataSource] = useState([]); //邀请中的供应商列表

    const [columns, setColumns] = useState([]);
    const [visible,setVisible] = useState(false);
    const [{data,setData,isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_CONFIRM,project_sn}});

    const [{data: supplierData}] = useDataApi({
        url: '/api/prosignScaling',
        method: 'post',
        data: {project_sn},
        initialData: []
    });// 获取定标列表
    const col = [
        {
            title: '供应商名称',
            dataIndex: 'store_name',
            key: 'store_name',
        },
        {
            title: '联系人',
            dataIndex: 'store_user_name',
            key: 'store_user_name',
        },
        {
            title: '联系方式',
            dataIndex: 'store_user_phone',
            key: 'store_user_phone',
        },
        {
            title: '投标时间',
            key: 'createtime',
            dataIndex: 'createtime',
        },
        {
            title: '中标情况',
            key: 'status',
            dataIndex: 'status',
        },
        {
            title: '中标通知',
            key: 'msg',
            dataIndex: 'msg',
            editable: true,
            render: (text,record) => {
                if (record.status !=='未中标') {
                    return <span style={{color: "green"}}>{text}</span>
                }else{  return <span style={{color: "red"}}>{text}</span>}
            },

        },
    ];
    const handleSave = row => {
        console.log("row",row);

        const newData = [...dataSource];
        console.log("newData",newData);

        const index = newData.findIndex(item => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {...item, ...row});
        setDataSource(newData);
    };

    useEffect(() => {
            if(!isLoading){
                const _data = data;
                _data.isLoading = true;
                setInitialData(_data);
            }

    }, [isLoading,data]);
    useEffect(() => {
        if(supplierData){
            setDataSource(supplierData);
        }

    }, [supplierData]);
    useEffect(() => {
        let _columns = col.map(opt => {
            if (!opt.editable) {
                return opt;
            }
            return {
                ...opt,
                onCell: record => ({
                    record,
                    editable: opt.editable,
                    dataIndex: opt.dataIndex,
                    title: opt.title,
                    handleSave: handleSave,
                }),
            };
        });
        setColumns(_columns);
    }, [dataSource]);
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    let { push } = useHistory();
    const toResult = ()=>{
        push({pathname:'/publish/listCommend',state:{stepName:'result',project_sn,step:3}});
    }

    const submit = ()=>{
        const signdata =   dataSource.map((item)=>{
            return {project_sign_id:item.id,appr_content: item.msg||''}
        });
        PublishModal.sendInformSuccess({signdata,project_sn:initialData.project_sn}).then(({data:{code,msg}})=>{
                if (code === 200) {
                    const _data = data;
                    _data.status = 25;
                    setData({..._data});
                    message.success('发送成功',5, setVisible(true));
                } else {
                    message.error(msg);
                }
            }
        );
    };
    const handleOk = ()=>{
        setVisible(false);
        push({pathname: '/account/project'})
    };
    /** 查看审批意见 */
    const showModal = ()=>{
        Modal.confirm({
            title:'审批意见',
            content: data.approvalcontent,
            okText:'确定',
            cancelText:'取消',
            maskClosable: true,
            onOk: ()=>{

            },
            onCancel:()=>{

            }
        });
    };
    /** 撤回审批  */
    const handleRepeal = ()=>{

        if(data.status === 17){
            Modal.confirm({
                title:'系统提示',
                content:'您确定要撤回中标人的审批嘛？',
                okText:'确定',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    repeal();
                },
                onCancel:()=>{

                }
            });
        }else{
            repeal();
        }


    };

    /** 撤回 */
    const repeal = ()=>{
        PublishModal.repealAudit({operation_type:'withdraw',project_sn,type:4}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                push({pathname:'/publish/listConfirm',state:{step:1,project_sn}})
            },
            error:err=>{},
            complete:()=>{}
        });
    }

    return (
        <div className={css.inform}>
            <div className={ css.boxTitle }>
                中标通知 <TagAudit data={{'17':{title:'审批中',index:AWAIT},'18':{title:'审批通过',index: SUCCESS},'19':{title:'审批未通过',index: FAIL}}[data.status]}/>
                <div className={css.info}>
                    <span >项目名称：</span>
                    <span className={css.high}>{data.project_name}</span>
                    <span >项目编号：</span>
                    <span className={css.high}>{data.project_sn}</span>
                    {
                        (initialData.status === 19 || initialData.status === 17) &&
                        <Button className={'withdrawBtn'} size={"small"} onClick={handleRepeal}>{data.status === 17 ? '撤回' : '编辑'}</Button>

                    }
                </div>
            </div>
            {initialData.status === 19 &&
            <Descriptions title="审批意见" bordered={true} style={{margin: '20px 0'}}>
                <Descriptions.Item>
                    {data.approvalcontent}
                </Descriptions.Item>
            </Descriptions>
            }
            <Descriptions >
                <Descriptions.Item label="供应商中标通知列表" />
                <Descriptions.Item label="" style={{textAlign: 'right'}}>
                    <Button className={css.yellowBtn} onClick={toResult}>查看评标结果</Button>
                </Descriptions.Item>
            </Descriptions>

            <Table components={components}  rowClassName={() => 'editable-row'} columns={columns} pagination={false} dataSource={dataSource} bordered />

            <div className={css.btns}>
                {data.status === 19 && <Button type="primary" size={"large"} onClick={showModal}>
                    查看审批意见
                </Button>}

                {
                    data.status === 18 ? <Button size={"large"} type="primary" onClick={submit} >
                        发送通知
                    </Button> : <Button type={"primary"} style={{marginLeft:'360px'}} onClick={handleOk}>返回个人中心</Button>
                }
            </div>
            <Modal
                title="通知"
                visible={visible}
                onCancel={()=>setVisible(false)}
                footer={null}
            >
                <p>您已成功发送中标通知，请等待中标人回复</p>
                <Button type={"primary"} style={{marginLeft:'360px'}} onClick={handleOk}>返回个人中心</Button>
            </Modal>
        </div>
    )
}
