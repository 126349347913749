import React from 'react';
import {message, Modal, Upload} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import {UploadConfig} from "../common";
import {resourcesUrl} from "../common/static_number";
import moment from "moment";
import css from "./upload.module.less";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
/**
 * 图片上传组件
 *  @param {String} accept 限制文件类型  default: '.png,.jpg,.jpeg',
 *  @param {Number} unloadUnm 限制上传文件数量 default: 1
 *  @param {Boolean} disabled 是否禁止上传 default: false
 *  @param {Function} onChange 图片上传成功回调
 */
export class UploadImage extends React.Component {
    constructor(props) {
        super(props);
        const {uploadNum=1, accept='.jpg, .jpeg, .png, .gif', value=[], disabled, action='https://upload-z0.qiniup.com',size=10} = props;
        const fileList = value.map(( item ) => {
                item.url = resourcesUrl + item.file;
                return {...item}}
            );
        this.state = {
            uptoken:'',
            filename:'',
            fileList,
            uploadNum,
            accept,
            disabled,
            fileBase:resourcesUrl,
            action,
            size,
        };
        this.beforeUpload = this.beforeUpload.bind(this);
        // this.props.onChange(value);
    }


    handleCancel = () => this.setState({ previewVisible: false });
    beforeUpload = (file)=> {
        const { uploadNum, accept ,size} = this.state;
        return new Promise((resolve, reject)=>{
            if(file.size > 1024*1024*size){
                message.error('上传图片为' + (file.size/1024/1024).toFixed(2)+'M ，请控制在'+size+'M以内！');
                reject();
            } else if( !accept.includes(file.name.slice(file.name.lastIndexOf('.')))  ){
                message.error('图片格式不正确');
                reject();
            } else if(this.state.fileList.length >= uploadNum){
                message.error('最多上传'+uploadNum+'张图片呦~');
                reject();
            }
            UploadConfig.fetchUploadToken().then(({data:{data:{uptoken:token}}})=>{
                this.setState({
                    uptoken: token,
                    filename: file.name
                });
                resolve();
            });

        })
    };
    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = ({ file,fileList }) =>{
        let _fileList = [...fileList];
        _fileList = _fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                const { key } = file.response;
                file.url =  this.state.fileBase + key;
                file.key = key;
            }
            return file;
        });
        this.setState({ fileList:_fileList });
        if(file.status === 'done' || file.status === "removed"){
          const list = _fileList.map((item)=>{
               const { key='' } = item.response ||{};
               return {uid:item.uid, name: item.name,file:item.file || key}
            });
            this.props.onChange([...list]);
        }
        if(file.status === 'error'){
            message.error("图片上传失败,请重新上传！");
        }
    };

    render(){
        const { disabled,uploadNum,action,accept ,uptoken,previewVisible,filename,fileList} = this.state;
        const {  previewImage } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">上传图片</div>
            </div>
        );

        return (
            <div className={css.uploadImg}>
                <Upload
                    action={action}
                    multiple={uploadNum>1}
                    data={{
                        token: uptoken,
                        key: moment(new Date()).format('YYYY-MM-DD')+'/'+parseInt(9999*Math.random())+'/' +filename
                    }}
                    accept={accept}
                    listType="picture-card"
                    fileList={fileList}
                    beforeUpload={this.beforeUpload}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    disabled={disabled}
                >
                    {disabled || ( fileList.length >= uploadNum ) ? null : uploadButton}
                </Upload>
                {disabled || <div  className={css.uploadText}>(最多可上传{uploadNum}张图片,仅支持{accept}类型图片，大小不超过10M)</div>}
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' ,marginTop: '20px'}} src={previewImage} />
                </Modal>
            </div>
        );
    }

}