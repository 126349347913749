import React, {Fragment, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import {useEffectOnce} from 'react-use';
import {Dropdown, Menu, message} from 'antd';
import {CaretDownOutlined, EnvironmentFilled, PhoneFilled} from '@ant-design/icons';
import {useIsLogin, useUserInfo} from '../hook';
import {ServicesModel} from '../common';
import {store} from '../helps';
import css from './top-header.module.less';
import avatar from 'aliasimgurl/default_avatar.png';
import cookie from 'react-cookies';
import {MASTER_URL} from "../common/static_number";

export const TopHeader = (props)=>{
    const [data] = useUserInfo();

    const [isLogin] = useIsLogin();
    const [userInfo] = useUserInfo();
    const handleLogout = ()=>{
        ServicesModel.doLogout().subscribe({
            next:res=>{
                store.clearAll();
                const domain = document.domain === 'localhost' ? document.domain : "."+document.domain.split('.').slice(-2).join('.')
                cookie.remove('token', { domain,path: '/' });
                message.success('退出成功！',3, window.location.href = '/');
            },
            error:err=>{}
        });
    }
    // useEffectOnce(()=>{
    //     function updateCity() {
    //         let _city = window.currentCity;
    //         setCity(_city||'加载中...');
    //         if (!_city) {
    //             let _timer = setTimeout(()=>{
    //                 updateCity();
    //             },1000);
    //             setTimer(_timer);
    //         };
    //     };
    //     updateCity();
    //     return ()=>{
    //         if (timer) {
    //             clearInterval(timer);
    //         }
    //     }
    // });
    const {push} = useHistory();
    const onClick = ({key})=>{
        console.log("key",key);
        push(key);
    }
    const menu = (
        <Menu onClick={onClick} className={css.items}>
            <Menu.Item key="/account/setting" className={css.item}>账户设置</Menu.Item>
            <Menu.Item key="/account/work" className={css.item}>消息通知</Menu.Item>
            <Menu.Item key="/account/project" className={css.item}>项目管理</Menu.Item>
            <Menu.Item key="/account/deposit" className={css.item}>保证金</Menu.Item>
            {userInfo.is_managemen !== 2 && <Menu.Item key="/account/employee" className={css.item}>员工管理</Menu.Item>}
            <Menu.Item key="/account/collect" className={css.item}>收藏夹</Menu.Item>
        </Menu>
    );
    return (
        <div className={ css['top-header'] }>
            <div className="container">
                <div className={ css.wraper }>
                    <div className={ css.welcome }>
                        您好，欢迎来到招标采购服务平台！ 热线电话：010 - 53673671
                    </div>
                    <div className={ css.ext }>
                        {
                            isLogin?
                            <Fragment>
                                <a className={css.userinfo} href={'/account/setting'}>
                                    <img src={data.avatar || avatar} alt=""/> {data.mobile} &nbsp;&nbsp;
                                </a>
                                <div className={css.menu}>
                                    <a href={MASTER_URL + '/account/home'} className={css.menuTitle}  target={'_block'}>个人中心 <CaretDownOutlined /></a>
                                    <div className={css.menuBox}>
                                        <a href={MASTER_URL + "/account/setting"}  target={'_block'}>账户设置</a>
                                        <a href={"/account/work"}  target={'_block'}>消息通知</a>
                                    </div>
                                </div>
                                <i></i>
                                <a onClick={ handleLogout }>退出</a>
                            </Fragment>:
                            <Fragment>
                                <NavLink to="/login">请登录</NavLink>
                                <i></i>
                                <NavLink to="/reg">免费注册</NavLink>
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}