import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import { OpenAwait, OpenResult} from "./components";
import css from "./listOpen.module.less";
import {Flow} from "../components";

const { TabPane } = Tabs;

/** 开标 */
export const ListOpen = (props) => {
    const superStep = 3; //1:约标 2：发标 3：开标 4：评标 5：定标
    const [ step,setStep ] = useState(0);
    const [params,setParams] = useState({project_sn:props.location.state.project_sn||''});

    useEffect(()=>{
        const {project_sn='',step=0} = props.location.state||{};
        setStep(step);
        setParams({project_sn});
    }, [props.location.state]);
    const superList = [
        {name: '约标', id: 2, step: 1},
        {name: '发标', id: 2, step: 2},
        {name: '开标', id: 2, step: 3},
        {name: '评标', id: 2, step: 4},
        {name: '定标', id: 2, step: 5},
    ];
    let stepList =  [
        {
            stepId: 1,
            stepName: '进入开标大厅',
            disabled: true
        },
        {
            stepId: 2,
            stepName: '查看开标结果',
            disabled: true
        },
    ];


    return (
        <div className='container'>
            <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList} superList={superList} />
            <div className={css.listOpen}>
                <Tabs className='tabs' activeKey={ step.toString() }>
                    <TabPane tab="进入开标大厅" key="1">
                        <OpenAwait params={params}/>
                    </TabPane>
                    <TabPane tab="查看开标结果" key="2">
                        <OpenResult params={params} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
};