import React, {useEffect, useState} from "react";
import css from "./publish.module.less";
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Descriptions, Modal, Table } from 'antd';
import {useDataApi} from "../../../../hook";
import {
    AUDIT_PUBLISH,
    AWAIT,
    FAIL,
    MODE_OFF,
    resourcesUrl,
    SUCCESS,
    TYPE_INVITE
} from "../../../../common/static_number";
import {TagAudit, UploadImage} from "../../../../components";
import {useHistory} from "react-router-dom";
import {PublishModal} from "../../../../common";
import {tap} from "rxjs/operators";

export const Publish = (props) => {
    const col = [
        {
            title: '序号',
            width: 65,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <span>{index+1}</span>
                )
            }

        },
        {
            title: '供应商名称',
            align: 'center',
            dataIndex: 'store_name',
            key: 'store_name',
        },
        {
            title: '行业分类',
            align: 'center',
            dataIndex: 'business_type',
            key: 'business_type',
        },
        {
            title: '注册资金',
            align: 'center',
            dataIndex: 'reg_capital',
            key: 'reg_capital',
            render: (text, record,index) =>{
                return  (
                    <span>{text} (万元)</span>
                )

            }

        },
        {
            title: '公司所在地',
            align: 'center',
            dataIndex: 'address',
            key: 'address',
        },
    ];
    const {params:{project_sn=''}} = props;
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage] = useState(''); //默认的图片列表
    const [defaultFileList, setDefaultFileList] = useState([]); //默认的文件列表
    const [initialData, setInitialData] = useState({});
    const [{data, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});

    useEffect(() => {
        if (!isLoading) {
            const _data = data;
            if (_data.file && _data.file.length) {
                setDefaultFileList(_data.file)
            }
            _data.isLoading = true;
            setInitialData({..._data});
        }
    }, [isLoading,data]);

    const handleCancel = () => setPreviewVisible(false);
    const {push} = useHistory();
    /** 撤回审批  */
    const handleRepeal = ()=>{
        if(data.status === 1){
            Modal.confirm({
                title:'系统提示',
                content:'您确定要撤回招标公告的审批嘛？',
                okText:'确定',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    repeal();
                },
                onCancel:()=>{

                }
            });
        }else{
            repeal();
        }
    };
    const repeal = () =>{
        PublishModal.repealAudit({operation_type:'withdraw',project_sn,type:1}).pipe(
            tap(res=>console.log("=====",res))
        ).subscribe({
            next:({data,code})=>{
                if(initialData.tendering_mode  === MODE_OFF){
                    push({pathname:'/publish/off',state:{step:2,project_sn:initialData.project_sn}})
                }else{
                    push({pathname:'/publish',state:{step:3,project_sn}})
                }
            },
            error:err=>{},
            complete:()=>{}
        });
    }
    const nextPage = ()=>{
            push({pathname:'/publish',state:{step:5,project_sn:initialData.project_sn}})
    };
    /** 发布公告 */
    const handlePublish = ()=>{
        PublishModal.publish({project_sn}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                Modal.success({
                    title: '请耐心等待，系统正在审核',
                    centered: true,
                    content: '工作人员将在2个工作日内完成审核！',
                    onOk:()=> {
                        window.location.reload();
                    }
                });
            },
            error:err=>{},
            complete:()=>{}
        });
    };

    return (
        <div className={css.publish}>
            <div className={css.boxTitle}>
                发布招标公告
                <TagAudit data={{'1':{title:'审批中',index:AWAIT},'2':{title:'审批通过',index: SUCCESS},'3':{title:'审批未通过',index: FAIL},'4':{title:'平台审核中',index: AWAIT},'30':{title:'平台审核通过',index: SUCCESS},'31':{title:'平台审核未通过',index: FAIL}}[data.status]}/>
                {
                   ( data.status === 1 || data.status === 3 || data.status === 31 ) && <div className={css.info}>
                        <Button className={'withdrawBtn'} size={"small"} onClick={handleRepeal}>{data.status === 1 ? '撤回' : '编辑'}</Button>
                    </div>
                }
            </div>
            {initialData.status === 31 &&
            <Descriptions title="平台审核意见" bordered={true} style={{margin: '20px 0'}}>
                <Descriptions.Item>
                    {initialData.admin_remark}
                </Descriptions.Item>
            </Descriptions>
            }
            {initialData.status === 3 &&
            <Descriptions title="审批意见" bordered={true} style={{margin: '20px 0'}}>
                <Descriptions.Item>
                    {initialData.approvalcontent}
                </Descriptions.Item>
            </Descriptions>
            }
            <Descriptions bordered title="招标概述" column={2}>
                <Descriptions.Item label="公告标题" span={2}>{initialData.title}</Descriptions.Item>
                <Descriptions.Item label="项目名称">{initialData.project_name}</Descriptions.Item>
                <Descriptions.Item label="项目编号">NO:{initialData.project_sn}</Descriptions.Item>

                <Descriptions.Item label="招标单位">{initialData.agent_type==1?initialData.company_name:initialData.entrust_company_name}</Descriptions.Item>

                <Descriptions.Item label="行业分类">{initialData.category_trade}</Descriptions.Item>

                {initialData.agent_type==2&&
                    <Descriptions.Item label="代理单位">{initialData.company_name}</Descriptions.Item>
                }
                
                <Descriptions.Item label="二级分类">{initialData.category_name}</Descriptions.Item>
                <Descriptions.Item label="招标类型">{initialData.tentype}</Descriptions.Item>
                <Descriptions.Item label="招标方式">{initialData.tendering_mode === MODE_OFF ?'仅线上发布招标公告':'在线电子招投标'}</Descriptions.Item>
                <Descriptions.Item label="招标联系人">{initialData.user_name}</Descriptions.Item>
                <Descriptions.Item label="联系电话">{initialData.user_phone && initialData.user_phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}</Descriptions.Item>
                <Descriptions.Item label="所属地区">{initialData.address}</Descriptions.Item>
                <Descriptions.Item label="项目金额">{initialData.starting_price}（万元）</Descriptions.Item>
                <Descriptions.Item label="报名时间">{initialData.signup_time} ~ {initialData.signend_time} </Descriptions.Item>
            </Descriptions>

            <Descriptions title="公告正文" bordered style={{marginTop: '20px'}}>
                <Descriptions.Item>
                    <div dangerouslySetInnerHTML={{__html: initialData.content}}/>
                </Descriptions.Item>
            </Descriptions>
            { initialData.isLoading &&
            <Descriptions style={{marginTop: '20px'}} column={1}>
                {
                    defaultFileList.length >0 && <Descriptions.Item label='附件' >
                        {defaultFileList.map((item)=>
                            <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                        )}
                    </Descriptions.Item>
                }
                {  initialData.images.length>0 &&   <Descriptions.Item label='项目图片'>
                    <UploadImage disabled={true} uploadNum={3} value={initialData.images}/>
                    <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                        <img alt="example" style={{width: '100%'}} src={previewImage}/>
                    </Modal>
                </Descriptions.Item>}
            </Descriptions>
            }
            {
                !isLoading && initialData.tendering_type === TYPE_INVITE && <div>
                    <Descriptions >
                        <Descriptions.Item label="供应商列表" />
                    </Descriptions>
                    <Table
                        bordered
                        pagination={false}
                        dataSource={data.projsign_list}
                        columns={col}
                        style={{marginBottom:'20px'}}
                    />
                    <Form.Item label={'邀请通知'} wrapperCol={20}>
                        <div style={{border: '1px solid #eee',padding: '8px',minHeight: '100px'}}>
                            {initialData && initialData.invitation_remark}
                        </div>
                    </Form.Item>
                </div>
            }

            <div className={css.btns}>
         {/*       <Button size={"large"} className={css.saveBtn} onClick={()=>window.history.back()}>
                    返回
                </Button>*/}
                {initialData.status === 2 && <Button type="primary" size={"large"}  onClick={handlePublish}>
                    立即发布
                </Button>
                }{
                    initialData.status === 30 && initialData.tendering_mode  !== MODE_OFF && <Button size={"large"}  onClick={nextPage}>
                        下一步
                    </Button>
                }
            </div>
        </div>
    );
}