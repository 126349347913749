import React, {useEffect, useState} from "react";
import css from "./audit.module.less";
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, Input, message, Modal } from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import {TagAudit, UploadFile} from "../../../../components";
import {useDataApi} from "../../../../hook";
import {AUDIT_SEND, AWAIT, FAIL, SUCCESS} from "../../../../common/static_number";
import moment from "moment";
import {PublishModal} from "../../../../common";
import {tap} from "rxjs/operators";

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 10},
};

export const Audit = (props) => {
    const { params:{project_sn=''}={}  } = props;
    const readOnly = true;
    // let { push } = useHistory();

    const [ form ] = Form.useForm();
    const [initialData, setInitialData] = useState({});
    const [{data, isLoading}] = useDataApi({url: '/api/issuedDetail', method: 'post', data: {type:AUDIT_SEND,project_sn}});
    const [params,setParams] = useState({});

    useEffect(() => {
        if (!isLoading) {
            const _data = data.project_issued;
            const _param = params;
            console.log("_data",_data);
            _data.bidend_time = _data.bidend_time?moment(_data.bidend_time, 'yy-MM-dd HH:mm'):null;
            _data.bidstart_time = _data.bidstart_time?moment(_data.bidstart_time, 'yy-MM-dd HH:mm'):null;
            _param.attachment_url = _data.attachment_url || [];
            setParams(_param);
            setEditor(BraftEditor.createEditorState(_data.content));
            _data.isLoading = true;
            setInitialData(_data);
        }
    }, [isLoading]);

    const submit = ()=>{
        console.log("submit",submit);
        PublishModal.sendFile({project_sn,is_change:1}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('发布成功！',3,window.location.reload());
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    const [editor,setEditor] = useState();
    const excludeControls = ['media', 'emoji'];
    const handleEditorChange = (editor) => {
        setEditor(editor);
    };
    /** 查看审批意见 */
    const showModal = ()=>{
        Modal.confirm({
            title:'审批意见',
            content: data.approvalcontent,
            okText:'确定',
            cancelText:'取消',
            maskClosable: true,
            onOk: ()=>{

            },
            onCancel:()=>{

            }
        });
    };
    return (
        <div className={css.audit}>
            <div className={css.boxTitle}>
                招标文件变更审批结果 {!isLoading &&  <TagAudit data={{'9':{title:'审批中',index:AWAIT},'10':{title:'审批通过',index: SUCCESS},'11':{title:'审批未通过',index: FAIL}}[data.status]}/>}
                <div className={css.info}>
                    <span >项目名称：</span>
                    <span className={css.high}>{data.project_name}</span>
                    <span >项目编号：</span>
                    <span className={css.high}>{data.project_sn}</span>
                </div>
            </div>
            {!initialData.isLoading ||  <Form
                {...layout}
                initialValues={initialData}
                scrollToFirstError = { true}
                form={form}
            >
                <Form.Item
                    labelCol={{span: 3}}
                    wrapperCol={{span: 10}}
                    label="招标文件标题"
                    name="issued_name"
                >
                    <Input placeholder='请填写招标文件标题' disabled={readOnly}/>
                </Form.Item>

                <Form.Item label="投标时间"  wrapperCol={{span: 12}} style={{marginBottom: '0'}} required>
                    <Form.Item
                        name={'bidstart_time'}
                        style={{display: 'inline-block'}}
                    >
                        <DatePicker disabled={readOnly} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
                    </Form.Item>
                    <span style={{textAlign: 'center', lineHeight: '25px', fontSize: '32px'}}>~</span>
                    <Form.Item style={{display: 'inline-block'}} name={'bidend_time'}
                               rules={[{required: true, message: '请选择投标结束时间'}]}>
                        <DatePicker disabled={readOnly} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
                    </Form.Item>
                </Form.Item>
                <Form.Item label={'招标文件正文'} labelCol={{ span: 3}}
                           wrapperCol={{ span: 21 }}>
                    <BraftEditor
                        excludeControls={excludeControls}
                        readOnly={readOnly}
                        value={editor}
                        onChange={handleEditorChange}
                        className={css.editor}
                        contentStyle={{height:'200px'}}
                        controlBarStyle={{display: readOnly? 'none' :''}}
                    />
                </Form.Item>
                <Form.Item labelCol={{ span: 3}} wrapperCol={{ offset:3,span: 12 }}>
                    <UploadFile disabled={true} uploadNum={3}
                                 defaultFileList={params.attachment_url} />
                </Form.Item>
                <div className={css.btns}>
                    {data.status === 11 && <Button type="primary" size={"large"} onClick={showModal}>
                        查看审批意见
                    </Button>}
                    {
                        data.status === 10 ?  <Button type="primary" size={"large"} onClick={ submit } >
                            发布招标变更
                        </Button>: <Button type={"primary"} onClick={()=> window.history.back()}>返回</Button>
                    }

                </div>
            </Form>}
        </div>);
}