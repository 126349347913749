import React from 'react';
// import classNames from 'classnames';
import css from './down-load-list.module.less';

export const DownLoadList =(props)=>{

    const { items=[],toViewPage } =props;
    items.forEach(item=>{

        if(item.read_num > 9999){
            item.read_num = parseInt(item.read_num/10000) +'万+';
        }
        if(item.download_num > 9999){
            item.download_num = parseInt(item.download_num/10000) +'万+';
        }
    })

    return (
        <div className={ css.list }>
            {
                items.map((opt,index)=>
                    <div className={ css.item } key={ index }>
                        <div className={ css.itemInner }>
                            <div className={ css.titleimg }  onClick={ ()=>{ toViewPage(opt.id) } }>
                                <img src={ opt.images } alt=""/>
                                <div className={ css.shade }>
                                    点击阅读
                                </div>
                            </div>
                            <div className={ css.title } onClick={()=>{toViewPage(opt.id)} }>{ opt.title }</div>
                            <div className={ css.descwrap }>
                                <div className={ css.readNum }>{opt.read_num}人阅读</div>
                                <div className={ css.downNum }>{opt.download_num}下载</div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}