import React, {useState} from 'react';
import classNames from 'classnames';
import css from './bidding-list.module.less';
import {Statistic} from "antd";

const { Countdown } = Statistic;

export const BiddingList =(props)=>{

    const { data } = props;
    const [time] = useState(Date.now());

    const getFormat =(opt)=>{
        if(opt.surplus_time > 24* 60 * 60 * 1000){
            return 'D 天'
        } else if(opt.surplus_time >  60 * 60 * 1000){
            return 'H 时'
        } else if(opt.surplus_time  >  60 * 1000){
            return 'm 分'
        }else {
            return 's 秒'
        }

    }
    return (
        <div className={ css.list }>
            <div className={ classNames(css.item,css.tableTitle) } >
                <div className={ css.title }>项目名称</div>
                <div className={ css.area }>剩余时间</div>
                <div className={ css.area }>所属地区</div>
                <div className={ css.type }>行业分类</div>
                <div className={ css.time }>发布时间</div>
            </div>
            {
                data.map((opt,index)=>
                    <div className={ css.item } key={ index } onClick={ ()=>props.toView && props.toView(opt) }>
                        <div className={ css.title }>{ opt.project_name }</div>
                        {opt.surplus_time !=='' &&  <div className={ css.area }>
                            还剩&nbsp;
                            <Countdown style={{display:"inline-block"}} valueStyle={{color: '#852a26'}} value={time + opt.surplus_time} format={getFormat(opt)}/>
                        </div>}
                        <div className={ css.area }>{ opt.province_name }</div>
                        <div className={ css.type }>{ opt.industry_type }</div>
                        <div className={ css.time }>{ opt.createtime }</div>
                    </div>
                )
            }
        </div>
    );
}