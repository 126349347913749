import React, {useState} from 'react';

import css from './close-pro.module.less';
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Modal } from "antd";
import {Contacts} from "../pages/publish/components";
import {PublishModal} from "../common";
import {AUDIT_STOP} from "../common/static_number";
import {tap} from "rxjs/operators";

const {TextArea } = Input;
export const ClosePro =(props)=>{
    const { project:project_sn ='', visible,setVisible} = props;
    const [contactsVisible, showContactsVisible] = useState(false); //审批人弹窗控制
    const [users, setUsers] = useState([]); //选中的审批人
    const [form] = Form.useForm();

    /** 审核人弹窗选择完成 */
    const handleContactsOk = e => {
        if (users.length === 0) {
            message.error('您还没有选择审批人呦~');
            return
        } else if (users.length !== 1) {
            message.error('审批人只能设置一个呦~');
            return
        }
        showContactsVisible(false);
    };
    const handleOk = e => {
        if (users.length === 0) {
            message.error('您还没有选择审批人呦~');
            return
        } else if (users.length !== 1) {
            message.error('审批人只能设置一个呦~');
            return
        }
        const audit = users[0];
        PublishModal.setResponsible({
            type: AUDIT_STOP, project_sn, is_approval:1, ...audit
        }).then(({data: {data, msg, code}}) => {
            if (code === 200) {
                form.validateFields().then((values) => {
                    console.log();
                    PublishModal.repealAudit({operation_type:'termination_remark',project_sn,...values}).pipe(
                        tap(res=>console.log(res))
                    ).subscribe({
                        next:res=>{
                            message.success('项目已终止',3);
                            handleCancel();
                        },
                        error:err=>{},
                        complete:()=>{}
                    });
                })
            } else {
                message.error(msg)
            }
        })
    };

    /** 人员选择回调函数 */
    const contactsSuccess = e => {
        setUsers(e.map(item => {
            return {username: item.title, user_id: item.id}
        }));
    };

    /** 关闭审批人弹窗 */
    const handleContactsCancel = e => {
        showContactsVisible(false);
    };

    /** handleCancel */
    const handleCancel = e=>{
        setVisible(false)
    };

    return (
        <div className={css.box}>
            <Modal  title="终止招标"
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}>
                <Form form={form}>
                    <Form.Item label={'终止原因'} name={'termination_remark'}>
                        <TextArea autoSize type="text"/>
                    </Form.Item>
                </Form>
                <Form.Item label="审批人"  style={{marginBottom: '0'}} required>
                    <Form.Item
                        wrapperCol={{span: 23}}
                        style={{display: 'inline-block'}}
                    >
                        <Input placeholder={'请选择审批人'} value={users.map(item => {
                            return item.username
                        }).join(',')} disabled/>
                    </Form.Item>
                    <Form.Item style={{display: 'inline-block'}}>
                        <Button type="primary" onClick={() => showContactsVisible(true)}>选择审批人</Button>
                    </Form.Item>
                </Form.Item>
            </Modal>
            <Modal
                title="选择审批人"
                visible={contactsVisible}
                onOk={handleContactsOk}
                onCancel={handleContactsCancel}
            >
                <Contacts success={contactsSuccess} users={users}/>
            </Modal>
        </div>

    );
}