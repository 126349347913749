import React, {useEffect, useState} from "react";
import css from "./result.module.less";
import {Button, Table} from 'antd';
import {useHistory} from "react-router-dom";
import {useDataApi} from "../../../../hook";
import {AUDIT_PUBLISH} from "../../../../common/static_number";


export const OpenResult = (props) => {
    const {params:{project_sn=''} } = props;

    const {push} = useHistory();

    const [dataSource, setDataSource] = useState([]); //供应商列表
    const [{data}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});

    const [{data: supplierData}] = useDataApi({
        url: '/api/signList',
        method: 'post',
        data: {project_sn, sign_status: '1'},//1=开标结果列表 2=报名列表 3=报名结果 4= 投标信息列表2
        initialData: []
    });// 获取公告中已选的供应商
    useEffect(() => {
        if(supplierData.list){
            setDataSource(supplierData.list);
        }
    }, [supplierData]);

    const columns = [
        {
            title: '供应商名称',
            dataIndex: 'store_name',
            key: 'store_name',
        },
        {
            title: '联系人',
            dataIndex: 'store_user_name',
            key: 'store_user_name',
        },
        {
            title: '联系方式',
            dataIndex: 'store_user_phone',
            key: 'store_user_phone',
        },
        {
            title: '保证金是否缴付',
            key: 'bail_status',
            dataIndex: 'bail_status',
            render: (text,record) => {
                switch (text) {
                    case 0:
                        return <span>未缴纳</span>;
                    case 1:
                        return <span>待后台审核</span>;
                    case 2:
                        return <span >已缴纳</span>;
                    case 3:
                        return <span >后台审核未通过</span>;
                    case 4:
                        return <span >已退回</span>;
                    case 5:
                        return <span >已冻结</span>;
                    default :
                        return <span >无需缴纳</span>;
                }
            },

        },
        {
            title: '投标状态',
            key: 'bid_status',
            dataIndex: 'bid_status',
            render: (text,record) => {
                if(text === 1){
                    return <span>已投标</span>
                }else{
                    return <span>未投标</span>
                }
            },

        },
         {
            title: '查看标书详情',
           key: 'file_status',
           render: (text,record) => {
              if(record.bid_status === 1){

                  return <a onClick={()=>push({pathname:'/detail/tenderDetail',state:{project_sn,id:record.id}})} style={{textDecoration:'underline'}}>查看</a>
               }else{
                   return <span> </span>
               }
           },

       },
    ];

    let history= useHistory();
    const goBack = ()=>{
        history.goBack();
    }
    const goCommend = ()=>{
        push({pathname:'/publish/listCommend',state:{project_sn,step:2}});
    }
    return (
        <div className={css.result}>
            <div className={css.boxTitle}>
                查看开标结果
                <div className={css.info}>
                    <span >项目名称：</span>
                    <span className={css.high}>{data.project_name}</span>
                    <span >项目编号：</span>
                    <span className={css.high}>{data.project_sn}</span>
                </div>
            </div>
            <Table columns={columns} pagination={false} dataSource={dataSource} bordered />
            <div className={css.btns}>
                {data.status === 38 ?  <Button type="primary" size={"large"} onClick={goCommend}>
                    进入评标阶段
                </Button>: <Button type="primary" size={"large"} onClick={ goBack } >
                    返回
                </Button>
              }
            </div>
        </div>);
}