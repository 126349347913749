import React, {Fragment, useEffect, useState} from "react";
import css from "./commendResult.module.less";
import {Button, Descriptions, Modal, Table} from "antd";
import {useHistory} from 'react-router-dom';
import {useDataApi} from "../../../../hook";
import {AUDIT_PUBLISH} from "../../../../common/static_number";
import {PublishModal} from "../../../../common";
import {tap} from "rxjs/operators";

export const CommentResult = (props)=>{

    const {params:{project_sn=''} } = props;
    const [columns,setColumns] = useState({});//tenderResult
    const [{data}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});
    const [initialData, setInitialData] = useState({});

    const [{data: supplierData}] = useDataApi({
        url: '/api/tenderlist',
        method: 'post',
        data: {project_sn},
        initialData: {arr:[]}
    });// 获取公告中已选的供应商

    const {push} = useHistory();
    const submitVerify = ()=>{
        if(supplierData.wcount !==0){
            Modal.confirm({
                title:'温馨提示',
                content: '您的招标项目还有'+supplierData.wcount+'位评标人未评标呦~ 是否继续提交',
                okText:'提交',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                  submit();
                },
                onCancel:()=>{

                }
            });
        }else{
            submit();
        }


    };
    const submit=()=>{
        PublishModal.goConfirm({project_sn}).pipe(
            tap(res=>{}),
        ).subscribe({
            next(res){
                push({pathname:'/publish/listConfirm',state:{project_sn,step:1}})
            }
        });

    }
    useEffect(()=>{
        if(supplierData.arr.length){
            const _columns = [
                {
                    title: '评标排名',
                    align: 'center',
                    render: (text,row,index)=>{
                        return <span>{index + 1 }</span>
                    },
                    width: 100,
                },
                {
                    title: '供应商名称',
                    dataIndex: 'store_name',
                    key: 'store_name',
                    align: 'center',
                    width: 300,
                },
                {
                    title: '平均得分',
                    dataIndex: 'fraction',
                    key: 'fraction',
                    align: 'center',
                    width: 100,
                },
            ];
            for(let  a = 0 ;a < supplierData.pcount;a++){
                _columns.push({
                    title: '评分人：'+supplierData.appuser[a].username,
                    key: supplierData.appuser[a].username,
                    dataIndex: supplierData.appuser[a].username,
                    align: 'center',
                    width: 100,
                    render: (text,row,index)=>{
                        const {id} = row;
                        const {user_id} = text[0] || [];
                      const  score =  text.filter((item)=>{
                            if(item.project_sign_id === id){
                              return item;
                            }
                            return false;
                        })[0] || {};
                        return <Fragment>
                            <span>商务：{score.business_fraction}</span> <br/>
                            <span>技术：{score.technology_fraction}</span> <br/>
                            <span ><a onClick={()=>push({pathname:'/detail/tenderDetail',state:{project_sn,id,user_id,type:'readonly'}})}>[查看]</a></span>
                        </Fragment>
                    },
                })
            }
            data.isLoading = true;
            setInitialData(supplierData);
            setColumns({isLoading:true,list:[..._columns]});
        }

    },[supplierData]);
    let history= useHistory();
    const goBack = ()=>{
        history.goBack();
    }
    return (
        <div>
            {
                 <div className={css.commendResult}>
                    <div className={ css.boxTitle }>
                        评标结果
                        <div className={css.info}>
                            <span >项目名称：</span>
                            <span className={css.high}>{data.project_name}</span>
                            <span >项目编号：</span>
                            <span className={css.high}>{data.project_sn}</span>
                        </div>
                    </div>
                    <Descriptions >
                        <Descriptions.Item label="评标结果列表" />
                    </Descriptions>
                     {!columns.isLoading ||  <Table columns={columns.list} pagination={false} dataSource={initialData.arr} bordered scroll={{ x: 1000, y: 500 }} />}
                     <div className={css.btns}>
                         {data.status === 14 ?  <Button type="primary" size={"large"} onClick={submitVerify}>
                             进入定标阶段
                         </Button>: <Button type="primary" size={"large"} onClick={ goBack } >
                             返回
                         </Button>
                         }
                    </div>
                </div>
            }
        </div>

    )
}