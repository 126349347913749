import {useDataApi} from "./dataApi";
import {useIsLogin} from "./isLogin";
import React, { useEffect } from 'react';

export const useMessageNumData = ()=>{
    const [isLogin] = useIsLogin();

    const [{data:messageNum,setData },updateParams] = useDataApi();
    useEffect(()=>{
        getData();
    },[isLogin]);

    const getData =()=>{
        console.log("isLogin",isLogin);
        if(isLogin){
            updateParams({url:'/api/user/msgCount',method:'post'});

        }
    };

    return [messageNum,getData,setData];
}