import React, {useState} from 'react';
import css from './apply_result.module.less';
import {Button} from "antd";
import {Flow} from "../../components";
import {useHistory} from 'react-router-dom';
import applySuccess from 'aliasimgurl/apply_success.png';
import applyReject from 'aliasimgurl/apply_reject.png';
import applyAwait from 'aliasimgurl/apply_await.png';
import {useDataApi} from "../../../hook";

export const ApplyResult = (props) => {
    const superStep = 1;
    const [ step,setStep ] = useState(2);
    // const { project_sn=''} = props.location.state;
    const { project_sn=''} = props.location.state;

    const [{data}] = useDataApi({url:'/api/signRes',data:{project_sn},method:'post'});
    const superList = [
        {name: '报名', id: 2, step: 1},
        {name: '投标', id: 2, step: 2},
        {name: '开标、评标', id: 2, step: 3},
        {name: '中标结果', id: 2, step: 4},
    ];
    let stepList = [
        {
            stepId: 1,
            stepName: '填写报名信息',
            disabled: true
        },
        {
            stepId: 2,
            stepName: '查看报名结果',
            disabled: true
        },
    ];


    const {push} = useHistory();

    return (
        <div className="container" >

             <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList} superList={superList} />


            <div className={css.apply}>
                <div className={css.boxTitle}>
                    查看报名结果
                </div>
                <div className={css.resultImage} >
                    <img src={{'5':applyAwait,'7':applySuccess,'6':applyReject}[data.statustype]} alt='' />
                    <div className={css.resultText} style={{color: data.statustype === 5?'#ff9c45':''}}>{{'5':'您提交的报名信息正在审批中，请耐心等待！','7':'恭喜您，您的资审已通过，报名成功！','6':'对不起，您的资审没有通过，报名失败！'}[data.statustype]}</div>
                </div>
                <div className={css.btns}>
                    {data.statustype ==='6' ?
                    <Button type="primary" size={"large"} style={{marginLeft: '20px'}}
                    >
                        下一步
                    </Button>:

                    <Button type="primary" size={"large"} style={{marginLeft: '20px'}} onClick={()=> push({pathname:'/account/project'})}>
                        返回个人中心
                    </Button>
                    }
                </div>
            </div>
        </div>
    );
}