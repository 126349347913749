import React, {useEffect, useState} from 'react';
import {Empty} from 'antd';
import {Breadcrumb, CardBox, DefaultList, YcLoading, YcPagination} from '../../components';
import css from './index.module.less';
import {BaiKeList} from "../baike/components";
import {useHistory} from "react-router-dom";
import {useDataApi} from "../../hook/dataApi";


export const Baike = (props)=>{
    const pageSize = 10;
    const cId = getParams(props.location.search,'c_id') ;
    let { push } = useHistory();

    const [page, setPage] = useState(1);

    let [{data,isLoading},updateParams] = useDataApi({url:'/api/newsList',method:'post',data:{c_id2:cId,type_id:0, page,pagesize:pageSize}});
    let [{data:hotList,isLoading:hotLoading}] = useDataApi({url:'/api/newsList',method:'post',data:{c_id2:cId,type_id:0,flag:'hot', page:1,pagesize:8}});
   // let [{data:commendList,isLoading:commendLoading}] = useDataApi({url:'/api/newsList',method:'post',data:{c_id2:cId,type_id:0,flag:'recommend', page:1,pagesize:8}});

    useEffect(()=>{
        if(!isLoading){
            updateParams({url:'/api/newsList',method:'post',data:{c_id2:cId,type_id:0, page,pagesize:pageSize}});
        }
    },[page]);

    /** 显示更多 */
    const toMorePage = (type) =>{
        if (type === 'hot') {
            push({pathname:'/category/148/178'});
        } else {
            push({pathname:'/category/148/178'});
        }
    }

    const toViewPage = (id) => {
        console.log(id);
        push('/category/148/177/view/'+id);
    };

    function getParams(url,name) {
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        let r = url.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
    };
    function pageChange (v){
        console.log("v======",v);
        setPage(v);
    };
    return (
        <div className="container">
            <Breadcrumb list={ {name:'招标百科'} } />
            <div className={ css.baike }>
                <div className={ css.listWrap }>
                    {isLoading ?<YcLoading /> : data.data && !data.data.length? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />: <BaiKeList items = { data.data} cId={cId} ></BaiKeList>}
                    <YcPagination style={ {margin:'30px 0'} }  current={ page } pageSize={ pageSize } total = { data.count} onChange={ pageChange} />
                </div>
                <div className={ css.sideBar }>
                    <CardBox title="热点新闻" more={ ()=>{toMorePage('hot')} }>
                        {hotLoading? <YcLoading />:<DefaultList items={ hotList.data } goDetail={ toViewPage } />}
                    </CardBox>
                    <div style={{ marginBottom:20 }}/>
                    {/*<CardBox title="项目推荐" more={ ()=>{ toMorePage('commend') }}>*/}
                    {/*    {commendLoading? <YcLoading />:<DefaultList items={ commendList.data } goDetail={ toViewPage } />}*/}
                    {/*</CardBox>*/}
                </div>
            </div>
        </div>
    )
}