import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import {CommendTime, CommentResult, CommentScore, } from "./components";
import css from "./listCommend.module.less";
import {Flow} from "../components";

const { TabPane } = Tabs;


/** 评标 */
export const ListCommend = (props) => {
    const superStep = 4; //1:约标 2：发标 3：开标 4：评标 5：定标
    const [ step,setStep ] = useState(0);
    const [params,setParams] = useState({project_sn:props.location.state.project_sn||''});

    useEffect(()=>{
        console.log("===========");
        const {project_sn='',step=0} = props.location.state||{};
        setStep(step);
        setParams({project_sn});
    }, [props.location.state]);
    const superList = [
        {name: '约标', id: 2, step: 1},
        {name: '发标', id: 2, step: 2},
        {name: '开标', id: 2, step: 3},
        {name: '评标', id: 2, step: 4},
        {name: '定标', id: 2, step: 5},
    ];
    let stepList =  [
        // {
        //     stepId: 1,
        //     stepName: '设置评标时间',
        //     disabled: true
        // },
        {
            stepId: 2,
            stepName: '评标人评分',
            disabled: true
        },
        {
            stepId: 3,
            stepName: '评标结果',
            disabled: true
        },
    ];

    return (
        <div className='container'>
            <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList} superList={superList} />
            <div className={css.listCommend}>
            <Tabs activeKey={ step.toString() }>
                <TabPane tab="设置评标时间" key="1">
                    <CommendTime params={params} />
                </TabPane>
                <TabPane tab="评标人评分" key="2">
                   <CommentScore params={params}/>
                </TabPane>
                <TabPane tab="评标结果" key="3">
                    <CommentResult params={params} />
                </TabPane>
            </Tabs>
        </div>

        </div>
    )
};