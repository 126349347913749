import React, {useEffect, useState} from 'react';
import {Flow} from '../components';
import {Apply, Index, MakeResult, Notice, Process, Publish} from '../publish/components/index'
import classNames from "classnames";

import css from '../publish/listMark.module.less';
import {Tabs} from "antd";
import {YcImage} from "../../components";
import headerImg from "aliasimgurl/publish-header.jpg";

const { TabPane } = Tabs;

/** 仅线上发布招标公告 */
export const OffPublish = (props) => {
    const superStep = 1; //1:约标 2：发标 3：开标 4：评标 5：定标
    const [ step,setStep ] = useState(1);
    const [params,setParams] = useState({project_sn:''});

    useEffect(()=>{
        const {project_sn='',step=1} = props.location.state||{};
        setStep(step);
        setParams({project_sn});
    }, [props.location.state]);
    const superList = [
        {name: '约标', id: 2, step: 1},
    ];
    let stepList = [
        {
            stepId: 1,
            stepName: '设置经办人',
            disabled: true
        },
        {
            stepId: 2,
            stepName: '编辑公告',
            disabled: true
        },
        {
            stepId: 3,
            stepName: '发布招标公告',
            disabled: true
        },
    ];

    return (
        <div className="container" >
             <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList} superList={superList} />
            <div className={css.list}>
                <div className={classNames(css.listMake,'listMake')} >
                    {
                        params.project_sn && <Tabs activeKey={ step.toString() }>
                            <TabPane tab="设置经办人" key="1">
                                <Process params={params}/>
                            </TabPane>
                            <TabPane tab="编辑招标公告" key="2">
                                <Notice params={params}/>
                            </TabPane>
                            <TabPane tab="发布招标公告" key="3">
                                <Publish params={params}/>
                            </TabPane>
                        </Tabs>
                    }

                </div>
            </div>
        </div>
    );
}