import React from 'react';
import css from './Card.module.less';

export const Card = (props)=>{
    const { title,extra ,cardStyle} = props;
    return(
        <div className={ css.card }>
            {
                !!title &&
                <div className={ css.title }>
                    <div className={css.name}>
                        {title}
                        <div className={css.border} />
                    </div>
                    {!!extra && <div className={css.extra}>{extra}</div>}
                </div>
            }
            <div className={ css.cardBody } style={cardStyle}>
                {props.children }
            </div>
        </div>
    );
}