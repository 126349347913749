import React from 'react';
// import classNames from 'classnames';
// import newsimg from 'aliasimgurl/img_news_demo.png';
import css from './default-list.module.less';

export const DefaultList =(props)=>{
    const { items =[], goDetail} = props;


    return (
        <div className={ css.list }>
            {
                items.map((opt,index)=>
                    <div className={ css.item } key={ index } onClick={ ()=>{goDetail(opt.id)}}>
                        <div className={ css.title } >{ opt.title }</div>
                    </div>
                )
            }
        </div>
    );
}