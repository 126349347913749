import {aesDecrypt, aesEncrypt, store} from '../helps';
import React, {useEffect, useState} from 'react';
import {useIsLogin} from './isLogin';
import {ServicesModel} from '../common';
import {Modal} from "antd";
import {useHistory} from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const useUserInfo = ()=>{

    const [isLogin] = useIsLogin();
    const [data,setData] = useState({});
    const [loading,setLoadig] = useState(true);
    const {push } =useHistory();

    useEffect(()=>{
        if (isLogin) {
            let userInfo = store.get('userinfo');
            if (userInfo) {
                setData(aesDecrypt(userInfo));
                setLoadig(false);
            }else{
                ServicesModel.getUserInfo().subscribe({
                    next:({data})=>{
                        if (data && data.userinfo) {
                            if(data.userinfo.apply_state == 0 &&data.userinfo.type != 3){
                                Modal.confirm({
                                    icon: <ExclamationCircleOutlined/>,
                                    title:'温馨提示',
                                    content: '您的账号尚未完成认证，可能会影响部分功能体验。',
                                    okText:'立即认证',
                                    cancelText:'取消',
                                    maskClosable: true,
                                    onOk: ()=>{
                                        push('/account/setting/userauth');
                                    },
                                    onCancel:()=>{

                                    }
                                });
                            }
                            setData(data.userinfo);
                            setLoadig(false);
                        }
                    }
                });
            };
        }else{
            setData({});
            setLoadig(false);
        }
    },[isLogin]);

    return [data,setData,loading];
}