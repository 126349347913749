import css from "./commendTime.module.less";
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Descriptions, Input, message, Modal, Row } from "antd";
import React, {useState} from "react";
import {Contacts} from "../contacts";

import {PublishModal} from "../../../../common";
import {useHistory} from "react-router-dom";
import {AUDIT_COMMENT, AUDIT_PUBLISH} from "../../../../common/static_number";
import {useDataApi} from "../../../../hook";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";

const {  RangePicker } = DatePicker;

export const CommendTime = (props)=>{
    const {params:{project_sn=''} } = props;

    const [visible , setVisible] = useState(false);
    const [users,setUsers] = useState([]);
    const [form] = Form.useForm();
    const [{data}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});

    let { push } = useHistory();

    const handleOk = e => {
        console.log(e);
        if(users.length === 0){
            message.error('您还没有选择经办人呦~');
        }else{
            setVisible(false);
        }
    };
    const showModal = e=>{
        setVisible(true);
    };
    const handleCancel = e => {
        setVisible(false);
    };
    const checkList = e=>{
        console.log("=============",e);
        setUsers(e.map((item)=>{
            const {title:username,id} = item;
            return {username,user_id:id}
            }
        ));
    }
    const submit = ()=>{
        if (users.length === 0) {
            message.error('您还没有选择审批人呦~');
            return
        }
        console.log("users",users);
        form.validateFields().then((values) => {

            const _params = values;
            _params.evaluationend_time = _params.evaluationend_time ? _params.evaluationend_time.format("YYYY-MM-DD HH:mm") : null;
            _params.evaluationstart_time = _params.evaluationstart_time ? _params.evaluationstart_time.format("YYYY-MM-DD HH:mm") : null;
            _params.people = [];
            const [evaluationstart_time,evaluationend_time] = _params.rangePicker;
            _params.evaluationend_time = evaluationend_time ? evaluationend_time.format('YYYY-MM-DD HH:mm') : '';
            _params.evaluationstart_time = evaluationstart_time ? evaluationstart_time.format('YYYY-MM-DD HH:mm') : '';
            users.map((item)=>{
                _params.people.push(item.user_id)
            });

            PublishModal.setBidtime({
                project_sn,
                type: AUDIT_COMMENT,
                ..._params
            }).then(({data: {msg, code}}) => {
                if (code === 200) {
                    message.success('设置成功！', 1,()=> push({pathname:'/publish/listCommend',state:{project_sn,step:2}}));
                } else {
                    message.error(msg)
                }
            })
        })
    };

    function disabledDate(current) {
        // Can not select days before today and today
        console.log(moment().subtract(1, 'days'));
        return current && current < moment().subtract(1, 'days');
    };
    return (
        <div className={css.commendTime}>
            <div className={ css.boxTitle }>
                设置评标时间
                <div className={css.info}>
                    <span >项目名称：</span>
                    <span className={css.high}>{data.project_name}</span>
                    <span >项目编号：</span>
                    <span className={css.high}>{data.project_sn}</span>
                </div>
            </div>
            <Form form={form} component={false} >
                <Form.Item  required  label="设置评标人" labelCol={{ span: 9}} wrapperCol={{ span: 8 }} style={{marginBottom: '0'}}>
                    <Form.Item
                        style={{display:"inline-block",width: '288px'}}
                    >
                        <Input placeholder='评标人姓名'   value={users.map(item =>{return item.username}).join(',')} disabled />
                    </Form.Item>
                    <Form.Item style={{display:"inline-block"}} wrapperCol={{ offset: 5}}>
                        <Button type={ 'primary' } onClick={()=>{showModal('name')}}>选择</Button>
                    </Form.Item>
                </Form.Item>




                <Form.Item label="设置评标时间" name={'rangePicker'}  labelCol={{ span: 9}}  wrapperCol={{ span: 8 }} rules={[{required: true, message: '请选择评标时间'}, ({ getFieldValue }) => ({
                    validator(rule, value) {
                    if(value[1].get('date') !== value[0].get('date')){
                    return Promise.reject('评标截止时间不得晚于评标当日的24点！');
                }
                    return Promise.resolve();
                },
                })]}>
                    <RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" locale={locale} disabledDate={disabledDate} style={{width: '100%'}}/>
                </Form.Item>
            </Form>

            <div className={css.btns}>
                <Button type="primary" size={"large"} onClick={ submit} >
                    下一步
                </Button>
            </div>
            <Descriptions style={{marginLeft: '350px',marginTop: '50px',color: '#999'}}>
                <Descriptions.Item label="" >
                    温馨提示:<br/>
                    1、评标工作应在开标后30日内完成。<br/>
                    2、评标截止时间不得晚于评标当日的24点。<br/>
                </Descriptions.Item>
            </Descriptions>
            <Modal
                title="设置评标人"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Contacts success={checkList} users={users}/>
            </Modal>
        </div>
    )
}