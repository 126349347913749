import React, {Fragment, useEffect, useState} from "react";
import css from "./apply.module.less";
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Descriptions, Table } from 'antd';
import 'braft-editor/dist/index.css'
import {useDataApi} from "../../../../hook";


/** 供应商报名列表 */
export const Apply = (props) => {
    const {params:{project_sn='',type} } = props;

    const [{data, isLoading}] = useDataApi({url: '/api/offerDetail', method: 'post', data: {type,project_sn}});

    const col = [
        {
            title: '序号',
            width: 65,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <span>{index+1}</span>
                )
            }
        },
        {
            title: '供应商名称',
            align: 'center',
            dataIndex: 'store_name',
        },
        {
            title: '联系人',
            align: 'center',
            dataIndex: 'store_user_name',
        },
        {
            title: '联系方式',
            align: 'center',
            dataIndex: 'store_user_phone',
        },
        {
            title: '报价',
            align: 'center',
            dataIndex: 'money',
        }, {
            title: '报价时间',
            align: 'center',
            dataIndex: 'updatetime',
        },{
            title: '备注',
            align: 'center',
            dataIndex: 'store_remark',
        },
    ];
    const myCol = [
        {
            title: '序号',
            width: 65,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <span>{index+1}</span>
                )
            }
        },
        {
            title: '联系人',
            align: 'center',
            dataIndex: 'store_user_name',
        },
        {
            title: '联系方式',
            align: 'center',
            dataIndex: 'store_user_phone',

        },
        {
            title: '报价',
            align: 'center',
            dataIndex: 'money',
        }, {
            title: '报价时间',
            align: 'center',
            dataIndex: 'updatetime',
        },{
            title: '备注',
            align: 'center',
            dataIndex: 'store_remark',
        },
    ];

    return (
        <div className={css.apply}>
            { !isLoading && <Fragment>
                    <div className={css.boxTitle}>
                        {/* eslint-disable-next-line no-undef */}
                        {type=== 1 ? '供应商报价列表':'采购报价'}
                        <div className={css.info}>
                            <span >项目名称：</span>
                            <span className={css.high}>{data.proinfo.project_name}</span>
                            <span >项目编号：</span>
                            <span className={css.high}>{data.proinfo.project_sn}</span>
                        </div>
                    </div>
                   <Descriptions bordered title="项目概述" column={2} style={{marginBottom: '20px'}}>
                    <Descriptions.Item label="项目名称" span={2}>{data.proinfo.project_name}</Descriptions.Item>
                    <Descriptions.Item label="项目编号" span={2}>NO:{data.proinfo.project_sn}</Descriptions.Item>
                    <Descriptions.Item label="采购物品" span={2}>{data.proinfo.product_name}</Descriptions.Item>
                    <Descriptions.Item label="行业分类">{data.proinfo.category_trade}</Descriptions.Item>
                    <Descriptions.Item label="二级分类">{data.proinfo.category_name}</Descriptions.Item>
                    <Descriptions.Item label="采购总量">{data.proinfo.total}{data.proinfo.unit_name}</Descriptions.Item>
                    <Descriptions.Item label="采购单价">{data.proinfo.starting_price} (元)</Descriptions.Item>
                    <Descriptions.Item label="联系人">{data.proinfo.user_name}</Descriptions.Item>
                    <Descriptions.Item label="联系电话">{data.proinfo.user_phone && data.proinfo.user_phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}</Descriptions.Item>
                    <Descriptions.Item label="所属地区" >{data.proinfo.address}</Descriptions.Item>
                    <Descriptions.Item label="采购时间">{data.proinfo.signup_time} ~ {data.proinfo.signend_time}</Descriptions.Item>

                   </Descriptions>
                {type=== 1 ?
                    <Fragment>
                        <Form.Item label="供应商列表" >（{data.list.length}家已经报名）</Form.Item>
                        <Table
                            bordered
                            pagination={false}
                            dataSource={data.list}
                            columns={col}
                            style={{marginBottom:'20px'}}
                        />
                    </Fragment>:<Fragment >
                        <Form.Item label="我的报价记录" />
                        <Table
                            bordered
                            pagination={false}
                            dataSource={data.list}
                            columns={myCol}
                            style={{marginBottom:'20px'}}
                        />
                    </Fragment>}

                    <div className={css.btns}>

                        <Button type="primary" size={"large"} onClick={()=>window.history.back()}>
                            返回
                        </Button>
                    </div>
                </Fragment>
            }
        </div>
    );
}