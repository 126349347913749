import React, {useEffect, useState} from 'react';
import {Flow} from '../components';
import {Apply, Index, MakeResult, Notice, Process, Publish} from './components/index'
import classNames from "classnames";
import {useHistory} from 'react-router-dom'
import css from './listMark.module.less';
import {Tabs} from "antd";
import {YcImage} from "../../components";
import headerImg from "aliasimgurl/publish-header.jpg";

const { TabPane } = Tabs;

/** 约标 */
export const ListMark = (props) => {
    const superStep = 1; //1:约标 2：发标 3：开标 4：评标 5：定标
    const [ step,setStep ] = useState(1);
    const [params,setParams] = useState({project_sn:''});
    let { push} = useHistory();

    useEffect(()=>{
        const {project_sn='',step=1} = props.location.state||{};
        setStep(step);
        setParams({project_sn});
        window.scrollTo(0,0);
    }, [props.location.state]);
    const superList = [
        {name: '约标', id: 2, step: 1},
        {name: '发标', id: 2, step: 2},
        {name: '开标', id: 2, step: 3},
        {name: '评标', id: 2, step: 4},
        {name: '定标', id: 2, step: 5},
    ];
    let stepList = [
        {
            stepId: 1,
            stepName: '创建招标项目',
            disabled: true
        },
        {
            stepId: 2,
            stepName: '设置经办人',
            disabled: true
        },
        {
            stepId: 3,
            stepName: '编辑公告',
            disabled: true
        },
        {
            stepId: 4,
            stepName: '发布招标公告',
            disabled: true
        },
        {
            stepId: 5,
            stepName: '查看报名情况',
            disabled: true
        }, {
            stepId: 6,
            stepName: '发送报名通知',
            disabled: true
        },
    ];
    function goDetail(){
        console.log("1111111");
        push('/category/149/361/view/98')
    }
    return (
        <div className="container" >
            {
                step !== 1 ? <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList} superList={superList} /> : <YcImage imgUrl={ headerImg } onClick={goDetail}  style={{'cursor': 'pointer'}}/>

            }
            <div className={css.list}>
                <div className={classNames(css.listMake,'listMake')} >
                    <Tabs activeKey={ step.toString() }>
                        <TabPane tab="创建项目" key="1">
                            <Index />
                        </TabPane>
                        <TabPane tab="设置经办人" key="2">
                            <Process params={params}/>
                        </TabPane>
                        <TabPane tab="编辑招标公告" key="3">
                            <Notice params={params}/>
                        </TabPane>
                        <TabPane tab="发布招标公告" key="4">
                            <Publish params={params}/>
                        </TabPane>
                        <TabPane tab="查看报名情况" key="5">
                            <Apply params={params}/>
                        </TabPane>
                        <TabPane tab="发送报名通知" key="6">
                            <MakeResult params={params}/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}