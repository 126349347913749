import React, {Fragment, useState} from 'react';
import classNames from 'classnames';
import css from './project-list.module.less';
import {Statistic} from "antd";

const { Countdown } = Statistic;

export const ProjectList =(props)=>{

    const {items=[],goDetail} = props;
    //     项目名称                                 采购产品                     需求数量                         所属地区                     报名截止日期
    const [time] = useState(Date.now());

    const getFormat =(opt)=>{
        if(opt.surplus_time > 24* 60 * 60 * 1000){
            return 'D 天'
        } else if(opt.surplus_time >  60 * 60 * 1000){
            return 'H 时'
        } else if(opt.surplus_time  >  60 * 1000){
            return 'm 分'
        }else {
            return 's 秒'
        }

    }
    return (
        <div className={css.list} style={{minHeight: '350px'}}>
            <div className={classNames(css.item, css.tableTitle)}>
                <div className={css.title}>项目名称</div>
                <div className={css.regin}>所属地区</div>
                <div className={css.type}>行业分类</div>
                <div className={css.area}>剩余时间</div>
                {/*<div className={ css.biddingType }>报名截止日期</div>*/}
                {/*<div className={ css.time }>报名日期</div>*/}
            </div>
            {
                items.map((opt, index) =>
                    <div className={css.item} key={index} >
                        <div className={css.title} onClick={() => goDetail(opt)}>{opt.project_name}</div>
                        <div className={css.regin}>{opt.province_name}</div>
                        <div className={css.type}>{opt.industry_type}-{opt.industry_id}</div>
                         <div className={css.area}>
                             {opt.surplus_time !=='' && <Fragment>
                            还剩&nbsp;
                            <Countdown style={{display:"inline-block"}} valueStyle={{color: '#852a26'}} value={time + opt.surplus_time} format={getFormat(opt)}/>
                            </Fragment>}
                            </div>
                        {/*<div className={ css.biddingType }>{ opt.biddingType }</div>*/}
                        {/*<div className={ css.time }>{ opt.signup_time }</div>*/}
                    </div>
                )
            }
        </div>
    );

}