import React from 'react';
import {Steps} from 'antd';

import css from './flow.module.less';

const {Step} = Steps;

export const Flow = (props) => {
    const { step} = props;
    let stepList = [
        {
            stepId: 1,
            stepName: '设置经办人',
            disabled: true
        },
        {
            stepId: 2,
            stepName: '编辑采购信息',
            disabled: true
        },
        {
            stepId: 3,
            stepName: '发布采购信息',
            disabled: true
        },
        {
            stepId: 4,
            stepName: '供应商报价结果',
            disabled: true
        },
    ];
    return (
        <div className={css.flow}>
            <div className={css.steps}>
                <Steps progressDot initial={1} current={step}>
                    {stepList.map((opt, index) =>
                        <Step title={opt.stepName} description="" key={'items'+index} disabled={ opt.disabled} className={opt.disabled? css.disabled :''}/>
                    )}
                </Steps>
            </div>
        </div>
    );
}