import React from 'react';
import css from './empty.module.less';
import IconEmptyMessage from 'aliasimgurl/icon_empty_message.png';
import IconEmpty from 'aliasimgurl/icon_empty.png';

export const EmptyMessage = (props)=>{
    return (
        <div className={css.emptyBox}>
            <img src={IconEmptyMessage} alt=""/>
        </div>
    );
}


export const EmptyData = (props)=>{
    return (
        <div className={css.emptyData}>
            <img src={IconEmpty} alt=""/>
            <div >暂无数据</div>
        </div>
    );
}