import React from 'react';
import {Breadcrumb} from '../../../components';
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Tooltip } from 'antd';
import {ServicesModel} from '../../../common';
import {useHistory} from "react-router-dom";
import {Card} from '../components';
import css from './index.module.less';

export const Password = ()=>{

    const { replace } = useHistory();
    const onSubmit = v=>{
        const { newpassword,renewpassword } = v;
        if (!newpassword || newpassword !==renewpassword) {
            return message.error('密码设置不正确！');
        };
        ServicesModel.doResetpwd(v).subscribe({
            next:res=>{
                message.success('修改成功',1,()=>{
                    replace('/account/setting');
                })
            }
        })
    }

    return (
        <div className={ css.formBody }>
            <Breadcrumb list={[{name:'基本信息',path:'/account/setting'},{name:'修改密码'}]} />
            <Card>
                <div className={ css.editForm }>
                    <h3>修改密码</h3>
                    <Form onFinish={ onSubmit} className={ css.form }>
                        <Form.Item name="oldpassword">
                            <Input.Password size="large" placeholder="请输入原密码" style={{width:'100%'}} autoComplete="off"/>
                        </Form.Item>
                        <Tooltip title={<span>长度为6~20个字符,<br/>必须包含字母/数字,<br/>不允许有空格、中文</span>}  trigger={['focus','hover']} >
                        <Form.Item

                            name="newpassword">
                            <Input.Password size="large" placeholder="请输入新密码" style={{width:'100%'}} autoComplete="off"/>
                        </Form.Item>
                        </Tooltip>
                        <Form.Item

                            name="renewpassword">
                            <Input.Password size="large" placeholder="请确认新密码" style={{width:'100%'}} autoComplete="off"/>
                        </Form.Item>
                        <Form.Item className={ css.btnWrap }>
                            <Button size="large" type="primary" htmlType="submit">提交修改</Button>
                        </Form.Item>
                    </Form>
                </div>
            </Card>
        </div>
    );
}