import React, {useState} from 'react';
import {Input} from 'antd';
import {useHistory} from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';
import className from 'classnames';
import css from './index-search.module.less'
import iconSearch from 'aliasimgurl/icon_search.png';

const { Search } = Input;

export const IndexSearch = () => {
    const {push} = useHistory();
    const [type ,setType] = useState('1');
    const selectChange = (v)=>{
        setType(v)
    }
    const handleSearch = e=>{
        if(type === '1'){
            push('/market?search_key='+e)
        }else{
            push('/bidding?search_key='+e)
        }
    };

    return (
        <div className={css.searchWarp}>
            <div className={css.tabs}>
                <div className={className(css.tab,type === '1' ?css.active: '')} onClick={()=>selectChange('1')}>招标项目</div>
                <div className={className(css.tab,type === '2' ?css.active:'')} onClick={()=>selectChange('2')}>采购项目</div>
            </div>
            <Search prefix={<img src={iconSearch} alt=""/>}
                    style={{width: '350px'}}
                    placeholder="请输入关键字"
                    onChange={ e=>console.log(e.target.value) }
                    onSearch={handleSearch}
                    enterButton="搜索" />
        </div>

    );
}