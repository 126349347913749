import React, {useEffect, useState} from 'react';
import {Card, SubNav, UserInfo} from '../components';
import {EmptyMessage, YcPagination} from '../../../components';
import classNames from 'classnames';
import {Button, Radio} from 'antd';
import {useDataApi, useNoticeNumData} from '../../../hook';
import {useHistory} from "react-router-dom";
import css from '../index.module.less';
import {PublishModal} from "../../../common";

export const Workbench = () => {

    const pList = [{
        label: '审批通知',
        key: '1'
    }, {
        label: '招标通知',
        key: '2'
    }, {
        label: '采购通知',
        key: '3'
    }, {
        label: '系统通知',
        key: '4'
    }];

    const [currentTopic, setCurrentTopic] = useState(pList[0]);
    const [numData, setData, getData] = useNoticeNumData();

    const handleCatgory = (opt) => {
        setCurrentTopic(opt);
        const _params = params;
        _params.category = opt.key;
        setParams({..._params});
    };

    const [dataList, setDataList] = useState([]);
    const [params, setParams] = useState({});

    const [{data: dataSource, isLoading/*, isError */}, updateParams] = useDataApi({
        url: '/api/user/NoticeList',
        method: 'post',
        data: {page: 1, category: currentTopic.key}
    });
    useEffect(() => {
        if (!isLoading) {
            const {data = [], total} = dataSource;
            const _params = params;
            _params.total = total;
            setDataList(data);
            setParams(_params);
        }
    }, [isLoading]);
    useEffect(() => {
        const {page = 1, category} = params;
        updateParams({
            url: '/api/user/NoticeList',
            method: 'post',
            data: {page, category}
        });
    }, [params]);
    const {push} = useHistory();
    const goDetail = ({project_sn, category, type, status, id}) => {
        const callback = () => {
            skip({project_sn, category, type});
        }
        markRead({project_sn, category, type, status, id, callback});
    };
    const markRead = ({
                          project_sn, category, type, status, id, callback = () => {
        }
                      }, index = null) => {
        if (!status) {
            PublishModal.updateNotice({project_notice_id: id}).pipe().subscribe({
                next: res => {
                    callback();
                    if (index !== null) {
                        const _dataList = dataList;
                        _dataList[index].status = 1;
                        setDataList(_dataList);
                        getData();
                    }


                },
                error: err => {
                },
                complete: () => {
                }
            });
        } else {
            callback();
        }
    };

    const skip = ({category, type, project_sn}) => {
        switch (category) {
            case 1 : //审核通知
                push({pathname: '/account/project/audit', state: {project_sn}});
                break;
            case 21 : //投标信息
                if (type === 20 || type === 21 || type === 142) { //评标
                    push({pathname: '/account/bid/list', state: {project_sn}});
                } else if (type === 18 || type === 19 || type === 22) { //开标
                    push({pathname: '/account/project/list', state: {project_sn, type: '5'}});
                } else {
                    push({pathname: '/account/project/list', state: {project_sn, type: '1'}});
                }
                break;
            case 22 : //招标信息
                if(type == '131'){
                    push({pathname: '/account/bid/list', state: {project_sn}});
                }else{
                    push({pathname: '/account/bid/list', state: {project_sn, type: '3'}});
                }
                break;
            case 3 : //采购信息
                push({pathname: '/account/project/list', state: {project_sn, type: '2'}});
                break;
            default:
                break;
        }
    }

    const allReady = () => {
        PublishModal.resetMessage({type: currentTopic.key}).pipe().subscribe({
            next: res => {
                window.location.reload();
            },
            error: err => {
            },
            complete: () => {
            }
        });
    };
    return (

        <div>
            {!isLoading && <Card title="消息通知">
                <div className={css.listTitle}>
                    {
                        pList.map((opt) =>
                            <div className={classNames(css.item, {[css.active]: opt.key === currentTopic.key})}
                                 onClick={() => handleCatgory(opt)}
                                 key={opt.key}>
                                <div className={css.label}>{opt.label}</div>
                                <div className={css.num}>{numData['category_' + opt.key]}</div>
                            </div>
                        )
                    }
                </div>
                <div className={css.currentTopic}>
                    {
                        (currentTopic.key === '2' || currentTopic.key === '21' || currentTopic.key === '22') ?
                            <div>
                                <Radio.Group value={currentTopic.key}
                                             onChange={e => handleCatgory({label: '招标信息', key: e.target.value})}>
                                    <Radio value="2">全部</Radio>
                                    <Radio value="21">招标通知</Radio>
                                    <Radio value="22">投标通知</Radio>
                                </Radio.Group>
                            </div> : <div className={css.t}>{currentTopic.label}</div>
                    }
                    <div className={css.ready_text} onClick={allReady}>全部标为已读</div>
                </div>
                <div className={css.dataList}>
                    <div className={css.item}>
                        <div className={css.title}>通知</div>
                        <div className={css.time}>时间</div>
                        <div className={css.time}>状态</div>
                        {currentTopic.key !== '4' && <div className={css.action} style={{paddingLeft: '13px'}}>操作</div>}
                    </div>
                    {
                        dataList.length > 0 ? dataList.map((opt, index) =>
                            <div key={opt.id} className={css.item}>
                                <div className={css.title}>{opt.content}</div>
                                <div className={css.time}>{opt.createtime}</div>
                                <div className={css.time}
                                     style={{color: !opt.status ? '#5e82da' : '#999'}}>{opt.status_text}</div>
                                {currentTopic.key !== '4' && <div className={css.action}>
                                    <Button onClick={() => goDetail(opt)}>查看</Button>
                                    {opt.status === 0 &&
                                    <a onClick={() => markRead(opt, index)} style={{'margin-left':'5px'}} >标为已读</a>}

                                </div>}
                            </div>
                        ) : <EmptyMessage/>
                    }
                    <YcPagination style={{margin: '30px 0'}} total={params.total || 0}
                                  current={params.page}
                                  onChange={(e) => {
                                      const _params = params;
                                      _params.page = e;
                                      setParams({..._params})
                                  }}/>
                </div>
            </Card>}
        </div>

    )
}