import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {Breadcrumb, YcImage, YcPagination, YcRadio} from '../../components';
import {useDataApi} from '../../hook/dataApi';
import headerImg from 'aliasimgurl/complex-header.png';
import css from './index.module.less';
import {BidhallList} from "./components";


export const BidHall = (props) => {

    let { push} = useHistory();
    const [searchValue, setSearch] = useState({});

    useEffect(()=>{
        let _s= props.location.state;
        if(_s && Object.keys(_s).length){
            setSearch({...searchValue,..._s});
        }
    },[props.location.state]);

    function updateValue(value) {
        console.log("vvvvvv",value);
        let _v = { ...searchValue, ...value };
        setSearch(_v);
    }

    const [{ data }] = useDataApi({ url: '/api/marketHeader', method: 'post', data: { c_id: 145, type: 1 }, initialData: [] });
    const [searchLable, setSearchLabel] = useState([]);
    const [ codes ] = useState(['industry_type', 'province_id']);


    useEffect(() => {
        function updateLabel(argument) {
            let _res = data.map((opt, index) => {
                const list =   opt.son.map((o, i) => ({
                    name: o.name,
                    value: o.id
                }));
                return {
                    name: opt.name,
                    code: codes[index],
                    defaultValue: searchValue[codes[index]] || 0,
                    items: [{
                        name: '全部',
                        value: 0
                    },...list]
                }
            });
            setSearchLabel(_res);
        };
        updateLabel();
    }, [data,codes,searchValue]);

    const [ { isLoading,...listData },updateParams ]  =useDataApi({});

    useEffect(() => {
        updateParams({url:'/api/projectList',method:'post',data:{type:3,province_id:0,sign_status:0,infor_type:0,time:0,page:1,industry_type:0, ...searchValue}});
    }, [searchValue]);

    function toView(opt) {
        push({pathname:'/bidhall/view/'+ opt.project_sn});
    }
    function pageChange (v){
        updateValue({page:v})
    }
    return (
        <div className="container">
            <YcImage imgUrl={ headerImg } />
            <Breadcrumb list={ {name:'中标公示'} } />
            <div className={ css.searchWrap }>
                {
                    searchLable.map((opt,index)=>
                        <YcRadio
                            key={ index }
                            items={ opt.items }
                            className={ css.searchItem }
                            label={ opt.name }
                            name={ opt.code }
                            value={ opt.defaultValue }
                            more
                            onChange={ updateValue } />
                    )
                }
            </div>
            <div className={ css.listWrap }>
                <BidhallList itemsLength={ 16 } data={ listData.data.data || [] } toView={ toView } />
                <YcPagination
                    total={ listData.data.count || 0 }
                    current={ searchValue.page}
                    onChange={pageChange}
                    style={ {margin:'30px 0'} } />
            </div>
        </div>
    );
}