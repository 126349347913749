import React from 'react';
// import classNames from 'classnames';
// import newsimg from 'aliasimgurl/img_news_demo.png';
import css from './common-list.module.less';

export const ProjectList =(props)=>{

    const {items=[],goDetail} = props;


    return (
        <div className={ css.list }>
            {
                items.map((opt,index)=>
                    <div className={ css.item } key={ index } onClick={()=>goDetail(opt)}>
                        <div className={ css.title }>{ opt.project_name }</div>
                        <div className={ css.time }>{ opt.createtime }</div>
                    </div>
                )
            }
        </div>
    );
}