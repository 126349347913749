import React, {useState} from "react";
import css from "./process.module.less";
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, message, Modal, Row } from 'antd';
import {PublishModal} from "../../../../common";
import {AUDIT_OPERATOR} from "../../../../common/static_number";
import {useHistory} from "react-router-dom";
import {store,aesDecrypt} from '../../../../helps';
import {Contacts} from "../../components";
import {useUserInfo} from "../../../../hook";


const col={
    labelCol: { span: 6 },
    wrapperCol: { span: 17 },
};

/** 设置经办人*/
export const Process = (props) => {

    const {params:{project_sn=''}} = props;
    const {push} = useHistory();
    const [visible , setVisible] = useState(false);
    const {username,user_id} = useUserInfo() ;
    const [users,setUsers] = useState([{username,user_id}]);
    const showModal = e=>{
        setVisible(true);
    };
    const handleOk = e => {
        verify();
    };
    const verify = ()=>{
        if(users.length === 0){
            message.error('您还没有选择经办人呦~');
            return false;
        }else if( users.length === 1){
            setVisible(false);
            return true;
        }else{
            message.error('经办人只能设置一个呦~');
            return false;
        }
    };

    const handleCancel = e => {
        setVisible(false);
    };
    const submit = e=>{
          if(!verify()){
              return
          }
        PublishModal.setResponsible({type:AUDIT_OPERATOR,project_sn,...users[0]} ).then(({data: {data, msg, code}})=>{
            if(code ===200){
                message.success('设置成功',1,
                    ()=>{
                        if(user_id === users[0].user_id){
                            push({pathname:'/procurement',state:{step:2,project_sn}})
                        }else{
                            push({pathname:'/account/project'})
                        }
                    }
                );
            }else{
                message.error(msg)
            }


        });
    };
    const checkList = e=>{
        setUsers(e.map(item=> {return {username: item.title,user_id:item.id}}));
    };
    return (<div className={css.process}>
        <div className={css.boxTitle}>
            设置经办人
            <div className={css.info}>
                <span >项目编号：</span>
                <span className={css.high}>{project_sn}</span>
            </div>
        </div>
        <Form
            initialValues={{ remember: true }}
            scrollToFirstError = { true}
        >
            <Row justify="center">
                <Col span={10}>
                    <Form.Item
                        {...col}
                        label="设置经办人"
                        required
                    >
                        <Input placeholder='经办人姓名'   value={users.length>0 ?users[0].username:''} disabled />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Button type={ 'primary' } onClick={()=>{showModal()}}>选择</Button>
                </Col>
            </Row>

            <div className={css.btns}>
              {/*  <Button  size={"large"} onClick={ prePage }>
                    上一步
                </Button>*/}
                 <Button size={ "large"} className={css.saveBtn} onClick={submit}>
                    提交
                </Button>
                {/*:
                <Button type="primary" size={"large"} onClick={ nextPage } ghost>
                    下一步
                </Button>}*/}
            </div>
        </Form>

        <Modal
            title="设置经办人"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Contacts success={checkList} users={users}/>
        </Modal>
    </div>);
}