import React, {Fragment} from 'react';
import './App.css';
import {EmptyBox, Footer, IndexHeader, NoticeNum, TopHeader} from './components';
import {Redirect, Route, Switch} from "react-router-dom";

import {Home} from './pages/home';
import {List} from './pages/list';
import {View} from './pages/view';
import {Bidding} from './pages/bidding';
import {BiddingView} from './pages/bidding/view';
import {BidHall} from './pages/bidhall';
import {BidHallView} from './pages/bidhall/view';
import {Market} from './pages/market';
import {MarketView} from './pages/market/view';
import {Baike} from './pages/baike/index';
import {BaikeView} from './pages/baike/view';
import {LoginPage} from './pages/login/index';
import {RegPage} from './pages/login/reg';
import {RecoverPage} from './pages/login/recover';
import {DownloadPage} from './pages/download/index';
import {DownloadPageView} from './pages/download/view';
import {Account} from './pages/account';
import {ListMark} from './pages/publish/listMark';
import {Commune} from "./pages/publish/commune";
import {ListCommend} from "./pages/publish/listCommend";
import {ListConfirm} from "./pages/publish/listConfirm";
import {ListConfirmNew} from "./pages/publish/listConfirmNew";
import {ListOpen} from "./pages/publish/listOpen";
import {ListSend} from "./pages/publish/listSend";
import {Apply} from "./pages/market/apply/apply";
import {ApplyResult} from "./pages/market/apply/applyResult";
import {Tender} from "./pages/market/tender";
import {TenderDetail} from "./pages/detail/tenderDetail";
import {Command} from "./pages/publish/operator/setCommand";
import {Adopt} from "./pages/market/adopt/adopt";
import {ApplyDetail} from "./pages/detail/applyDetail";
import {Accept} from "./pages/accept";
import {Procurement} from "./pages/publish/procurement/procurement";
import {Alteration} from "./pages/publish/alteration/alteration";
import {PreviewBidding} from "./pages/preview/previewBidding";
import {PreviewMarket} from "./pages/preview/previewMarket";
import {Detail} from "./pages/detail/detail";
import {OffPublish} from "./pages/off-publish/listMark";
import {useMessageNumData} from "./hook/messageNumData";
import {MainNav} from "./router";

function App() {
    const [messageNum] = useMessageNumData();
    
    return (
        <div className="App">
            <Switch>
                    <Redirect exact from="/login" to="/login/login" />
                    <Redirect exact from="/reg" to="/login/reg" />
                    <Redirect exact from="/recover" to="/login/recover" />

                    <Route exact path="/login/:currentModal" component={LoginPage}/>
                    <Route exact path={['/account','/account/:c1','/account/:c1/:c2','/account/:c1/:c2/:c3']} component={ Account }/>

                    <Fragment>
                        <TopHeader />
                        <IndexHeader />
                        <NoticeNum num={ messageNum }/>
                        <MainNav />
                            <Switch>
                                <Redirect exact from="/" to="/index" />

                                <Route exact path="/index" component={Home}/>
                                <Route exact path="/market" component={Market}/>
                                <Route exact path="/market/view/:project_sn" component={MarketView}/>
                                <Route exact path="/bidding" component={Bidding}/>
                                <Route exact path="/bidding/view/:project_sn" component={BiddingView}/>
                                <Route exact from="/bidhall" component={BidHall}/>
                                <Route exact path="/bidhall/view/:project_sn" component={BidHallView}/>
                                <Route exact path="/baike" component={Baike}/>
                                <Route exact path="/download" component={DownloadPage}/>
                                <Route exact path="/detail/tenderDetail" component={TenderDetail}/>
                                <Route exact path="/procurement" component={Procurement}/>
                                <Route exact path="/alteration" component={Alteration}/>
                                <Route exact path="/publish" component={ListMark}/>
                                <Route exact path="/publish/listCommend" component={ ListCommend }/>
                                <Route exact path="/publish/listConfirm" component={ ListConfirm }/>
                                <Route exact path="/publish/listConfirmNew" component={ ListConfirmNew }/>
                                <Route exact path="/publish/listOpen" component={ ListOpen }/>
                                <Route exact path="/publish/listSend" component={ ListSend }/>
                                <Route exact path="/publish/commune" component={ Commune }/>
                                <Route exact path="/publish/score" component={ Command }/>
                                <Route exact path="/publish/off" component={OffPublish}/>

                                <Route exact path="/project/accept" component={ Accept }/>
                                <Route exact path="/operator/Command" component={ Command }/>
                                <Route exact path="/market/apply" component={ Apply }/>
                                <Route exact path="/market/apply/result" component={ ApplyResult }/>
                                <Route exact path="/market/adopt/" component={ Adopt }/>
                                <Route exact path="/market/tender" component={ Tender }/>
                                <Route exact path="/applyDetail" component={ ApplyDetail }/>
                                <Route exact path="/detail" component={ Detail }/>
                                <Route exact path="/baike" component={Baike}/>
                                <Route exact path="/baike/view/:c_id/:id" component={BaikeView}/>
                                <Route exact path="/download" component={DownloadPage}/>
                                <Route exact path="/download/view/:c_id/:id" component={ DownloadPageView }/>
                                <Route exact path="/previewBidding/:project_sn" component={PreviewBidding}/>
                                <Route exact path="/previewMarket/:project_sn" component={PreviewMarket}/>
                                <Route exact
                                    path={ ['/category/:cid','/category/:cid/:cid','/category/:cid/view/:id','/category/:cid/:id/view/:id' ] }
                                    component={ List } />
                                <Route exact path="/view" component={View}/>
                                <Route key="error" render={ (props) => <EmptyBox /> } />
                            </Switch>
                        <Footer />
                    </Fragment>
                    <Route key="error" render={ (props) => <EmptyBox /> } />
                </Switch>
        </div>
    );
}

export default App;