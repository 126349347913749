import React, {Fragment, useEffect, useState} from 'react';
import css from './detail.module.less';
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Descriptions, Divider, Modal, Table } from "antd";
import {useHistory} from 'react-router-dom';
import {useDataApi} from "../../hook";
import {MODE_OFF, resourcesUrl, TYPE_INVITE} from "../../common/static_number";
import {UploadImage} from "../../components";

/** 投标详情页面 */
export const Detail = (props) => {
    const { project_sn=''} = props.location.state;
    const [{data, isLoading}] = useDataApi({url: '/api/projectinfo', method: 'post', data: {project_sn}});
    const [resultCol,setResultCol] = useState();
    const {push} = useHistory();
    useEffect(()=>{
        if(!isLoading){
            const _data = data.evaluation_list;
            if(_data.appuser.length){
                const _columns = [
                    {
                        title: '评标排名',
                        align: 'center',
                        render: (text,row,index)=>{
                            return <span>{index + 1 }</span>
                        },
                        width: 100,
                    },
                    {
                        title: '供应商名称',
                        dataIndex: 'store_name',
                        key: 'store_name',
                        align: 'center',
                        width: 300,
                    },
                    {
                        title: '平均得分',
                        dataIndex: 'fraction',
                        key: 'fraction',
                        align: 'center',
                        width: 100,
                    },
                ];
                for(let  a = 0 ; a < _data.pcount; a++){
                    _columns.push({
                        title: '评分人：'+_data.appuser[a].username,
                        key: _data.appuser[a].username,
                        dataIndex: _data.appuser[a].username,
                        align: 'center',
                        width: 100,
                        render: (text,row,index)=>{
                            const {id} = row;
                            const {user_id} = text;

                            for(let  len = 0,length= text.length;a < length;len++){
                                if(text[len].project_sign_id === row.id){
                                    return <Fragment>
                                        <span>商务：{text[len].business_fraction}</span> <br/>
                                        <span>技术：{text[len].technology_fraction}</span> <br/>
                                        <span ><a onClick={()=>push({pathname:'/detail/tenderDetail',state:{project_sn,id,user_id,type:'readonly'}})}>[查看]</a></span>
                                    </Fragment>
                                }
                            }
                        },
                    })
                }
                data.isLoading = true;
                console.log("[..._columns]",[..._columns]);
                setResultCol([..._columns]);
            }
        }


    },[data,isLoading]);
    /** 邀请报名列表 */
    const invitecol = [
        {
            title: '序号',
            width: 65,
            align: 'center',
            render: (text, record,index) =>{
                return  (
                    <span>{index+1}</span>
                )
            }

        },
        {
            title: '供应商名称',
            align: 'center',
            dataIndex: 'store_name',
            key: 'store_name',
        }, {
            title: '行业分类',
            align: 'center',
            dataIndex: 'business_type',
            key: 'business_type',
        }, {
            title: '公司所在地',
            align: 'center',
            dataIndex: 'address',
            key: 'address',
        }, {
            title: '注册资金',
            align: 'center',
            dataIndex: 'reg_capital',
            key: 'reg_capital',
            render: (text, record,index) =>{
                return  (
                    <span>{text} (万元)</span>
                )

            }
        },
    ];
    /** 供应商投标情况 */
    const revertCol = [
        {
            title: '供应商名称',
            dataIndex: 'store_name',
            key: 'store_name',
        },
        {
            title: '联系人',
            dataIndex: 'store_user_name',
            key: 'store_user_name',
        },
        {
            title: '联系方式',
            dataIndex: 'store_user_phone',
            key: 'store_user_phone',
        },
        {
            title: '投标时间',
            key: 'tender_time',
            dataIndex: 'tender_time',
            render: text => {if(text){
                return <span>{text}</span>
            }else{  return <span>未投标</span>}
            },
        },
        {
            title: '标书是否签收',
            key: 'send_status',
            dataIndex: 'send_status',
            render: text => {if(text ==='已签收'){
                return <span>已签收</span>
            }else{  return <span>未签收</span>}
            },
        },

        {
            title: '投标状态',
            key: 'bid_status',
            dataIndex: 'bid_status',
            render: text => {if(text === 1){
                return <span >已投标</span>
            }else{  return <span >未投标</span>}
            },

        },
        {
            title: '保证金是否缴付',
            key: 'bail_status',
            dataIndex: 'bail_status',
            render: (text,record) => {
                switch (text) {
                    case 0:
                        return <span>未缴纳</span>;
                    case 1:
                        return <span >待后台审核</span>;
                    case 2:
                        return <span >已缴纳</span>;
                    case 3:
                        return <span >后台审核未通过</span>;
                    case 4:
                        return <span >已退回</span>;
                    case 5:
                        return <span >已冻结</span>;
                    default :
                        return <span >无需缴纳</span>;

                }
            },

        },
    ];
    /** 中标通知 */
    const winCol = [
        {
            title: '供应商名称',
            dataIndex: 'store_name',
            key: 'store_name',
        },
        {
            title: '联系人',
            dataIndex: 'store_user_name',
            key: 'store_user_name',
        },
        {
            title: '联系方式',
            dataIndex: 'store_user_phone',
            key: 'store_user_phone',
        },
        {
            title: '投标时间',
            key: 'createtime',
            dataIndex: 'createtime',
        },
        {
            title: '中标情况',
            key: 'status',
            dataIndex: 'status',
        },
        {
            title: '中标通知',
            key: 'msg',
            dataIndex: 'msg',
            editable: true,
            render: (text,record) => {
                if (record.status !=='未中标') {
                    return <span style={{color: "green"}}>{text}</span>
                }else{  return <span style={{color: "red"}}>{text}</span>}
            },

        },
    ];

    return (
        <div className="container" >
            {
                !isLoading && <div className={css.apply}>
                    <div className={css.boxTitle}>
                        详情
                    </div>
                    {data.proinfo.ptype === '1' ?
                        <Descriptions column={2} className={'rows'} title="招标公告">
                            <Descriptions.Item label="公告标题" span={2}>{data.proinfo.title}</Descriptions.Item>
                            <Descriptions.Item label="项目名称">{data.proinfo.project_name}</Descriptions.Item>
                            <Descriptions.Item label="项目编号">NO:{data.proinfo.project_sn}</Descriptions.Item>
                            
                            <Descriptions.Item label="招标单位">{data.proinfo.agent_type==1?data.proinfo.company_name:data.proinfo.entrust_company_name}</Descriptions.Item>

                            <Descriptions.Item label="行业分类">{data.proinfo.category_trade} - {data.proinfo.category_name}</Descriptions.Item>

                            {data.proinfo.agent_type==2&&
                                <Fragment>
                                    <Descriptions.Item label="代理单位">{data.proinfo.company_name}</Descriptions.Item>
                                </Fragment>
                            }

                            <Descriptions.Item label="招标类型">{data.proinfo.tentype}</Descriptions.Item>
                            <Descriptions.Item label="招标方式">{data.proinfo.tendering_mode === MODE_OFF ?'仅线上发布招标公告':'在线电子招投标'}</Descriptions.Item>
                            {/* <Descriptions.Item label="招标联系人">{data.proinfo.user_name}</Descriptions.Item>
                            <Descriptions.Item
                                label="联系电话">{data.proinfo.user_phone && data.proinfo.user_phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')}</Descriptions.Item> */}
                            <Descriptions.Item
                                label="所属地区">{data.proinfo.address}</Descriptions.Item>
                            <Descriptions.Item label="项目金额">{data.proinfo.starting_price} (万元)</Descriptions.Item>
                            <Descriptions.Item
                                label="报名时间">{data.proinfo.signup_time} ~ {data.proinfo.signend_time}</Descriptions.Item>
                        </Descriptions>:
                        <Descriptions column={2} className={'rows'} title="采购公告">
                            <Descriptions.Item label="项目名称">{data.proinfo.project_name}</Descriptions.Item>
                            <Descriptions.Item label="项目编号">NO:{data.proinfo.project_sn}</Descriptions.Item>
                            <Descriptions.Item label="行业分类">{data.proinfo.category_trade} - {data.proinfo.category_name}</Descriptions.Item>
                            <Descriptions.Item label="采购产品">{data.proinfo.product_name} </Descriptions.Item>
                            <Descriptions.Item label="采购总量">{data.proinfo.total}{data.proinfo.unit_name}</Descriptions.Item>
                            <Descriptions.Item label="采购区域">{data.proinfo.address}</Descriptions.Item>
                            <Descriptions.Item label="采购单价">{data.proinfo.starting_price} (元)</Descriptions.Item>
                            <Descriptions.Item label="采购联系人">{data.proinfo.user_name}</Descriptions.Item>
                            <Descriptions.Item label="联系人电话">{data.proinfo.user_phone}</Descriptions.Item>
                        </Descriptions>
                    }
                    <div className={css.contentBox}>
                        <div className={css.contentTitle}>公告正文:</div>
                        <div className={css.content} dangerouslySetInnerHTML={{__html: data.proinfo.content}}/>
                    </div>
                    {
                        data.proinfo.file && <Form.Item label='附件' >
                            {data.proinfo.file.map((item)=>
                                <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                            )}
                        </Form.Item>
                    }
                    {
                        data.proinfo.images &&  <Form.Item label='项目图片' style={{marginTop: '20px'}}>
                            <UploadImage disabled={true} uploadNum={3} value={data.proinfo.images}/>
                        </Form.Item>
                    }
                    { data.proinfo.tendering_type === TYPE_INVITE &&
                    <Fragment>
                        <Descriptions>
                            <Descriptions.Item label="供应商报名列表"/>
                        </Descriptions>
                        <Table
                            bordered
                            pagination={false}
                            dataSource={data.projsign_invitation}
                            columns={invitecol}
                            style={{marginBottom: '20px'}}
                        />
                        <Descriptions>
                            <Descriptions.Item label="邀请通知">{data.proinfo.invitation_remark}</Descriptions.Item>
                        </Descriptions>
                    </Fragment>
                    }

                    {data.appro_hand &&
                        <Fragment>
                            <Divider dashed />
                            <Descriptions>
                                <Descriptions.Item label="开标人">{data.appro_hand.username}</Descriptions.Item>
                            </Descriptions>
                            <Divider dashed />
                        </Fragment>
                    }
                    {data.project_issued && <Fragment>
                        <Descriptions title={'招标文件'} className={'rows'} column={1}>
                            <Descriptions.Item label="招标文件标题">{data.project_issued.issued_name}</Descriptions.Item>
                            <Descriptions.Item label="投标时间">{data.project_issued.bidstart_time} - {data.project_issued.bidend_time}</Descriptions.Item>
                        </Descriptions>
                        <div className={css.contentBox}>
                            <div className={css.contentTitle}>招标文件正文:</div>
                            <div className={css.content} dangerouslySetInnerHTML={{__html: data.project_issued.content}}/>
                        </div>
                        {
                            data.project_issued.file && <Form.Item label='附件' >
                                {data.project_issued.file.map((item)=>
                                    <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                                )}
                            </Form.Item>
                        }
                    </Fragment>
                    }
                    {
                        data.backlist && <Fragment>
                            <Divider dashed />
                            <Descriptions title={'供应商投标情况'}/>
                            <Table columns={revertCol} pagination={false} dataSource={data.backlist.list} bordered />
                       </Fragment>
                    }
                    {
                        data.evaluation_list.pcount !== 0 && <Fragment>
                            <Divider dashed />
                            <Descriptions title={'评标分数列表'}>
                                <Descriptions.Item label="评标时间">{data.projsign_list.bidend_time}</Descriptions.Item>
                            </Descriptions>
                            {resultCol &&<Table columns={resultCol} pagination={false} dataSource={data.evaluation_list.arr} bordered />}
                        </Fragment>
                    }
                    {
                        data.win_list &&  <Fragment>
                            <Divider dashed />
                            <Descriptions title={'中标通知'}/>
                            <Table columns={winCol} pagination={false} dataSource={data.win_list} bordered />
                        </Fragment>
                    }

                    {
                        data.win_info && <Fragment>
                            <Divider dashed />
                            <Descriptions title={'中标公示'} className={'rows'} column={1}>
                                <Descriptions.Item label="中标单位">{data.win_info.store_name}</Descriptions.Item>
                                {/*<Descriptions.Item label="成交时间">{data.win_info.completetime}</Descriptions.Item>*/}
                            </Descriptions>
                            <div className={css.contentBox}>
                                <div className={css.contentTitle}>招标公示正文:</div>
                                <div className={css.content} dangerouslySetInnerHTML={{__html: data.win_info.content}}/>
                            </div>
                        </Fragment>
                    }

                    <div className={css.btns}>
                        <Button type="primary" size={"large"} style={{marginLeft: '20px'}} onClick={()=>{window.history.back()}}
                        >
                            返回
                        </Button>
                    </div>

                </div>

            }
        </div>
    );
}