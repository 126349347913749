import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import {Edit, Pay, Sign} from "./components";
import css from "./index.module.less";
import {Flow} from "../../components";

const { TabPane } = Tabs;

export const Tender = (props) => {
    const superStep = 2; //1:约标 2：发标 3：开标 4：评标 5：定标
    const [ step,setStep ] = useState(0);
    const [params,setParams] = useState({project_sn: props.location.state.project_sn ||''});

    useEffect(()=>{
        const {project_sn='',step=0} = props.location.state||{};
        console.log("project_sn=============",project_sn);
        setStep(step);
        setParams({project_sn});
    }, [props.location.state]);
    const superList = [
        {name: '报名', id: 2, step: 1},
        {name: '投标', id: 2, step: 2},
        {name: '开标、评标', id: 2, step: 3},
        {name: '中标结果', id: 2, step: 4},
    ];
    let stepList = [
        {
            stepId: 1,
            stepName: '签收招标文件',
            disabled: true
        },
        {
            stepId: 2,
            stepName: '缴纳保证金',
            disabled: true

        },
        {
            stepId: 3,
            stepName: '编写投标标书',
            disabled: true

        },

    ];

    return (
        <div className='container'>
            <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList} superList={superList} />
            <div className={css.tender}>
                <Tabs activeKey={ step.toString() }>
                    <TabPane tab="签收招标文件" key="1">
                        <Sign params={params}/>
                    </TabPane>
                    <TabPane tab="缴纳保证金" key="2">
                        <Pay params={params}/>
                    </TabPane>
                    <TabPane tab="编写投标标书" key="3">
                        <Edit params={params}/>
                    </TabPane>
                </Tabs>
            </div>
        </div>



    );
}