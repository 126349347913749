import React, {Fragment, useEffect, useState} from 'react';
import css from './tableTransfer.less';

import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';

import { Button, Checkbox, Col, Input, Row, Table } from "antd";
import {useDataApi} from "../hook";
import {AddressItem} from "./AddressItem";
import {YcPagination} from "./yc-pagination";

const { Search } = Input;

const leftColumns = [
    {
        title: '公司名称',
        dataIndex: 'store_name',
    },
    {
        title: '所属行业',
        dataIndex: 'business_type',
    },
    {
        dataIndex: 'address',
        title: '所在地',
    },
    {
        dataIndex: 'money',
        title: '注册资金',
        render: (text, record,index) =>{
            return  (
                <span>{text} (万元)</span>
            )

        }
    },
];
const rightColumns = [
    {
        dataIndex: 'store_name',
        title: '公司名称',
    },
];

export const TableTransfer = (props) => {
    const {selectedData,pushData,removeData } = props;
    const [leftLoading] = useState(false);
    const [rightPagination] = useState({pageSize:10});
    const [leftData,setLeftData] = useState([]);
    const [rightData,setRightData] = useState([]);
    const [leftKeys,setLeftKeys] = useState([]);
    const [dataSource,setData] = useState();
    const [{data},updateParams] = useDataApi({initialData:[]});
    const [params,setParams] =useState({page:1,business_type:[]});
    const [form] = Form.useForm();

    useEffect(()=>{
        updateParams({url:'/api/getsupplyStore',method:'post',data:{...params,...{business_type:params.business_type.join(','),}},initialData:[]})
    },[params]);
    useEffect(()=>{
       setData(data.list)
        let _params = params;
       _params.total = data.count;
       setParams(_params)
       // setData(data.map(item=>{return {ps: '备注：',...item}}))
    },[data]);

    const industryList = [
        { label: '工程', value: '工程' },
        { label: '贸易', value: '贸易' },
        { label: '服务', value: '服务' },
        { label: '林业', value: '林业' },
        { label: '其他', value: '其他' },
    ];
    const searchChange = (name,value)=>{
        setParams({...params,...{[name]:value}})
    };
    const leftChange = (e)=>{
        console.log("leftChange",e);

    };
    const rightChange = (e)=>{
        console.log("rightChange",e);
    }

    const leftSelection = {
        type: 'checkbox',
        selectedRowKeys: leftKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setLeftData(selectedRows)
            setLeftKeys(selectedRowKeys);
        },

    };
    const rightSelection = {
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
            // removeData(selectedRows)
            setRightData(selectedRows.filter(item=>item));
        },
    };
    const onAreaChange = e => {
        const [province_id,city_id,county_id] = e;
        setParams({...params,...{province_id,city_id,county_id}})
    };
    const handleSearch = e=>{
        console.log('e====',e)
        setParams({...params,...{store_name:e}});

    };

    return (
        <div className={css.tableTransfer}>
            <Form form={form}>
                <Form.Item name={'store_name'} wrapperCol={{span: 12}}>
                <Search
                    placeholder="供应商名称"
                    enterButton="搜索"
                    size="large"
                    onSearch={handleSearch}
                />
                </Form.Item>
                <Form.Item  label={'行业分类'} name={'business_type'} style={{margin:'10px 0'}}>
                    <Checkbox.Group options={industryList} defaultValue={['Apple']} onChange={(e)=>{searchChange('business_type',e)}} />
                </Form.Item>

                <Row>
                    <Form.Item  style={{width: '400px'}} labelCol={{span:4}} wrapperCol={{span:20}} labelAlign='left' label={'注册资金'}>
                        <Form.Item style={{display: 'inline-block',width: '130px','margin-bottom':'0'}} name={'moneymin'} >
                            <Input type={"number"} placeholder='最低值' suffix="万元" autoComplete="off"  onChange={(e)=>{searchChange('moneymin',e.target.value)}}/>
                        </Form.Item>
                        <span style={{textAlign: 'center', lineHeight: '25px', fontSize: '32px'}}>~</span>
                        <Form.Item style={{display: 'inline-block',width: '130px','margin-bottom':'0'}}  name={'moneymax'} >
                            <Input type={"number"} placeholder='最高值' suffix="万元" autoComplete="off" onChange={(e)=>{searchChange('moneymax',e.target.value)}}/>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item  style={{width: '400px'}} labelCol={{span:5}} wrapperCol={{span:19}} labelAlign='left' label={'公司所在地'}>
                        <AddressItem size="default" onChange={onAreaChange}/>
                    </Form.Item>
                </Row>
            </Form>
            {dataSource && <Fragment>
                <Row>
                    <Col className={ css.leftTable } span={15} style={{ background: '#f3f4f5', padding:'5px 5px 0'}}>
                        <h4>供应商列表 （{params.total}）项</h4>
                        <Table
                            columns={leftColumns}
                            dataSource={dataSource}
                            size="small"
                            rowSelection={leftSelection}
                            pagination={false}
                            loading={leftLoading}
                            onChange={ leftChange}
                        />
                        <YcPagination
                            total={ params.total || 0 }
                            pageSize={8}
                            current={ params.page }
                            onChange={(e)=> {const _params = params;_params.page=e; setParams({..._params})}}
                            style={ {margin:'30px 0'} } />
                    </Col>
                    <div className={ css.operationBtn } style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '0 15px',}}>
                        <Button type={"primary"} ghost  onClick={()=>{ pushData(leftData);setLeftKeys([]);}} >添加</Button><br /><br />
                        <Button onClick={()=>removeData(rightData)} >移除</Button>
                    </div>
                    <Col span={6} style={{ background: '#f3f4f5', padding:'5px 5px 0'}}>
                        <h4>已选择 （{selectedData.length}）项</h4>
                        <Table
                            selectedRowKeys={[66]}
                            rowSelection={rightSelection}
                            columns={rightColumns}
                            dataSource={selectedData}
                            size="small"
                            pagination={rightPagination}
                            onChange={ rightChange}
                        />
                    </Col>
                </Row>
                </Fragment>}
        </div>
    );
}