import React, {useEffect, useState} from 'react';
// import classNames from 'classnames';
import * as R from 'ramda';
import newsimg from 'aliasimgurl/img_news_demo.png';
import css from './news-list.module.less';

export const NewsList =(props)=>{

    const {items=[],goDetail} = props;



    const [after,setAfter] = useState([]);
    const [before,setBefore] = useState([]);
    useEffect(()=>{
        if(items.length){
            let [_before,_after] = R.splitAt(2,items);
            setAfter([..._after]);
            setBefore([..._before]);
        }

    },[items]);

    const onError = ()=>{
        let _r = before.map(opt=>({...opt,images:newsimg}));
        setBefore(_r);
    }

    /*let  _after = R.splitEvery(2,after);
    console.log(_after)*/

    const [ w,setWidth] = useState(196);
    const [ h,setHeight] = useState(140);

    const changeWh = (v)=>{
        if (v) {
            let _w = v.clientWidth;
            let h =_w*144/204;
            setWidth(parseInt(w,10));
            setHeight(parseInt(h,10));
        }

    };

    return (
        <div className={ css.list }>
            <div className={ css.before }>
                {
                    before.map((o,i)=>
                        <div ref={ _div=>changeWh(_div) } className={ css.item } key={ i } onClick={()=>goDetail(o)}>
                            <div style={ {display:'flex',alignItems:'center',height:h,justifyContent:'center',width:w} }>
                                <div>
                                    <img style={ {fontSize:0,display:'block',height:h,width:w} }
                                        onError={ (e)=>onError(e) } src={ o.images } alt=""/>
                                </div>
                            </div>
                            <div className={ css.title } >{ o.title }</div>
                        </div>
                    )
                }
            </div>
            <div className={ css.after }>
                {
                    after.map((opt,index)=>
                        <div  className={ css.item } key={ index } onClick={()=>goDetail(opt)}>
                            <div className={ css.title } onClick={()=>goDetail(opt)}>{ opt.title }</div>
                        </div>
                    )
                }
            </div>

        </div>
    );
}