import React, {useState} from 'react';
import Swiper from 'react-id-swiper';
import css from './notices-scroll.module.less';
import {useHistory} from "react-router-dom";

export const NoticesScroll =(props)=>{

    const {items=[],goDetail} = props;
    const [swiper, updateSwiper] = useState(null);
    const {push} = useHistory();
    const params = {
        slidesPerView: 4,
        spaceBetween: 30,
        loop: true,
        // centeredSlides: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        on:{
            click: function(e){
                console.log("=============",e);
                let id = e.target.dataset.id // jumpurl是在swiper-slide中动态绑定的data-jumpUrl属性，值是从后台获取的跳转链接
                console.log("id",id);
                setTimeout(()=>{
                    push({pathname: '/category/148/178/view/'+id});

                },10)
                // _this.bannerJump(url)
            },
        },

        getSwiper: updateSwiper
    }

    const handleMouseOver = ()=>{
        swiper.autoplay.stop()
    };
    const handleMouseOut = ()=>{
        swiper.autoplay.start()
    }
    return (
        <div className={ css.list } >
            {items.length &&  <Swiper { ...params } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
                {
                    items.map((opt,index)=>
                        <div className={ css.item } key={ index } >
                            <div className={ css.title } data-id={opt.id}>{ index+1 }. { opt.title }</div>
                        </div>
                    )
                }
            </Swiper>}

        </div>
    );
}