import React, {useState} from 'react';
import css from './bidhall-list.module.less';
import classNames from 'classnames';

export const BidhallList =(props)=>{
    const { data } = props;
    return (
        <div className={ css.list }>
            <div className={ classNames(css.item,css.tableTitle) } >
                <div className={ css.title }>项目名称</div>
                <div className={ css.icon }>发布时间</div>
                <div className={ css.area }>所属地区</div>
                <div className={ css.type }>行业分类</div>
                <div className={ css.time }>发布时间</div>
            </div>
            {
                ( data ).map((opt,index)=>
                    <div className={ css.item } key={ index }  onClick={ ()=>props.toView && props.toView(opt) } >
                        <div className={ css.title }>【{opt.infor_type}】{ opt.project_name }</div>
                       <div className={ css.icon }>
                           {opt.createtime}

                        </div>
                        <div className={ css.area }>{ opt.province_name }</div>
                        <div className={ css.type }>{ opt.industry_type }-{opt.industry_id}</div>
                        <div className={ css.time }>{ opt.createtime }</div>
                    </div>
                )
            }
        </div>
    );
}