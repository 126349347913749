import React from 'react';
import demoimg from 'aliasimgurl/logo_img.png';

export const YcImage = (props)=>{
    const { imgUrl ,onClick,style} = props;
    return (
        <div style={{...style}}>
            <img style={{ maxWidth:'100%' }} src={ imgUrl || demoimg } alt="" onClick={onClick}/>
        </div>
    );
}