import React, {useEffect, useState} from 'react';
import {Card, SubNav, UserInfo} from '../components';
import {YcPagination} from '../../../components';
import classNames from 'classnames';
import {Button} from 'antd';
import {useDataApi} from '../../../hook';
import css from './index.module.less';
import {useHistory} from "react-router-dom";

export const Deposit = () => {


    const [params, setParams] = useState({page: 1, pagesize: 20, type: '1', total: 0});
    const [{data: dataSource, isLoading/*, isError */}, updateParams] = useDataApi({initialData: {data: []}});
    const [{data: total,/*, isError */}] = useDataApi({
        url: '/api/user/bonds',
        method: 'post',
        initialData: {data: []}
    });
    const {push} = useHistory();

    useEffect(() => {
        if (!isLoading) {
            const _params = params;
            _params.total = dataSource.total;
            setParams(_params);
        }
    }, [isLoading]);

    useEffect(() => {
        const {page, pagesize} = params;
        updateParams({
            url: '/api/user/bondList',
            method: 'post',
            data: {page, pagesize}
        })
    }, [params]);

    const handleStatusText = (opt) => {
        const {bail_status} = opt;
        switch (bail_status) {
            case 0:
                return <span>未缴纳</span>;

            case 1:
                return <span>待审核</span>;

            case 2:
                return <span>已缴纳</span>;

            case 3:
                return <span>审核未通过</span>;

            case 4:
                return <span>已退回</span>;
            case 5:
                return <span>已冻结</span>;
            default:
                return <span/>

        }
    };


    const goDetail = (opt) => {
        const {project_sn} = opt;
        push({pathname: '/account/deposit/detail', state: {project_sn}})
    }

    return (
        <div className={css.body}>
            <Card title="保证金管理" cardStyle={{padding: '0 0 20px '}}>

                <div className={css.priceBox}>
                    <div className={css.item}>
                        <div className={css.num}>{total.bail_status1} {total.bail_status1 > 0 && '万'}</div>
                        <div className={css.text}>已缴纳保证金</div>
                    </div>
                    <div className={css.item}>
                        <div className={css.num}>{total.bail_status2} {total.bail_status2 > 0 && '万'}</div>
                        <div className={css.text}>未缴纳保证金</div>
                    </div>
                    <div className={css.item}>
                        <div className={css.num}>{total.bail_status3} {total.bail_status3 > 0 && '万'}</div>
                        <div className={css.text}>已退回保证金</div>
                    </div>
                    <div className={css.item}>
                        <div className={css.num}>{total.bail_status4} {total.bail_status4 > 0 && '万'}</div>
                        <div className={css.text}>冻结保证金</div>
                    </div>
                </div>

                <div className={classNames(css.top, css.header)}>
                    <div className={css.name}>项目名称</div>
                    <div className={css.time}>金额 (万元)</div>
                    <div className={css.time} style={{width: '180px'}}>缴纳时间</div>
                    <div className={css.status}>状态</div>
                    <div className={css.operation}>操作</div>
                </div>
                <div className={css.list}>
                    {!isLoading && dataSource.list.map(opt =>
                        <div className={css.row} key={opt.project_sn}>
                            <div className={classNames(css.top)}>
                                <div className={css.name}
                                     onClick={() => push({pathname: '/market/view/' + opt.project_sn})}>
                                    {opt.project_name || '未命名'}
                                </div>
                                <div className={css.time}>{opt.bond}</div>


                                <div className={css.time} style={{width: '180px'}}>{opt.bondtime || ''}</div>
                                <div className={css.status}>
                                    {handleStatusText(opt)}
                                </div>
                                <div className={css.operation}>
                                    <div className={css.btn} onClick={() => goDetail(opt)}>查看</div>

                                </div>
                            </div>
                        </div>
                    )}
                    <YcPagination
                        total={params.total || 0}
                        pageSize={params.pagesize}
                        current={params.page}
                        onChange={(e) => {
                            const _params = params;
                            _params.page = e;
                            setParams({..._params})
                        }}
                        style={{margin: '30px 0'}}/>
                </div>
            </Card>
        </div>
    )
};