import React from 'react';
import {Steps} from 'antd';

import css from './flow.module.less';

const {Step} = Steps;

export const Flow = (props) => {
    const { step, setStep,stepList} = props;

    const onChange = (i) => {
        setStep(i);
    }
    return (
        <div className={css.flow}>
            <div className={css.steps}>
                <Steps progressDot initial={1} current={step} onChange={onChange}>
                    {stepList.map((opt, index) =>
                        <Step title={opt.stepName} description="" key={'items'+index} disabled={ opt.disabled} className={opt.disabled? css.disabled :''} />
                    )}
                </Steps>
            </div>
        </div>
    );
}