import React from 'react';
import css from './type-and-regin.module.less';

export const TypeAndRegin =(props)=>{
    const {area:regin=[],goDetail}=props;
    const type=['华北','东北','西北','华东','华中','华南','西南'];
    return (
        <div className={ css.list }>
            <h4 className={ css.typesTitle } >热门地区</h4>
            <div className={ css.box } >
                {
                    regin.map((opt,index)=> <div style={{whiteSpace: "nowrap",marginBottom:'7px'}} key={index}>
                            <span className={css.types}>{type[index]}</span>
                            { opt.map((item)=>
                                <a key={ item.name } className={ css.title } onClick={()=>goDetail('area',item)}>{ item.name }</a>

                            )}

                        </div>
                    )
                }
            </div>
        </div>
    );
}