import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import {Answer, Audit, Auditor, Edit,ExpertRule,ExpertResult} from "./components";
import css from "./listSend.module.less";
import {Flow} from "../components";

const { TabPane } = Tabs;
/** 发标 */
export const ListSend = (props) => {
    const superStep = 2; //1:约标 2：发标 3：开标 4：评标 5：定标
    const [ step,setStep ] = useState(0);
    const [params,setParams] = useState({project_sn:props.location.state.project_sn||''});

    useEffect(()=>{
        const {project_sn='',step=0} = props.location.state||{};
        setStep(step);
        setParams({project_sn});
    }, [props.location.state]);

    const superList = [
        {name: '约标', id: 2, step: 1},
        {name: '发标', id: 2, step: 2},
        {name: '开标', id: 2, step: 3},
        {name: '评标', id: 2, step: 4},
        {name: '定标', id: 2, step: 5},
    ];

    let stepList = [
        {
            stepId: 1,
            stepName: '设置开标人',
            disabled: true
        },
        {
            stepId: 2,
            stepName: '编写招标内容',
            disabled: true

        },
        {
            stepId: 3,
            stepName: '生成招标文件',
            disabled: true

        },
        {
            stepId: 4,
            stepName: '查看投标情况',
            disabled: true
        },  {
            stepId: 5,
            stepName: '设置专家抽取规则',
            disabled: true
        },{
            stepId: 6,
            stepName: '专家抽取结果',
            disabled: true
        },
    ];

    return (
        <div className='container'>
            <Flow superStep={superStep} step={step} setStep={setStep} stepList={stepList} superList={superList} />
            <div className={css.listSend}>
                <Tabs activeKey={ step.toString() }>
                    <TabPane tab="设置开标人" key="1">
                        <Auditor params={params}/>
                    </TabPane>
                    <TabPane tab="编写招标文件" key="2">
                        <Edit params={params}/>
                    </TabPane>
                    <TabPane tab="招标人审批" key="3">
                        <Audit params={params }  />
                    </TabPane>
                    <TabPane tab="投标情况" key="4">
                        <Answer params={params} />
                    </TabPane>
                    <TabPane tab="设置专家抽取规则" key="5">
                        <ExpertRule params={params} />
                    </TabPane>
                    <TabPane tab="专家抽取结果" key="6">
                        <ExpertResult params={params} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
};