import React from 'react';
import {NavLink} from 'react-router-dom';
// import { useDataApi } from '../hook/dataApi';
// import * as R from 'ramda';
import css from './sub-nav.module.less';

export const SubNav = (props) => {

    const { title, subnav } = props;

    return (
            <div className={ css.navList }>
                {
                    subnav.map((opt,index)=>
                        <NavLink key={ index } to={ opt.path } className={ css.nav } activeClassName={ css.selected }>
                            <div>
                                <span>{ opt.label }</span>
                            </div>
                        </NavLink>
                    )
                }
            </div>
    );
}