import React, {useEffect, useState} from 'react';
import css from './edit.module.less';
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Descriptions, message } from "antd";
import BraftEditor from "braft-editor";
import {useHistory} from 'react-router-dom';
import {UploadFile} from "../../../../components";
import {PublishModal} from "../../../../common";
import {useDataApi} from "../../../../hook";
import {AUDIT_PUBLISH} from "../../../../common/static_number";


const layout = {
    labelCol: {span: 2},
    wrapperCol:{span: 24},
};

export const Edit = (props) => {
    const { params:{project_sn=''}} = props;
    const [form] = Form.useForm();
    const [params, setParams] = useState({});
    const [initialData, setInitialData] = useState({});
    const [{data, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});

    useEffect(() => {
        if (!isLoading) {
            let _data = data.prosign;
            const _param = params;
            const {bidstart_time='',bidend_time=''} = data.project_issued || {};
            _data.bidstart_time = bidstart_time;
            _data.bidend_time = bidend_time;
            const {fit_file=[],technology_file=[],business_file=[],qualification_file=[],offer_file=[]} = _data;
            _param.fit_file= fit_file || [];
            _param.technology_file= technology_file  || [];
            _param.business_file= business_file || [];
            _param.offer_file= offer_file || [];
            _param.qualification_file= qualification_file || [];
            _data.isLoading = true;
            setInitialData(_data);
            setParams(_param)
        }
    }, [isLoading]);


    let Loading = false;
    const {push} = useHistory();

    /** 提交事件 */
    const submit = ({bid_status = 0}) => {
        if (Loading) {
            message.warn('正在提交，不要心急哟~');
            return
        }
        form.validateFields().then((values) => {
            let _params = Object.assign( {...params},{...values});
            PublishModal.bidSend({
                prosign_id:initialData.id,
                bid_status,
                ..._params,
            }).then(({data: {data, code, msg}}) => {
                if (code === 200) {
                    if(bid_status === 0){
                        message.success('保存成功',5);
                    }else{
                        message.success('提交成功',5,push({pathname: '/account/bid/list'}));
                    }
                } else {
                    message.error(msg);
                }
            })
        });

    };

    return (
            <div className={css.edit}>
                <div className={css.boxTitle}>
                    编写投标标书
                </div>
                {
                    initialData.isLoading &&  <Form
                        form={form}
                        scrollToFirstError={true}
                        {...layout}
                        initialValues={initialData}
                    >
                        <Form.Item
                            label={'投标时间'}
                        >
                            <span>{initialData.bidstart_time}~{initialData.bidend_time}</span>
                        </Form.Item>
                        <Form.Item
                            label={'报价文件'}
                            name={'offer_file'}
                            rules={[{required:true,message:'请上传报价文件'}]}
                        >
                             <UploadFile uploadNum={5} size={500} defaultFileList={params.offer_file}/>
                        </Form.Item>
                        <Form.Item
                            label={'资质文件'}
                            name={'qualification_file'}
                            rules={[{required:true,message:'请上传资质文件'}]}
                        >
                            <UploadFile uploadNum={5} size={500} defaultFileList={params.qualification_file}/>
                        </Form.Item>
                        <Form.Item
                            label={'商务标书'}
                            name={'business_file'}
                            rules={[{required:true,message:'请上传商务标书'}]}
                        >
                            <UploadFile uploadNum={5} size={500} defaultFileList={params.business_file}/>
                        </Form.Item>
                        <Form.Item
                            label={'技术标书'}
                            name={'technology_file'}
                            rules={[{required:true,message:'请上传技术标书'}]}
                        >
                            <UploadFile uploadNum={5} size={500} defaultFileList={params.technology_file}/>
                        </Form.Item>
                        <Form.Item
                            label={'综合标书'}
                            name={'fit_file'}
                            rules={[{required:true,message:'请上传综合标书'}]}
                        >
                            <UploadFile uploadNum={5} size={500} defaultFileList={params.fit_file}/>
                        </Form.Item>
                        <div className={css.btns}>
                            {/*<Button className={css.saveBtn} size={"large"} style={{marginLeft: '20px'}} onClick={submit}*/}
                            {/*>*/}
                            {/*    保存*/}
                            {/*</Button>*/}
                            <Button type="primary" size={"large"} style={{marginLeft: '20px'}} onClick={()=>submit({bid_status:1})}
                            >
                                发送标书
                            </Button>
                        </div>
                    </Form>
                }


            </div>

    );
}