import {useState} from 'react';
import {useEffectOnce} from 'react-use';
import {ServicesModel} from '../common';

export const useNoticeNumData = ()=>{
    const [data,setData] = useState({});
    const [timer/*,setTimer*/]= useState(null);
    useEffectOnce(()=>{

        getData();
        return ()=>{
            timer&& clearInterval(timer);
        }
    });
    const getData = ()=>{
        ServicesModel.getNoticeNum().subscribe({
            next:({data})=>{
                setData(data);
                // let _t = setTimeout(()=>getData(),5000);
                // setTimer(_t);
            }
        });
    };
    return [data,setData,getData];
}