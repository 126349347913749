import React, {useEffect, useState} from "react";
import css from './confirm.module.less'
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Descriptions, Divider, message, Modal, Table } from "antd";
import BraftEditor from "braft-editor";
import 'braft-editor/dist/index.css'
import {useHistory} from "react-router-dom";
import {UploadFile} from "../../../../components";
import {useDataApi} from "../../../../hook";
import {AUDIT_CONFIRM, AUDIT_PUBLISH} from "../../../../common/static_number";
import {PublishModal} from "../../../../common";
import {Contacts} from "../contacts";


export const Confirm =(props) =>{
    const {params:{project_sn=""}} = props;

    const [checkIndex,setCheckIndex] = useState(0);
    const [columns, setColumns] = useState([]);
    const [defaultFileList] = useState([]); //默认的文件列表
    const [params, setParams] = useState({ content: '', file: []});
    const [initialData, setInitialData] = useState({});
    const [users, setUsers] = useState([]); //选中的审批人
    const [contactsVisible, showContactsVisible] = useState(false); //审批人弹窗控制

    const [{data, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});
    const [{data: supplierData}] = useDataApi({
        url: '/api/scalingList',
        method: 'post',
        data: {project_sn},
        initialData: []
    });// 获取定标列表
    useEffect(() => {
        if (!isLoading) {
            const _data = data;
            const _param = params;
            setParams(_param);
            _data.isLoading = true;
            setInitialData(_data);
        }
    }, [isLoading]);
    let { push } = useHistory();
    useEffect(()=>{
        setColumns([
            {
                title: '评分排名',
                align: 'center',
                render:(text,row,index)=>{
                    return <span>{index + 1}</span>
                }
            },
            {
                title: '供应商名称',
                dataIndex: 'store_name',
                key: 'store_name',
                align: 'center',
            },
            {
                title: '平均得分',
                dataIndex: 'fraction',
                key: 'fraction',
                align: 'center'
            },
            {
                title: '中标人',
                key: 'tags',
                dataIndex: 'tags',
                align: 'center',
                width: '200px',
                className : css.row,
                render:(text,row,index)=>{
                    if(row.status_type === '22'){
                        return <Button  type={"primary"} ghost disabled>中标人</Button>
                    }else if( checkIndex === index ){
                        return <Button className={css.yellowBtn}>中标人</Button>
                    }else{
                        // return  <Button type={"primary"} ghost onClick={e=>{checkChange(index)}}>设为中标人</Button>
                    }
                }
            },
        ])
    },[checkIndex]);

    const toResult = ()=>{
        push({pathname:'/publish/listCommend',state:{project_sn,step:'3'}});
    }

    const excludeControls = ['media', 'emoji'];

    /** 文件上传成功事件 */
    const handleFileChange = (val) => {
        let _params = params;
        _params.file = val;
        setParams(_params);
    };

    const submit = (values )=>{
        const {remark_file}= values;
        const signdata =   supplierData.map((item,index)=>{
            if(!index){
                return {project_sign_id:item.id,successful_bid:1}
            }
            return {project_sign_id:item.id,successful_bid:0}
        });
        PublishModal.scaling({is_approval:1,remark:encodeURI(values.remark.toHTML()),project_sn,remark_file,signdata}).then(({data:{msg,code}})=>{
            if (code === 200) {
                message.success('提交成功！',3,    push({pathname:'/publish/listConfirm',state:{step:3,project_sn}}));
            } else {
            message.error(msg);
        }
        })
    };
    /** 审批人弹窗选择完成 */
    const handleContactsOk = e => {
        if (users.length === 0) {
            message.error('您还没有选择审批人呦~');
            return
        } else if (users.length !== 1) {
            message.error('审批人只能设置一个呦~');
            return
        }
        showContactsVisible(false);
        submit();
    };
    /** 人员选择回调函数 */
    const contactsSuccess = e => {
        setUsers(e.map(item => {
            return {username: item.title, user_id: item.id}
        }));
    };
    /** 关闭审批人弹窗 */
    const handleCancel = e => {
        showContactsVisible(false);
    };
    return (
        <div>
            {!initialData.isLoading ||
            <div className={css.confirm}>
                <div className={ css.boxTitle }>
                    确定中标人
                    <div className={css.info}>
                        <span >项目名称：</span>
                        <span className={css.high}>{data.project_name}</span>
                        <span >项目编号：</span>
                        <span className={css.high}>{data.project_sn}</span>
                    </div>
                </div>

                <Descriptions >
                    <Descriptions.Item label="确定中标人"/>
                    <Descriptions.Item label="" style={{textAlign: 'right'}}>
                        <Button className={css.yellowBtn} onClick={toResult}>查看评标结果</Button>
                    </Descriptions.Item>
                </Descriptions>
                <Table columns={columns} pagination={false} dataSource={supplierData} bordered align={'center'}/>

                <Divider dashed />

                <Form onFinish={submit}>
                    <Form.Item label="撰写定标报告"  required   name={'remark'}
                                       rules={[({getFieldValue})=>({
                                           validator(rule,value){
                                               if(!value || value.toHTML() === '<p></p>'){
                                                   return Promise.reject('请填写定标报告');
                                               }
                                               return Promise.resolve();
                                           }
                                       })]}>
                        <BraftEditor
                            excludeControls={excludeControls}
                            className={css.editor}
                            contentStyle={{height:'200px'}}
                        />
                    </Form.Item>
                    <Form.Item label="附件"  name={'remark_file'} rules={[{required:true,message:'请上传附件'}]}>
                        <UploadFile accept={' .PDF, .PPT, .PPTX, .DOCX, .DOC'} uploadNum={3}
                                    handleFileChange={handleFileChange} defaultFileList={defaultFileList}/>
                    </Form.Item>
                    <div className={css.btns}>
                        <Button type="primary" size={"large"} htmlType="submit">
                           发送通知
                        </Button>
                    </div>
                </Form>
            </div>
            }
            <Modal
                title="选择审批人"
                visible={contactsVisible}
                onOk={handleContactsOk}
                onCancel={handleCancel}
            >
                <Contacts success={contactsSuccess} users={users}/>
            </Modal>
        </div>

    )
}
