import React, {useEffect, useState} from "react";
import css from "./answer.module.less";
import {Button, Descriptions, Statistic, Table} from 'antd';

import {TagAudit} from "../../../../components";
import {useHistory} from "react-router-dom";
import {useDataApi} from "../../../../hook";
import {AUDIT_PUBLISH, AWAIT, OVER} from "../../../../common/static_number";

const { Countdown } = Statistic;
/**
 *  抽取专家规则
 * */
export const ExpertResult = (props) => {
    const {params:{project_sn=''} } = props;
    const [dataSource, setDataSource] = useState([]); //供应商列表
    const [initialData, setInitialData] = useState({});
    const [disabled,setDisabled] = useState(false);
    const [{data, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});
    let { push } = useHistory();
    const [{data:downTime}] = useDataApi({url: '/api/downtime', method: 'post',data:{project_sn},initialData: {'signtimeres':0}});
    const [{data: supplierData}] = useDataApi({
        url: '/api/signList',
        method: 'post',
        data: {project_sn, sign_status: '4'},//1=开标结果列表 2=报名列表 3=报名结果 4= 投标信息列表2
        initialData: []
    });// 获取公告中已选的供应商
    useEffect(() => {
        if(supplierData.list){
            setDataSource(supplierData.list);
        }
    }, [supplierData]);
    useEffect(() => {
        if (!isLoading) {
            let _data = data.project_issued;
            _data.isLoading = true;
            _data.time = Date.now();
            setInitialData(_data);
        }
    }, [isLoading]);

    const columns = [
        {
            title: '供应商名称',
            dataIndex: 'store_name',
            key: 'store_name',
        },
        {
            title: '联系人',
            dataIndex: 'store_user_name',
            key: 'store_user_name',
        },
        {
            title: '联系方式',
            dataIndex: 'store_user_phone',
            key: 'store_user_phone',
        },
        {
            title: '投标时间',
            key: 'tender_time',
            dataIndex: 'tender_time',
            render: text => {if(text){
                return <span>{text}</span>
            }else{  return <span>未投标</span>}
            },
        },
        {
            title: '标书是否签收',
            key: 'send_status',
            dataIndex: 'send_status',
            render: text => {if(text ==='已签收'){
                return <span>已签收</span>
            }else{  return <span>未签收</span>}
            },
        },

        {
            title: '投标状态',
            key: 'bid_status',
            dataIndex: 'bid_status',
            render: text => {if(text === 1){
                return <span >已投标</span>
            }else{  return <span >未投标</span>}
            },

        },
        {
            title: '保证金是否缴付',
            key: 'bail_status',
            dataIndex: 'bail_status',
            render: (text,record) => {
                switch (text) {
                    case 0:
                        return <span>未缴纳</span>;
                    case 1:
                        return <span >待后台审核</span>;
                    case 2:
                        return <span >已缴纳</span>;
                    case 3:
                        return <span >后台审核未通过</span>;
                    case 4:
                        return <span >已退回</span>;
                    case 5:
                        return <span >已冻结</span>;
                    default :
                        return <span >无需缴纳</span>;

                }
            },

        },
    ];

    const submit = ()=>{
        console.log("submit",submit);
        push({pathname: '/publish/listOpen',state:{project_sn:initialData.project_sn,step:1}});
    };
    const toCommune = ()=>{
        push({pathname:'/publish/commune',state:{project_sn}});
    };
    const onFinish = ()=>{
        setDisabled(true);
    };

    return (
        <div className={css.answer}>
            <div className={css.boxTitle}>
                投标情况 <TagAudit data={{'1':{title:'投标中',index:AWAIT},'0':{title:'已结束',index: OVER}}[data.status]}/>
                <div className={css.info}>
                    <span >项目名称：</span>
                    <span className={css.high}>{data.project_name}</span>
                    <span >项目编号：</span>
                    <span className={css.high}>{data.project_sn}</span>
                </div>
            </div>

            <Descriptions column={1}>
                <Descriptions.Item label="投标时间">
                    {initialData.bidstart_time} ~ {initialData.bidend_time}
                </Descriptions.Item>
                <Descriptions.Item label="距投标截止">
                    <Countdown value={Date.now() + downTime.bidtimeres*1000} format="D 天 H 时 m 分 s 秒"   onFinish={onFinish}/>
                    {/*<Countdown value={Date.now() + downTime} format="D 天 H 时 m 分 s 秒"   onFinish={onFinish}/>*/}
                </Descriptions.Item>
                {/*<Descriptions.Item label="" style={{textAlign: 'right'}}>*/}
                {/*    /!*<Button type={"primary"} style={{marginRight:'15px'}} onClick={()=>push({pathname:'/alteration',state: {project_sn,step:1}})}>变更招标文件</Button>*!/*/}
                {/*    /!*<Button type={"primary"}>变更记录</Button>*!/*/}
                {/*</Descriptions.Item>*/}
            </Descriptions>
            <Descriptions >
                <Descriptions.Item label="供应商投标情况">({dataSource.filter((item)=>{return item.bid_status === 1}).length}家已投标)</Descriptions.Item>
                <Descriptions.Item label="" style={{textAlign: 'right'}}>
                    <Button type={"primary"} onClick={toCommune}>澄清</Button>
                </Descriptions.Item>
            </Descriptions>
            <Table columns={columns} pagination={false} dataSource={dataSource} bordered />
            <div className={css.btns}>
                <Button type="primary" size={"large"} onClick={ submit } disabled={false && (initialData.down_time === 0 || !disabled)}>
                    进入开标大厅
                </Button>
            </div>

        </div>);
}