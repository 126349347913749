import React, {Fragment, useEffect, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import {useEffectOnce, useToggle} from 'react-use';
import {Breadcrumb, CardBox, UploadFile, UploadImage, YcImage} from '../../components';
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Descriptions, Input, message, Modal, Statistic } from 'antd';
import {useDataApi, useIsLogin} from '../../hook';
import headerImg from 'aliasimgurl/bidding_header.png';
import css from './index.module.less';
import {PublishModal} from "../../common";
import {tap} from "rxjs/operators";
import {resourcesUrl} from "../../common/static_number";
import {ProjectList} from "../../components/project-list";
import classNames from "classnames";

const { Countdown } = Statistic;

export const BiddingView = (props) => {
    const {project_sn=""} = props.match.params ? props.match.params : {};
    const [isLogin] = useIsLogin();
    const [form] = Form.useForm();
    const [visible, toggle] = useToggle(false);
    const [{data, setData,isLoading},updateParams] = useDataApi();
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage] = useState(''); //默认的图片列表
    const [nowtime] = useState(Date.now());
    const {push} = useHistory();

    useEffectOnce(() => {
        function scrollToTop(argument) {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 8);
            };
        };
        scrollToTop();
    });
    useEffect(()=>{
        updateParams({url: '/api/projectDetail', method: 'post', data: {project_sn,type:1}})
    },[project_sn])

    const onSubmit = data => {
        PublishModal.projectOffer({
            project_sn,
            is_change:'0',
            ...data,
        }).then(({data: {data, code, msg}}) => {
            if (code === 200) {
                message.success('您的报价已经提交成功！', 1,window.location.reload());
                toggle(false);
            } else {
                message.error(msg);
            }
        })
    };

    /** 收藏 */
    const handleCollect = ()=>{
        PublishModal.collect({id:data.projectinfo.id,type:2}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('收藏成功',3);
                const _data = data;
                _data.projectinfo.is_collect = true;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };
    /** 取消收藏 */
    const handleCancelCollect = ()=>{
        PublishModal.cancelCollect({id:data.projectinfo.id,type:2}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                message.success('取消成功',3);
                const _data = data;
                _data.projectinfo.is_collect = false;
                setData({..._data})
            },
            error:err=>{},
            complete:()=>{}
        });
    };

    const onFinish = ()=>{
        const _data = data;
        _data.projectinfo.sign_status = 4;
        setData({..._data})
    }
    const handleCancel = () => setPreviewVisible(false);
    const openOffer = ()=>{
        if(isLogin){
            toggle(true);
        }else{
            Modal.warning({
                title:'温馨提示',
                content: '您还未登录，是否现在登录？',
                okText:'去登录',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    window.location.href = '/login'
                },
                onCancel:()=>{

                }
            });

        }
    }
    return (
        <div className="container">
            <YcImage imgUrl={ headerImg } />
            {
                !isLoading &&  <Fragment>
                <Breadcrumb list={ [{name:'采购信息',path: '/bidding'},{name:data.projectinfo.project_name}] } />
                <div className={ css.innerMain }>
                    <div className={ css.listWrap }>
                        <div className={ css.viewWrap }>
                            <div className={ css.titleInfo }>
                                <h2 className={ css.viewTitle }>{data.projectinfo.project_name}</h2>
                                <p>
                                    <i className="iconfont iconweizhi" /><span>{data.projectinfo.address}</span>
                                    <i className="iconfont iconrili" /><span> {data.projectinfo.createtime}</span>
                                    {
                                        data.projectinfo.is_collect?  <Fragment><i className={classNames(css.check,"iconfont iconshoucangon ")}/><span onClick={handleCancelCollect} style={{cursor: "pointer"}}  className={css.check}>已收藏</span></Fragment>: <Fragment> <i className="iconfont iconshoucangon"/><span onClick={handleCollect} style={{cursor: "pointer"}}>收藏</span></Fragment>
                                    }
                                   {/* <i className="iconfont iconfenxiang"></i>
                                    <span>分享</span>
                                    <i className="iconfont icondayin"></i>
                                    <span>打印</span>*/}
                                </p>
                            </div>
                            <div className={ css.extInfo }>
                                <table>
                                    <tbody>
                                        <th>项目编号</th>
                                        <td>{data.projectinfo.project_sn}</td>
                                        <th>项目名称</th>
                                        <td >{data.projectinfo.project_name}</td>
                                        <th>采购物品</th>
                                        <td>{data.projectinfo.product_name}</td>
                                        <th> 所属行业</th>
                                        <td>{data.projectinfo.industry_type}-{data.projectinfo.industry_id}</td>

                                        <th>采购人/单位</th>
                                        <td>{data.projectinfo.company_name}</td>
                                        <th>所属地区</th>
                                        <td>{data.projectinfo.address}</td>


                                        <th>采购总量</th>
                                        <td>{data.projectinfo.total}{data.projectinfo.unit_name}</td>
                                        <th>采购单价</th>
                                        <td>
                                            <span>{data.projectinfo.starting_price} (元)</span>
                                        </td>
                                        <th>采购开始时间</th>
                                        <td>
                                            {data.projectinfo.signup_time}
                                        </td>
                                        <th>采购结束时间</th>
                                       <td>{data.projectinfo.signend_time}</td>
                                        <th>联系人</th>
                                        <td>
                                            {data.projectinfo.user_name}
                                        </td>
                                        <th>联系电话</th>
                                        <td>
                                            {
                                                isLogin?
                                                    <span >{data.projectinfo.user_phone}</span>:
                                                    <span>
                                                    <NavLink to="/login">登录</NavLink>
                                                    后可查看
                                                </span>
                                            }
                                        </td>

                                    {/*<tr>*/}
                                    {/*    <th>联系方式</th>*/}
                                    {/*    <td>*/}
                                    {/*        {*/}
                                    {/*            isLogin?*/}
                                    {/*                <a onClick={ info } >点击查看</a>:*/}
                                    {/*                <span>*/}
                                    {/*                <NavLink to="/login">登录</NavLink>*/}
                                    {/*                后可查看*/}
                                    {/*            </span>*/}
                                    {/*        }*/}
                                    {/*    </td>*/}
                                    {/*    <th></th>*/}
                                    {/*    <td></td>*/}

                                    {/*</tr>*/}

                                    </tbody>
                                </table>
                            </div>
                            <div className={ css.viewCon } dangerouslySetInnerHTML={{__html:data.projectinfo.content}} />
                            <Descriptions style={{marginTop: '20px'}} column={1}>
                                {data.projectinfo.file.length >0 &&  <Descriptions.Item label='附件'>
                                    {  data.projectinfo.file.map((item)=>
                                        <a href={ resourcesUrl + item.file} style={{display: 'block'}} target={'_blank'}>{item.name}</a>
                                    )}

                                </Descriptions.Item>}
                                {data.projectinfo.images.length>0 &&

                                <Descriptions.Item label='图片'>
                                    <UploadImage disabled={true} uploadNum={3} value={data.projectinfo.images || []}/>
                                    <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                                        <img alt="example" style={{width: '100%'}} src={previewImage}/>
                                    </Modal>
                                </Descriptions.Item> }
                            </Descriptions>

                        </div>
                    </div>
                    <div className={ css.sideBar }>
                        <div className={ css.countdown }>
                            { data.day > 0 && data.projectinfo.sign_status === 2 && <Fragment>
                                <div className={  css.title}>距离报名结束还剩</div>
                                <Countdown value={nowtime + data.day*1000} format="D 天 H 时 m 分 s 秒"   onFinish={onFinish}/>
                                {/*<div className={ css.count }>{Math.floor(/24/60/60) }天</div>*/}
                            </Fragment>}

                            <div className={ css.btnWrap }>
                                {
                                    data.projectinfo.sign_status === 1 &&  <Button type="primary" disabled>采购未开始</Button>
                                }
                                {
                                    data.projectinfo.sign_status === 2 && <Button type="primary" onClick={openOffer}>采购报名</Button>
                                }
                                {
                                    data.projectinfo.sign_status === 3 && <Button type="primary" style={{cursor: "not-allowed"}}>已报价</Button>
                                }
                                {
                                    data.projectinfo.sign_status === 4 &&  <Button disabled >采购已结束</Button>
                                }
                            </div>
                        </div>
                        <CardBox title="其它采购信息" more={ ()=>push('/bidding')}>
                            <ProjectList  items={data.other} goDetail={(opt)=>{console.log(opt); push({pathname:'/bidding/view/'+opt.project_sn})}}/>
                        </CardBox>
                    </div>
                </div>
                </Fragment>
            }
            <Modal
                title="报价通知"
                visible={ visible }
                onOk={ toggle }
                onCancel={ toggle }
                footer={ null }
                centered destroyOnClose mask maskClosable={ false }>
                <Form onFinish={onSubmit} wrapperCol={{span:20}} labelCol={{span:4}} className={ css.formWrap } form={form}>
                    <Form.Item label={'报价金额'} name={'money'} rules={[{required: true, message: '请输入报价金额'}]}>
                        <Input style={{width:'100%'}} autoComplete="off"  placeholder="请输入报价金额"/>
                    </Form.Item>
                    <Form.Item label={'联系人'} name={'store_user_name'} rules={[{required: true, message: '请输入联系人姓名'}]}>
                        <Input style={{width:'100%'}} autoComplete="off"  placeholder="请输入联系人姓名"/>
                    </Form.Item>
                    <Form.Item label={'联系电话'} name={'store_user_phone'}  rules={[{required: true, message: '请输入联系电话'}]}>
                        <Input style={{width:'100%'}} autoComplete="off"  placeholder="请输入联系电话"/>
                    </Form.Item>

                    <Form.Item label={'备注'} name={'remark'} >
                        <Input.TextArea style={{width:'100%'}} autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        name={'file'}
                        wrapperCol={{offset: 4, span: 16}}
                    >
                        <UploadFile accept={' .PDF, .PPT, .PPTX, .DOCX, .DOC'} uploadNum={3} defaultFileList={[]}/>
                    </Form.Item>
                    <div className={ css.btnWrap }>
                        <div className={ css.btnInner }>
                            <Button type="primary" htmlType="submit">提交</Button>
                            <Button onClick={()=>form.resetFields()}>重置</Button>
                        </div>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}