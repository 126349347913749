import cookie from 'react-cookies';
import {useEffect, useState} from 'react';

export const useIsLogin = ()=>{

    const [isLogin,setIsLogin] = useState(false);
    useEffect(()=>{
        let _isLogin = cookie.load('token') ||'';
        setIsLogin(!!_isLogin);
    },[isLogin]);

    return [isLogin,setIsLogin];
}