import css from "./commendScore.module.less";
import {Button, Descriptions, Table} from "antd";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDataApi} from "../../../../hook";
import {AUDIT_PUBLISH} from "../../../../common/static_number";

export const CommentScore = (props)=>{
    const {params:{project_sn=''} } = props;
    let { push } = useHistory();
    const [dataSource, setDataSource] = useState([]); //供应商列表
    const [{data, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});

    const [{data: supplierData}] = useDataApi({
        url: '/api/tenderResult',
        method: 'post',
        data: {project_sn},
        initialData: []
    });// 获取公告中已选的供应商
    useEffect(() => {
        if(supplierData.list){
            setDataSource(supplierData.list);
        }
    }, [supplierData]);
    useEffect(() => {
        if (!isLoading) {
            let _data = data.project_issued;
            _data.isLoading = true;
            _data.time = Date.now();
            // setInitialData(_data);
        }
    }, [isLoading]);

    const columns = [
        {
            title: '供应商名称',
            dataIndex: 'store_name',
            key: 'store_name',
        },
        {
            title: '联系人',
            dataIndex: 'store_user_name',
            key: 'store_user_name',
        },
        {
            title: '联系方式',
            dataIndex: 'store_user_phone',
            key: 'store_user_phone',
        },
        {
            title: '投标时间',
            key: 'tender_time',
            dataIndex: 'tender_time',
        },
        {
            title: '标书是否签收',
            key: 'send_status',
            dataIndex: 'send_status',
            render: text => {if(text ==='已签收'){
                return <a>已签收</a>
            }else{  return <a >未签收</a>}
            },
        },
        {
            title: '保证金是否缴付',
            key: 'bail_status',
            dataIndex: 'bail_status',
            render: (text,record) => {
                switch (text) {
                    case 0:
                        return <span>未缴纳</span>;
                    case 1:
                        return <span >待后台审核</span>;
                    case 2:
                        return <span >已缴纳</span>;
                    case 3:
                        return <span >后台审核未通过</span>;
                    case 4:
                        return <span >已退回</span>;
                    case 5:
                        return <span >已冻结</span>;
                    default :
                        return <span >无需缴纳</span>;
                }
            },

        },
        {
            title: '投标状态',
            key: 'bid_status',
            dataIndex: 'bid_status',
            render: text => {
                return <a>{text}</a>
            },

        },
        /*  {
              title: '附件上传状态',
              key: 'action',
              dataIndex: 'name',
              render: (text,recode) => {
                  if (recode.business_file + recode.technology_file + recode.fit_file ==='') {
                  console.log('==================',text);
                  return <span style={{color: '#333'}}>未上传</span>

                  }else{
                      return <span style={{color: '#0f8605'}} >已上传</span>
                  }
              },

          },*/
    ];


    const submit = ()=>{
        push({pathname:'/publish/listCommend',state:{project_sn,step:3}})
    }
    return (
        <div className={css.commendScore}>
            <div className={ css.boxTitle }>
                评标人评分
                <div className={css.info}>
                    <span >项目名称：</span>
                    <span className={css.high}>{data.project_name}</span>
                    <span >项目编号：</span>
                    <span className={css.high}>{data.project_sn}</span>
                </div>
            </div>
            <Descriptions column={1}>
                <Descriptions.Item label="评标时间" >{supplierData.evaluationstart_time} ~ {supplierData.evaluationend_time}</Descriptions.Item>
                <Descriptions.Item label="评标情况" >
                    本项目共邀请了&nbsp;
                    <span style={{color: '#0E7506'}}>{supplierData.pcount}</span>&nbsp;位评标人，&nbsp;
                    <span style={{color: '#0E7506'}}>{supplierData.ocount}</span>
                    &nbsp;人已完成评标，&nbsp;
                    <span style={{color: '#0E7506'}}>{supplierData.wcount}</span>
                    &nbsp;人正在评标
                </Descriptions.Item>
            </Descriptions>
            <Descriptions >
                <Descriptions.Item label="供应商投标情况" />
            </Descriptions>
            <Table columns={columns} pagination={false} dataSource={dataSource} bordered />
            <div className={css.btns}>
                <Button type="primary" size={"large"} onClick={ submit} >
                    下一步
                </Button>
            </div>
        </div>
    )
}