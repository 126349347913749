import React, {useEffect, useState} from 'react'
import {Input, Tree} from 'antd';
import css from './contacts.module.less'
import {useDataApi} from "../../../hook/dataApi";

const { Search } = Input;


export const Contacts = (props)=>{
    const { success  ,users, } = props;
    const[ searchValue,setSearchValue] = useState('');
    const[ expandedKeys,setExpandedKeys] = useState([]);
    const[ autoExpandParent,setAutoExpandParent] = useState(true);

    const [{data ,isLoading}] = useDataApi({ url: '/api/getStorepeople', method: 'post', initialData: []});
    const [userList,setUserList] = useState([]);
    const [empl,setEmpl]= useState([]);
    useEffect(()=>{
        if(!isLoading){
            setUserList(data);
            let _empl = [];
            dataEach(data);
            setEmpl(_empl);

            function dataEach(list){
                list.map(item => {
                    if(!item.children){
                        _empl.push(item)
                    }else{
                        dataEach(item.children);
                    }
                    return item
                })
            }
        }
    },[isLoading]);

    const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some(item => item.key === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    const onExpand = expandedKeys => {
        setExpandedKeys(expandedKeys);
        setAutoExpandParent(false);
    };
    const dataList = [];
    const generateList = data => {
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            const { key,title } = node;
            dataList.push({ key, title });
            if (node.children) {
                generateList(node.children);
            }
        }
    };
    generateList(userList);
    const onChange = e => {
        const { value } = e.target;
        const keys = dataList.map(item => {
                if (item.title.indexOf(value) > -1) {
                    return getParentKey(item.key, userList);
                }
                return null;
            }).filter((item, i, self) =>{return item && self.indexOf(item) === i} );
        setExpandedKeys(keys);
        setSearchValue(value);
        setAutoExpandParent(autoExpandParent);
    };

/*    useEffect(()=>{
       console.log("expandedKeys",expandedKeys);
       console.log("expandedKeys",val);
    },[val]);*/

    const onCheck = (keys) =>{
        success(empl.filter(item => {
            return !!keys.includes(item.key);
        }).filter(item=>{
            return !item.children;
        }));
    };
    const loop = data => data.map(item => {
            const index = item.title.indexOf(searchValue);
            const beforeStr = item.title.substr(0, index);
            const afterStr = item.title.substr(index + searchValue.length);
            const title =
                index > -1 ? (
                    <span>
            {beforeStr}
                        <span className="site-tree-search-value">{searchValue}</span>
                        {afterStr}
            </span>
                ) : (
                    <span>{item.title}</span>
                );
            if (item.children) {
                return { title, key: item.key, children: loop(item.children) };
            }

            return {
                title,
                key: item.key,
            };
        });

    return (
        <div className={css.contacts}>
            <Search style={{ marginBottom: 8 }} placeholder="搜索" onChange={onChange} />
            <Tree
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            treeData={loop(userList)}
            checkable={true}
            // checkStrictly={ true}
            onCheck={onCheck}
            />
            <div className={css.checkList}>
                {
                    users.map(( item,index )=>
                        <span key={ index } >{ item.username }</span>
                    )
                }
            </div>
        </div>
    )
}