import React, {useContext, useEffect, useRef, useState} from "react";
import css from "./apply.module.less";
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Descriptions, Input, message, Modal, Table } from 'antd';
import 'braft-editor/dist/index.css'
import {TagAudit} from "../../../../components";
import {useDataApi} from "../../../../hook";
import {useHistory} from "react-router-dom";
import {AUDIT_SUPPLIER, AWAIT, FAIL, SUCCESS} from "../../../../common/static_number";
import {PublishModal} from "../../../../common";
import {tap} from "rxjs/operators";

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 10},
};
const EditableContext = React.createContext();

const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async e => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

/** 发送报名通知 */
export const MakeResult = (props) => {
    const {form,params:{project_sn=''} } = props;
    const [dataSource, setDataSource] = useState([]); //邀请中的供应商列表
    const [{data,setData, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_SUPPLIER,project_sn}});
    const {push} = useHistory();

    const col = [
        {
            title: '供应商名称',
            dataIndex: 'store_name',
            key: 'store_name',
        },
        {
            title: '联系电话',
            dataIndex: 'project_user_phone',
            key: 'project_user_phone',
        },
        {
            title: '入围情况',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '详情',
            align: 'center',
            render: (text, record,index) =>{
                if(record.status_type !=='0' && record.status_type !=='1'){
                    return  (
                        <a onClick={()=>push({pathname:'/applyDetail',state:{project_sign_id:record.id,readonly:true}})}>查看</a>
                    )
                }

            }
        },

    ];

    const [{data: supplierData,isLoading:supplierLoading}] = useDataApi({
        url: '/api/signList',
        method: 'post',
        data: {project_sn, sign_status: '3'},//1=开标结果列表 2=报名列表 3=报名结果 4= 投标信息列表2
        initialData: []
    });// 获取公告中已选的供应商

    const [columns, setColumns] = useState([]);
    const handleSave = row => {
        const newData = [...dataSource];
        const index = newData.findIndex(item => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {...item, ...row});
        setDataSource([...newData]);
    };

    useEffect(() => {
        if(!supplierLoading){
            setDataSource(supplierData.list);
        }
    }, [supplierLoading]);


    useEffect(() => {
        let _columns = col.map(opt => {
            if (!opt.editable) {
                return opt;
            }
            return {
                ...opt,
                onCell: record => ({
                    record,
                    editable: opt.editable,
                    dataIndex: opt.dataIndex,
                    title: opt.title,
                    handleSave: handleSave,
                }),
            };
        });
        setColumns(_columns);
    }, [dataSource]);

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };


    const submit = ()=>{
        const signdata =   dataSource.map((item)=>{
            return {project_sign_id:item.id,appr_content: item.status_text||''}
        });
        PublishModal.sendInform({signdata,project_sn}).then(({data:{code,msg}})=>{
                if (code === 200) {
                    message.success('发送成功',5 );
                    let _data = data;
                    _data.status = 8;
                    setData({..._data});
                } else {
                    message.error(msg);
                }
            }
         );
    };

/*    const goCommune = ()=>{
        push({pathname:'/publish/commune',state:{}});
    }*/
    /** 撤回审批  */
    const handleRepeal = ()=>{
        if(data.status === 5){
            Modal.confirm({
                title:'系统提示',
                content:'您确定要撤回供应商资审的审批嘛？',
                okText:'确定',
                cancelText:'取消',
                maskClosable: true,
                onOk: ()=>{
                    repeal();
                },
                onCancel:()=>{

                }
            });
        }else{
            repeal();
        }
    };
    const repeal = ()=>{
        PublishModal.repealAudit({operation_type:'withdraw',project_sn,type:2}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:res=>{
                push({pathname:'/publish',state:{step:5,project_sn}})
            },
            error:err=>{},
            complete:()=>{}
        });
    }
    return (
        <div>
            {isLoading || <div className={css.apply}>
                <div className={css.boxTitle}>
                    供应商报名结果列表 <TagAudit data={{'5':{title:'审批中',index:AWAIT},'6':{title:'通过审批',index: SUCCESS},'7':{title:'未通过审批',index: FAIL},}[data.status]}/>
                    <div className={css.info}>
                        <span >项目名称：</span>
                        <span className={css.high}>{data.project_name}</span>
                        <span >项目编号：</span>
                        <span className={css.high}>{data.project_sn}</span>
                        {
                            (data.status === 7 || data.status === 5) &&  <Button className={'withdrawBtn'} size={"small"} onClick={handleRepeal}>{data.status === 5 ?'撤回':'编辑'}</Button>
                        }
                    </div>
                </div>
                { data.status === 7 &&
                <Descriptions title="审批意见" bordered={true} style={{margin: '20px 0'}}>
                    <Descriptions.Item>
                        {data.approvalcontent}
                    </Descriptions.Item>
                </Descriptions>
                }
                <Form
                    {...layout}
                    scrollToFirstError = { true}
                    form={form}
                >
                    <Descriptions >
                        <Descriptions.Item label="供应商列表"/>
                    </Descriptions>
                    <Form.Item wrapperCol={24}>
                        <Table
                            components={components}
                            rowClassName={() => 'editable-row'}
                            bordered
                            pagination={false}
                            dataSource={dataSource}
                            rowKey="id"
                            columns={columns}
                        />
                    </Form.Item>
                    <div className={css.btns}>
                        { data.status === 8  ?
                        <Button type="primary" size={"large"} onClick={()=> push({pathname: '/publish/listSend',state:{project_sn,step:1}}) } >
                            下一步
                        </Button>:   <Button type="primary" size={"large"}  disabled={ data.status !== 6} onClick={ submit } >
                            发送通知
                            </Button>}
                    </div>
                </Form>
            </div>}
        </div>
        );
}