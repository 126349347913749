import React, {useEffect, useState} from 'react';
import {Apply, Flow, Notice, Process, Publish} from './components/index'
import classNames from "classnames";

import css from './procurement.module.less';
import {Tabs} from "antd";

const { TabPane } = Tabs;

/** 采购 */
export const Procurement = (props) => {
    const [ step,setStep ] = useState(1);
    const [params,setParams] = useState({project_sn:''});

    useEffect(()=>{
        const {project_sn='',step=1,type=1} = props.location.state||{};
        setStep(step);
        setParams({project_sn,type});
    }, [props.location.state]);

    return (
        <div className="container" >
            {params.type === 1 &&  <Flow step={step}/>}
            <div className={css.list}>
                <div className={classNames(css.procurement,'listMake')} >
                    <Tabs activeKey={ step.toString() }>
                        <TabPane tab="设置审批流程" key="1">
                            <Process params={params}/>
                        </TabPane>
                        <TabPane tab="编辑采购信息" key="2">
                            <Notice params={params}/>
                        </TabPane>
                        <TabPane tab="发布采购信息" key="3">
                            <Publish params={params}/>
                        </TabPane>
                        <TabPane tab="供应商报价结果" key="4">
                            <Apply params={params}/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};