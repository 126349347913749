import React, {useEffect, useState} from "react";
import css from "./await.module.less";
import { Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Descriptions, Input, message, Modal, Row, Statistic, Tooltip } from 'antd';
import {useDataApi} from "../../../../hook";
import {AUDIT_PUBLISH} from "../../../../common/static_number";
import {useHistory} from "react-router-dom";
import {PublishModal, ServicesModel} from "../../../../common";
import {tap} from "rxjs/operators";

const { Countdown } = Statistic;
export const OpenAwait = (props) => {
    const {params:{project_sn=''} } = props;
    const [modalFlag,setModalFlag] = useState(false);
    const [resetModalFlag,setResetModalFlag] = useState(false);
    const [openFlag,setOpenFlag] = useState(false);
    const [form] = Form.useForm();
    const [resetForm] = Form.useForm();
    const [count, setCount] = useState(60);
    const [countdown, showCountdown] = useState(false);
    const [initialData, setInitialData] = useState({});
    const [mobile,setMobile] = useState('');
    const {push} = useHistory();
    const [{data,setData, isLoading}] = useDataApi({url: '/api/simpleDetail', method: 'post', data: {type:AUDIT_PUBLISH,project_sn}});
    useEffect(() => {
        if(!isLoading){
            let _data = data.project_issued;

            // _data.time = Date.now();
            setInitialData(_data);
        }

    }, [isLoading,data]);


    const submit = ()=>{
        push({pathname:'/publish/listOpen',state:{project_sn,step:2}})
    };
    const showModal = ()=>{
        setModalFlag(true);
    };
    const hideModal = ()=>{
        form.resetFields();
        setModalFlag(false);
    }
    const handleOk = ()=>{
        form.validateFields().then((values )=>{
             PublishModal.open({project_sn,secretkey:values.code}).pipe(
                tap(res=>console.log(res))
            ).subscribe({
                next:res=>{
                    hideModal();
                    message.success('开标成功',3,  push({pathname:'/publish/listOpen',state:{project_sn,step:2}}));
                },
                error:err=>{},
                complete:()=>{}
            });
        })
    }
    const showResetModal = ()=>{
        PublishModal.getOpenMobile({project_sn}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:({data})=>{
                console.log("data======",data);
                setMobile(data);
                setResetModalFlag(true);

            },
            error:err=>{},
            complete:()=>{}
        });
    };

    const handleReset = ()=>{
        resetForm.validateFields().then((values )=>{
            console.log("values",values);

            PublishModal.resetSecretkey({project_sn,mobile,...values}).pipe(
                tap(res=>console.log(res))
            ).subscribe({
                next:res=>{
                    message.success('重置成功！');
                    hideResetModal()
                },
                error:err=>{},
                complete:()=>{}
            });
        })
    };
    const hideResetModal = ()=>{
        setResetModalFlag(false);
    }
    const openOk = ()=>{
        hideOpenModal();
        showModal();



    }
    const showOpenModal = ()=>{
        setOpenFlag(true);
    }
    const hideOpenModal = ()=>{
        setOpenFlag(false);
    }
    const onFinish = ()=>{
        const _data = data;
        _data.status = 12;
        setData({..._data});
    };
     /** 获取手机验证码 */
     const getCode = () => {
         startCountdown();
         ServicesModel.getSendCode({mobile, event: 'retrieveKey'}).pipe(
             tap(res => console.log(res))
         ).subscribe({
             success(res) {
             }
         });
     };
    let IntervalId = '';
    const startCountdown = () => {
        showCountdown(true);
        let _count = count;
        IntervalId = setInterval(() => {
                if (_count > 0) {
                    _count--;
                    setCount(_count);
                } else {
                    showCountdown(false);
                    clearInterval(IntervalId);
                    setCount(60);
                }
            }
            , 1000);

    };

    return (
        <div className={css.open}>
            <div className={css.boxTitle}>
                开标大厅
                <div className={css.info}>
                    <span >项目名称：</span>
                    <span className={css.high}>{data.project_name}</span>
                    <span >项目编号：</span>
                    <span className={css.high}>{data.project_sn}</span>
                </div>
            </div>
            <Descriptions >
                <Descriptions.Item  style={{textAlign: 'right'}}>
                    <Button className={css.lookBtn}  style={{marginRight:'15px'}} onClick={()=>push({pathname: '/publish/listSend',state:{project_sn,step:5}})}>查看专家列表</Button>
                    <Button className={css.lookBtn}  style={{marginRight:'15px'}} onClick={()=>push({pathname: '/publish/listSend',state:{project_sn,step:4}})}>查看投标情况</Button>
                    {
                        initialData.user_approval !== 0 && <Button className={css.lookBtn} onClick={showResetModal}>找回开标口令</Button>
                    }
                </Descriptions.Item>
            </Descriptions>
            <Row justify="center">
                <Col  span={8} className={css.colLeft}>投标截止时间：{initialData.bidend_time}</Col>
                <Col  span={8} className={css.colRight} style={{display:"flex",justifyItems:"center"}}>
                    <Descriptions >
                        <Descriptions.Item label="距投标截止" style={{paddingBottom: '0'}}>
                            <Countdown value={ initialData.down_time + 10000 } format="D 天 H 时 m 分 s 秒" onFinish={onFinish}/>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row justify="center">
                <Col span={8} className={css.colLeft}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;开标时间：{initialData.bidend_time}</Col>
                <Col  span={8} className={css.colRight}>
                    <Descriptions >
                        <Descriptions.Item label="距开标截止" style={{paddingBottom: '0'}}>
                            <Countdown value={ initialData.down_time + 10000 } format="D 天 H 时 m 分 s 秒"/>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <div className={css.btns}>

                {data.status === 12 && data.user_approval !== 0 &&  <Button type="primary" size={"large"}  onClick={ showOpenModal } >
                    开标
                </Button>}
                {data.status === 38 && <Button type="primary" size={"large"} onClick={ submit }>
                    下一步
                    </Button>}
            </div>
            <Modal title='开标确认'
                   visible={openFlag}
                   onOk={openOk}
                   onCancel={hideOpenModal}
            >
                <div style={{margin: '30px 0',textAlign: 'center',fontSize: '18px'}}>您确认现在要开标嘛？</div>
            </Modal>
            <Modal title="输入开标口令"
                   visible={modalFlag}
                   onOk={handleOk}
                   onCancel={hideModal}>
                <Form form={form}  name="basic">
                    <Form.Item label={'开标口令'} name='code' rules={[{ required: true, message: '请输入开标口令' },{min: 6,message: '口令最少为6个字符'},{max: 20,message:'口令最长为10个字符'}]}>

                        <Input.Password  placeholder={'请输入开标口令'} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title='找回开标口令' visible={resetModalFlag} onOk={handleReset} onCancel={hideResetModal}>
                {
                    mobile &&  <Form form={resetForm} >
                        <Row>
                            <Col span={18}>
                                <Form.Item
                                    labelCol={{ span: 12}}
                                    wrapperCol={{ span: 11 }}
                                    label="手机号码"
                                >
                                    {mobile}
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                {
                                    !countdown ?
                                        <Form.Item style={{display: 'inline-block'}}>
                                            <Button type="primary" onClick={() => {
                                                getCode();
                                            }}>获取验证码</Button>
                                        </Form.Item> :
                                        <Form.Item style={{display: 'inline-block'}}>
                                            <Button type={"primary"} disabled>{count}s后获取</Button>
                                        </Form.Item>
                                }
                            </Col>
                        </Row>
                        <Form.Item
                            labelCol={{ span: 9 }}
                            wrapperCol={{ span: 14 }}
                            label="手机验证码"
                            name="code"
                            rules={[{ required: true, message: '请输入手机验证码' }]}
                        >
                            <Input placeholder='请输入手机验证码' autoComplete="off"/>
                        </Form.Item>
                        <Tooltip title={<span>长度为8~10个字符,<br/>必须包含字母/数字,<br/>不允许有空格、中文</span>}  trigger={['focus','hover']} >
                            <Form.Item
                                labelCol={{ span: 9}}
                                wrapperCol={{ span: 14 }}
                                label="新的开标口令"
                                name="secretkey"
                                rules={[{ required: true, message: '请输入新的开标口令' },{min: 6,message: '口令最少为6个字符'},{max: 10,message:'口令最长为10个字符'}]}
                            >
                                <Input.Password placeholder='6-10位的字母加数字组合'  />
                            </Form.Item>
                        </Tooltip>
                        <Form.Item
                            labelCol={{ span: 9}}
                            wrapperCol={{ span: 14 }}
                            label="再次输入开标口令"
                            name="resecretkey"
                            rules={[{ required: true, message: '请再次输入开标口令' },{min: 6,message: '口令最少为6个字符'},{max: 10,message:'口令最长为10个字符'}]}
                        >
                            <Input.Password placeholder='6-10位的字母加数字组合'  />
                        </Form.Item>
                    </Form>

                }
            </Modal>
        </div>);
}